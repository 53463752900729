<!--
Formular für die Userdaten eines neuen Zertifikatantrags.
-->
<template>
  <form>
    <h2>{{ $t("title") }}</h2>
    <p>
      {{ $t("intro") }}
    </p>
    <div class="div-sperrpin" v-for="input in applicantInput" :key="input.id">
      <div v-if="input.alwaysActive || certType != certTypes.user">
        <label :for="input.id">{{
          $t(input.name) + (input.optional ? "" : " *")
        }}</label>
        <b-form-input
          :id="input.id"
          :type="input.type"
          :autocomplete="input.autocomplete"
          v-model="input.value"
          :state="
            displayAllFeedback || input.isDisplayingFeedback
              ? !inputHasError(input.id)
              : null
          "
          aria-describedby="input-true input-false"
          :placeholder="$t(input.name + '_placeholder')"
          trim
          @change="
            displayFeedback(input.id);
            submitData();
          "
        ></b-form-input>
        <b-form-invalid-feedback id="input-false">{{
          $t(input.name + "_feedback")
        }}</b-form-invalid-feedback>
        <b-form-valid-feedback id="input-true"></b-form-valid-feedback>
        <div v-if="input.id == 'revokePin2'">
          <p>{{ $t("explainPin") }}</p>
        </div>
      </div>
    </div>
  </form>
</template>

<i18n>
    en:
      title: "Your data"
      intro: "Enter further data below. What you enter here will not be found in the certificate."
      name: "Full name"
      name_placeholder: "Enter your full given name(s) and surname(s) here"
      name_feedback: "The name must be at least 3 characters long"
      email: "Email"
      email_placeholder: "Your email address"
      email_feedback: "The email address has to be valid"
      abteilung: "Department"
      abteilung_placeholder: "Name of your department (or organisational unit)"
      abteilung_feedback: "The department must be at least 3 characters long"
      revokePin: "Revocation PIN"
      revokePin_placeholder: "Revocation PIN - at least 8 characters"
      revokePin_feedback: "The revocation PIN must be at least 8 characters long"
      revokePin2: "Revocation PIN - Confirmation"
      revokePin2_placeholder: "Please enter the revocation PIN again to confirm."
      revokePin2_feedback: "The revocation PIN must be at least 8 characters long and they have to match"
      explainPin: "This PIN is required if you want to revoke your certificate. Please make a note of this PIN."
    de:
      title: "Ihre Daten"
      intro: "Diese Daten werden nicht in Ihr Zertifikat aufgenommen."
      name: "Vollständiger Name"
      name_placeholder: "Geben Sie hier Ihren vollständigen Vor- und Nachnamen an"
      name_feedback: "Der Name muss mindestens 3 Zeichen lang sein"
      email: "E-Mail"
      email_placeholder: "Ihre E-Mail-Adresse"
      email_feedback: "Die E-Mail-Adresse muss gültig sein"
      abteilung: "Abteilung"
      abteilung_placeholder: "Abteilungsname Ihrer Abteilung"
      abteilung_feedback: "Der Abteilungsname muss mindestens 3 Zeichen lang sein"
      revokePin: "Sperr-PIN"
      revokePin_placeholder: "Sperr-PIN - Mindestens 8 beliebige Zeichen"
      revokePin_feedback: "Die Sperr-Pin muss mindestens 8 Zeichen lang sein"
      revokePin2: "Sperr-PIN - Bestätigung"
      revokePin2_placeholder: "Nochmalige Eingabe der Sperr-PIN zur Bestätigung"
      revokePin2_feedback: "Die Sperr-Pin muss mindestens 8 Zeichen lang sein und die Sperr-Pins müssen übereinstimmen"
      explainPin: "Diese PIN wird von Ihnen benötigt, wenn Sie Ihr Zertifikat sperren wollen. Bitte notieren Sie sich die PIN."
</i18n>

<script>
import { ALLOWED_RDN_TYPES, SYNTAX_PATTERN } from "@/pki/nameTypes";
import { Logger } from "@/logger/logger";
import { CERT_TYPES } from "@/pki/certTypes";

const logger = new Logger("SaveRequestForm");
// eslint-disable-next-line no-undef
const developerMode = process.env.NODE_ENV === "development";

export default {
  name: "UserRequestData",
  mounted() {
    if (developerMode) {
      this.developerModeFunction();
    }
  },
  props: {
    certType: { type: Object, required: true },
    displayAllFeedback: { type: Boolean, required: true },
    value: {},
  },
  data() {
    // TODO: Jedem Input ein Pattern (aus nameTypes.js) zuweisen und damit
    // die Eingaben prüfen.
    let applicantInput = {
      addName: {
        id: "addName",
        name: "name",
        type: "text",
        optional: false,
        alwaysActive: false,
        isDisplayingFeedback: false,
        value: "",
        autocomplete: "name",
      },
      addEmail: {
        id: "addEmail",
        name: "email",
        type: "email",
        optional: false,
        alwaysActive: false,
        isDisplayingFeedback: false,
        value: "",
        autocomplete: "email",
      },
      // TODO: Eigentlich muss das addOrgUnit heißen
      addOrg: {
        id: "addOrg",
        name: "abteilung",
        type: "text",
        optional: true,
        alwaysActive: true,
        isDisplayingFeedback: false,
        value: "",
        autocomplete: "organization",
      },
      revokePin: {
        id: "revokePin",
        name: "revokePin",
        type: "password",
        optional: false,
        alwaysActive: true,
        isDisplayingFeedback: false,
        value: "",
        autocomplete: "new-password",
      },
      revokePin2: {
        id: "revokePin2",
        name: "revokePin2",
        type: "password",
        optional: false,
        alwaysActive: true,
        isDisplayingFeedback: false,
        value: "",
        autocomplete: "new-password",
      },
    };
    return {
      certificateType: "user", // user oder server Zertifikat
      rdnTypes: ALLOWED_RDN_TYPES,
      certTypes: CERT_TYPES,
      applicantInput: applicantInput,
      revokePin: "",
      revokePin2: "",
      errors: {},
      // userInput: userInput,
      user: true,
    };
  },
  methods: {
    /**
     * Emittet addName, addEmail, addOrg und die revokePin an NewRequest.
     * Die ersten drei Werte sind gegebenenfalls leer.
     *
     * Wird bei jeder Änderung aufgerufen.
     */
    submitData() {
      if (this.formHasError()) {
        // Bei Fehlern wird null emittet. Das signalisiert, dass die Eingabe
        // noch nicht vollständig ist oder Fehler enthält.
        this.$emit("input", null);
        return;
      }
      this.$emit("input", {
        addName: this.applicantInput.addName.value,
        addEmail: this.applicantInput.addEmail.value,
        addOrg: this.applicantInput.addOrg.value,
        revokePin: this.applicantInput.revokePin.value,
        revokePin2: this.applicantInput.revokePin2.value,
      });
    },
    inputHasError(input) {
      switch (input) {
        case "addName":
          return this.applicantInput.addName.value.length < 3;
        case "addEmail":
          return !this.validEmail(this.applicantInput.addEmail.value);
        case "addOrg":
          return false;
        case "revokePin":
          return this.applicantInput.revokePin.value.length < 8;
        case "revokePin2":
          return (
            this.applicantInput.revokePin2.value.length < 8 ||
            this.applicantInput.revokePin2.value !==
              this.applicantInput.revokePin.value
          );
      }
    },
    formHasError() {
      for (let key in this.applicantInput) {
        const input = this.applicantInput[key];
        if (
          (input.alwaysActive || this.certType != CERT_TYPES.user) &&
          this.inputHasError(key)
        ) {
          logger.debug("Fehler in " + JSON.stringify(input));
          return true;
        }
      }
      logger.debug("Keine Fehler in NewRequestApplicant");
      return false;
    },
    /**
     * Prüft, ob eine Adresse auf das Muster SYNTAX_PATTERN.EMAIL passt.
     */
    validEmail(email) {
      return SYNTAX_PATTERN.EMAIL.test(email);
    },
    displayFeedback(field) {
      this.applicantInput[field].isDisplayingFeedback = true;
    },
    /**
     *
     */
    developerModeFunction() {
      const pin = "12345678";

      this.applicantInput.addName.value = "Anna Antragsstellerin";
      this.applicantInput.addEmail.value = "Anna@email.dfnpki.de";
      this.applicantInput.revokePin.value = pin;
      this.applicantInput.revokePin2.value = pin;

      // Eingetragene Daten direkt an NewRequest übermitteln.
      this.submitData();
    },
  },
};
</script>

<style scoped>
.div-sperrpin {
  margin-top: 15px;
}
</style>
