import { encrypt, decrypt } from "@/pki/dfnpki";
import { RDNsToString, Subject } from "@/pki/subject";
import { CERT_TYPES } from "@/pki/certTypes";

const requiredProperties = [
  "ca",
  "ra_id",
  "date",
  "addName",
  "addEmail",
  "addOrg",
  "role",
  "publish",
  "subject",
];

/**
 * Kapselt alle Daten eines Requests.
 *
 * Manche Daten, die erst später zu einem Request hinzugefügt werden, können
 * null sein. Das betrifft beispielsweise die zugehörige Zertifikatseriennummer,
 * aber auch den PKCS10-Antrag und den privaten Schlüssel.
 */
export class Request {
  constructor(requestData) {
    if (!requestData) {
      throw new Error("Request-Constructor mit leeren Daten aufgerufen");
    }
    for (let prop of requiredProperties) {
      if (!Object.prototype.hasOwnProperty.call(requestData, prop)) {
        throw new Error("Attribut " + prop + " fehlt in Antragsdaten.");
      }
    }
    this.ca = requestData.ca;
    this.ra_id = requestData.ra_id;
    this.date = new Date(requestData.date);
    this.addName = requestData.addName;
    this.addEmail = requestData.addEmail;
    this.addOrg = requestData.addOrg;
    this.plainPinHash = requestData.plainPinHash;
    this.encryptedPinHash = requestData.encryptedPinHash;
    this.role = requestData.role;
    this.publish = requestData.publish;
    this.subject = requestData.subject;
    this.altnames = requestData.altnames;
    this.pkcs10PEM = requestData.pkcs10PEM;
    this.plainPrivateKey = requestData.plainPrivateKey;
    this.encryptedPrivateKey = requestData.encryptedPrivateKey;
    this.serial = requestData.serial;
    this.certInfo = requestData.certInfo;
    this.comment = requestData.comment;
    this.certType = requestData.certType;
    this.version = requestData.version;

    // Hier werden alte Datenformate aktualisiert.
    if (!this.version) {
      // Vor Version 1 gab es kein Kommentarfeld. --> Setze leeren String.
      this.comment = "";
      // Das Subject war noch nicht im Wenja-Format.
      this.subject = Subject.fromTypeValueArray(this.subject);
      this.version = 1;
    }
    if (this.version < 2) {
      // Pinhash und Privater Schlüssel noch ohne plain-/encrypted-Prefix.
      this.encryptedPinHash = requestData.pinHash;
      this.encryptedPrivateKey = requestData.privateKey;
      this.version = 2;
    }
    if (this.version < 3) {
      // certType noch nicht vorhanden
      this.certType = CERT_TYPES.user;
      this.version = 3;
    }

    this.subject = new Subject(this.subject);
    // TODO: abgeflachte SANs wieder mit richtigen Typen versehen.
  }

  toString() {
    return "Antrag " + this.serial;
  }

  /**
   * Gibt den DN des Antrags in der Form Typ1=Wert1, Typ2=Wert2, ...
   * Beispiel: CN=Willie Wenja, O=Hochschule Schilda CA, C=DE
   *
   * @returns {String} den DN des Antrags
   */
  getDN() {
    return RDNsToString(this.subject);
  }

  /**
   * Gibt alle CNs kommasepariert aus dem subject.
   *
   * Wirft einen Fehler, falls kein CN vorhanden ist.
   *
   * @returns {String} alle CNs kommasepariert aus dem subject
   */
  getCN() {
    return this.subject.getCN();
  }

  /**
   * Verschlüsselt Pinhash und privaten Schlüssel.
   *
   * Es werden aus den Feldern plainPinHash und plainPrivateKey die Felder
   * encryptedPinHash und encryptedPrivateKey erzeugt. Wenn erstere fehlen wird
   * ein Fehler geworfen.
   *
   * Achtung: plainPinHash und plainPrivateKey werden NICHT gelöscht. Für eine
   * bereinigte Kopie einfach getSaveableCopy() verwenden.
   *
   * @param {String} password das zum Verschlüsseln benutzte Passwort
   */
  encrypt(password) {
    if (!this.plainPinHash) {
      throw new Error("plainPinHash nicht definiert");
    }
    this.encryptedPinHash = encrypt(this.plainPinHash, password);
    if (this.plainPrivateKey) {
      this.encryptedPrivateKey = encrypt(this.plainPrivateKey, password);
    }
  }

  /**
   * Entschlüsselt Pinhash und privaten Schlüssel.
   *
   * Es werden aus den Feldern encryptedPinHash und encryptedPrivateKey die
   * Felder plainPinHash und plainPrivateKey erzeugt. Wenn erstere fehlen wird
   * ein Fehler geworfen.
   *
   * @param {String} password das zum Verschlüsseln benutzte Passwort
   */
  decrypt(password) {
    if (!this.encryptedPinHash) {
      throw new Error("encryptedPinHash nicht definiert");
    }
    this.plainPinHash = decrypt(this.encryptedPinHash, password);
    if (this.encryptedPrivateKey) {
      this.plainPrivateKey = decrypt(this.encryptedPrivateKey, password);
    }
  }

  /**
   * Gibt eine Kopie des Requests ohne die Felder plainPinHash/plainPrivateKey.
   *
   * Altnames werden abgeflacht zu shortName-value-Paaren.
   */
  getSaveableCopy() {
    if (!this.encryptedPinHash) {
      throw new Error("Requestdaten noch nicht verschlüsselt.");
    }
    const clone = { ...this };

    delete clone.plainPinHash;
    delete clone.plainPrivateKey;

    clone.altnames = clone.altnames.map((san) => ({
      shortName: san.type.shortName,
      value: san.value,
    }));

    return clone;
  }
}
