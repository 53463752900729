<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="light">
      <b-navbar-brand>
        <router-link :to="{ name: HOME }">
          {{ $t("home") }}
        </router-link>
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <nav-link-list></nav-link-list>

        <!-- Right aligned nav item -->
        <b-navbar-nav class="ml-auto">
          <locale-changer></locale-changer>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<i18n>
    en:
      home: "Home"
    de:
      home: "Startseite"
</i18n>

<script>
import { HOME } from "@/router/names";
import NavLinkList from "@/components/NavLinkList";
import LocaleChanger from "@/components/gui-elements/LocaleChanger";

export default {
  components: {
    NavLinkList,
    LocaleChanger,
  },
  data() {
    return {
      HOME: HOME,
    };
  },
};
</script>
