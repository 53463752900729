<!--
Formular für einen neuen Zertifikatantrag.
-->
<template>
  <div>
    <!-- 1 -->
    <div v-show="this.step === 1">
      <h1>{{ $t(this.certType.name + "_title") }}</h1>
      <p>{{ $t("intro") }}</p>
      <!-- Vorrübergehende Mitteilung zur Abkündigung der Serverzertifikate
          in der Global Policy, siehe Ticket #2049 -->
      <div>
        <p v-if="this.custom.policy.showNotification" style="color: red">
          {{ $t("notification") }}
        </p>
      </div>

      <!-- Profilauswahl -->
      <label for="role">{{ $t("profile") }}</label>
      &nbsp;
      <select v-model="role" id="role" @change="updatePublish()">
        <option v-for="r in roles" :key="r">{{ r }}</option>
      </select>
      &nbsp;
      <p v-html="$t('tooltip_profile', { profiles_hint: profiles_hint })"></p>

      <!-- PKCS10 Upload -->
      <div v-if="isPKCS10Upload()">
        <Pkcs10Upload
          v-model="pkcs10"
          :displayAllFeedback="displayFeedback"
          @error-message="(msg) => $emit('error-message', msg)"
        ></Pkcs10Upload>
      </div>
      <!-- Zertifikatsdaten -->
      <div v-else>
        <NewRequestCertificate
          v-model="certificateData"
          :certType="certType"
          :displayAllFeedback="displayFeedback"
          @error-message="(msg) => $emit('error-message', msg)"
        ></NewRequestCertificate>
      </div>
      <!-- Sperr-PIN -->
      <NewRequestApplicant
        v-model="userData"
        :certType="certType"
        :displayAllFeedback="displayFeedback"
      ></NewRequestApplicant>
      <!-- Kommentarfeld -->
      <h2>{{ $t("comment") }}</h2>
      <label for="comment_input">{{ $t("comment_label") }}</label>
      <b-form-input
        id="comment_input"
        type="text"
        v-model="comment"
        :placeholder="$t('comment')"
        trim
      ></b-form-input>
      <!-- Terms and conditions -->
      <b-form-checkbox
        id="checkbox-1"
        v-if="custom.termsAndConditionsRequired"
        v-model="termsAccepted"
        name="checkbox-1"
        aria-describedby="input-true input-false input-help"
        :state="displayFeedback ? termsAccepted : null"
      >
        <p v-html="termsAndConditions + ' *'"></p>
      </b-form-checkbox>
      <b-form-invalid-feedback
        id="input-false"
        v-if="custom.termsAndConditionsRequired"
        :state="displayFeedback ? termsAccepted : null"
        >{{ $t("terms_and_conditions_feedback") }}</b-form-invalid-feedback
      >
      <!-- Veröffentlichen -->
      <b-form-checkbox
        v-if="showPublishCheckbox"
        id="checkbox-2"
        v-model="publish"
        name="checkbox-2"
        :state="displayFeedback ? !publishRequired || publish : null"
        aria-describedby="input-true input-false input-help"
      >
        <p v-html="$t('publish') + (publishRequired ? ' *' : '')"></p>
      </b-form-checkbox>
      <b-form-invalid-feedback
        :state="displayFeedback ? !publishRequired || publish : null"
        >{{ $t("publish_required") }}</b-form-invalid-feedback
      >
      <!-- Datenschutzrichtlinie -->
      <b-form-checkbox
        id="checkbox-3"
        v-model="dataProtectionAgreementAccepted"
        name="checkbox-3"
        :state="displayFeedback ? dataProtectionAgreementAccepted : null"
        aria-describedby="input-true input-false input-help"
      >
        <p v-html="dataProtectionAgreement"></p>
      </b-form-checkbox>
      <b-form-invalid-feedback
        id="input-false"
        :state="displayFeedback ? dataProtectionAgreementAccepted : null"
        >{{ $t("terms_and_conditions_feedback") }}</b-form-invalid-feedback
      >
      <b-button
        type="submit"
        @click.prevent="onSubmit"
        :disabled="isUploading"
        variant="primary"
      >
        {{ $t("submit") }}
        <b-spinner v-if="isUploading" small label="Small Spinner"></b-spinner>
      </b-button>
    </div>
    <!-- 2: Antrag anzeigen -->
    <div v-if="this.step === 2">
      <ConfirmRequest
        :request="request"
        :certType="certType"
        @error-message="(msg) => $emit('error-message', msg)"
        @requestSaved="displayPDFButton"
      ></ConfirmRequest>
      <b-button variant="primary" id="backbutton" @click="$router.go(-1)">
        {{ $t("back_button") }}
      </b-button>
    </div>
    <!-- 3: Abschlussseite, PDF-Download -->
    <DownloadPDF v-if="this.step === 3" :request="request"></DownloadPDF>
  </div>
</template>

<i18n>
    en:
      notification: "Please note: As of 30.08.2023, certificates will no longer be issued in the DFN-PKI Global. In the future, you will receive new certificates via the GÉANT TCS service. Please contact your local subscriber service at your institution for information on specific application procedures."
      profile: "Certificate profile"
      tooltip_profile: "The chosen \"Certificate profile\" determines the possible usages of the certificate.{profiles_hint}"
      profiles_hint_text: "(Description of certificate profiles [German])"
      user_title: "New user certificate (incl. key generation by your webbrowser)"
      pseudonym_title: "New pseudonym certificate (incl. key generation by your webbrowser)"
      group_title: "New group certificate (incl. key generation by your webbrowser)"
      server_title: "New server certificate (incl. key generation by your webbrowser)"
      pkcs10_title: "CSR (PKCS#10) upload"
      intro: "Here you can apply for a new certificate."
      submit: "Next"
      publish: "I agree to the publication of the certificate with the contained names and e-mail addresses.<br>You can withdraw this agreement by sending an e-mail to <a href='mailto:pki@dfn.de'>pki@dfn.de</a>."
      comment: "Personal note"
      comment_label: "You may enter an optional note for this certificate application here. The comment will solely be saved in your local certificate application data file."
      back_button: "Change data"
      terms_and_conditions_default: "I am commited to comply with the regulations contained in <a href='https://info.pca.dfn.de/doc/Info_Zertifikatinhaber.pdf' target='blank'>Information for certificate holders</a>."
      terms_and_conditions_feedback: "This is a mandatory field"
      publish_required: "You have to agree to the publication of your certificate."
    de:
      notification: "Bitte beachten Sie: Ab 30.08.2023 werden in der DFN-PKI Global keine Zertifikate mehr ausgestellt. Neue Zertifikate erhalten Sie in Zukunft über den Dienst GÉANT TCS. Bitte wenden Sie sich für Informationen zu konkreten Beantragungsverfahren an Ihren lokalen Teilnehmerservice Ihrer Einrichtung."
      profile: "Zertifikatsprofil"
      tooltip_profile: "Mit dem Zertifikatsprofil legen Sie den Einsatzzweck des Zertifikats fest.{profiles_hint}"
      profiles_hint_text: "(Beschreibung der Zertifikatsprofile)"
      user_title: "Neues Nutzerzertifikat (inkl. Schlüsselerzeugung durch Ihren Web-Browser)"
      pseudonym_title: "Neues Pseudonymzertifikat (inkl. Schlüsselerzeugung durch Ihren Web-Browser)"
      group_title: "Neues Gruppenzertifikat (inkl. Schlüsselerzeugung durch Ihren Web-Browser)"
      server_title: "Neues Serverzertifikat (inkl. Schlüsselerzeugung durch Ihren Web-Browser)"
      pkcs10_title: "Eigene CSR-Datei (PKCS#10) einreichen"
      intro: "Hier können Sie ein neues Zertifikat beantragen."
      submit: "Weiter"
      publish: "Ich stimme der Veröffentlichung des Zertifikates mit meinem darin enthaltenen Namen und der E-Mail-Adresse zu.<br>Sie können diese Einwilligung jederzeit mit Wirkung für die Zukunft durch eine E-Mail an <a href='mailto:pki@dfn.de'>pki@dfn.de</a> widerrufen."
      comment: "Persönliche Notiz"
      comment_label: "Hier können Sie eine persönliche Notiz zu diesem Zertifikatantrag eingeben. Diese Notiz wird ausschließlich lokal mit der Antragsdatei abgespeichert."
      back_button: "Daten ändern"
      terms_and_conditions_default: "Ich verpflichte mich, die in den <a href='https://info.pca.dfn.de/doc/Info_Zertifikatinhaber.pdf' target='blank'>Informationen für Zertifikatinhaber</a> aufgeführten Regelungen einzuhalten."
      terms_and_conditions_feedback: "Dies ist ein Pflichtfeld"
      publish_required: "Sie müssen der Veröffentlichung zustimmen."
</i18n>

<script>
import { brand } from "@/brand/brand";
import { custom } from "@/customization/customization";
import { Logger } from "@/logger/logger";
import NewRequestApplicant from "@/components/pages/new-certificate/NewRequestApplicant";
import NewRequestCertificate from "@/components/pages/new-certificate/NewRequestCertificate";
import Pkcs10Upload from "@/components/pages/new-certificate/Pkcs10Upload";
import ConfirmRequest from "@/components/pages/new-certificate/ConfirmRequest";
import { CERT_TYPES } from "@/pki/certTypes";
import DownloadPDF from "@/components/pages/new-certificate/DownloadPDF";
import { i18n } from "@/i18n/i18n";
import { sha1 } from "@/pki/dfnpki";
import { RequestBuilder } from "@/pki/requestbuilder";
import { Request } from "@/pki/request.js";

const logger = new Logger("NewRequest");
// eslint-disable-next-line no-undef
const developerMode = process.env.NODE_ENV === "development";

export default {
  name: "NewRequest",
  components: {
    NewRequestCertificate,
    NewRequestApplicant,
    Pkcs10Upload,
    ConfirmRequest,
    DownloadPDF,
  },
  mounted() {
    if (developerMode) {
      this.developerModeFunction();
    }

    this.updatePublish(); // Werte für publish etc. setzen
  },
  computed: {
    termsAndConditions() {
      let conditions = custom.termsAndConditions[i18n.locale];
      // es gibt ra_id_<N>.xml configs ohne <terms-and-conditions>, cf. #1826
      // in solch einem Fall hat conditions den Wert ""
      if (!conditions) conditions = this.$t("terms_and_conditions_default");
      return conditions;
    },
    // Legt den Datenschutztext in Abhängigkeit der Rolle (Server/User) fest
    dataProtectionAgreement() {
      let dpa = custom.dataProtectionAgreement;
      let dpaText = dpa.userText[i18n.locale];

      if (custom.serverRoles.includes(this.role)) {
        dpaText = dpa.serverText[i18n.locale];
      }

      dpaText = dpaText.replace(
        "{link}",
        '<a href="' +
          dpa.url[i18n.locale] +
          '" target="_blank" rel="noopener noreferrer">' +
          dpa.linkText[i18n.locale] +
          "</a>"
      );
      // Pflichtfeld, daher Sternchen anfügen.
      return dpaText + " *";
    },
    profiles_hint() {
      if (brand.showProfilesHint) {
        return (
          " <a target='_blank' rel='noopener noreferrer' href='" +
          custom.profileHintURL +
          "'>" +
          this.$t("profiles_hint_text", this.$i18n.locale) +
          "</a>"
        );
      }
      return "";
    },
  },
  props: {
    certType: { type: Object, required: true },
    step: { type: Number, required: true },
  },
  data() {
    const certificateData = {
      rdns: [],
      sans: [],
    };

    // custom.userRoles: Alle Einträge <allowed_role_spkac_request> aus der ra_id_N.xml
    // custom.pkcs10Roles: Alle Einträge <allowed_role_pkcs10_request> aus der ra_id_N.xml
    // custom.serverRoles: Alle Einträge aus pkcs10Roles, bei denen in roles.xml das Flag 'server' gesetzt ist
    // Achtung: pkcs10Roles != userRoles + serverRoles, d.h. Über den PKCS10Upload-Antragsweg können u.U. mehr User-Rollen
    //          ausgewählt werden, als in dem 'einfachen' Nutzerantragsweg
    const roles =
      this.certType == CERT_TYPES.server
        ? custom.serverRoles
        : this.certType == CERT_TYPES.pkcs10
        ? custom.pkcs10Roles
        : custom.userRoles;
    return {
      custom,
      certTypes: CERT_TYPES,
      roles, // Die im Select auswählbaren Zertifikatsprofile
      role: roles[0], // Das ausgewählte Zertifikatsprofil
      userData: null, // Daten aus der Komponente NewRequestApplicant
      certificateData, // Daten aus der Komponente NewRequestCertificate
      pkcs10: null, // Object {pem, subject, altnames} vom Pkcs10Upload
      termsAccepted: false,
      displayFeedback: false, // Sollen alle Inputs Feedback anzeigen?
      isUploading: false,
      request: null,
      comment: "",
      publish: null, // Wert der Veröffentlichen-Checkbox. Siehe updatePublish()
      publishRequired: null, // Muss veröffentlicht werden?
      showPublishCheckbox: null, // Wird Checkbox angezeigt?
      dataProtectionAgreementAccepted: false,
    };
  },
  // Überwacht die step und certType Variablen.
  // Es wird ermittelt ob ein zurücksetzen der Formularfelder erforderlich ist.
  watch: {
    step: function (newStep, oldStep) {
      if (oldStep > 2) {
        this.reset();
      }
    },
    certType: function (newType, oldType) {
      if (newType !== oldType) {
        this.reset();
      }
    },
  },
  created() {
    if (isNaN(this.step) || (this.step != 1 && !this.request)) {
      logger.info("Seiteneinstieg nicht beim Start, mache $router.replace");
      this.$router.replace("1");
    }
  },
  methods: {
    /**
     * Erzeugt aus den Eingabedaten einen Request und wechselt auf Seite 2.
     *
     * Bei Fehlern in der Eingabe wird die Seite nicht gewechselt sondern
     * Feedback an allen Inputs angezeigt.
     */
    onSubmit: async function () {
      logger.debug("onSubmit()");
      this.displayFeedback = true;
      if (this.isPKCS10Upload() && this.pkcs10 === null) {
        // TODO: Hinweis bei fehlendem Upload hinzufügen
        logger.error("KEIN PKCS10 vorhanden");
        return null;
      }
      if (
        this.userData !== null &&
        (this.certificateData !== null || this.pkcs10 !== null) &&
        (!custom.termsAndConditionsRequired || this.termsAccepted) &&
        this.dataProtectionAgreementAccepted &&
        (!this.publishRequired || this.publish)
      ) {
        logger.debug("onSubmit() --> true");
        let pkcs10PEM;
        let privateKey;
        let pinHash = sha1(this.userData.revokePin);
        if (this.pkcs10 !== null) {
          // Bestehender Antrag eingegeben
          pkcs10PEM = this.pkcs10.pem;
          privateKey = ""; // es wird kein privater Schlüssel gesetzt
          this.certificateData.rdns = this.pkcs10.subject;
          this.certificateData.sans = this.pkcs10.altnames;
        } else {
          // Neuer Antrag
          try {
            const builder = new RequestBuilder();
            builder.setSubject(this.certificateData.rdns);
            //builder.setAltnames(this.certificateData.sans);
            // TODO: Altnames setzen
            ({ pkcs10PEM, privateKey } = await builder.build());
          } catch (e) {
            logger.error("Fehler beim Erzeugen des PKCS10-Antrags: " + e);
            return null;
          }
          logger.trace("PKCS10-Antrag: " + pkcs10PEM);
        }

        let addName = this.userData.addName;
        let addEmail = this.userData.addEmail;
        let addOrg = this.userData.addOrg;

        if (this.certType === CERT_TYPES.user) {
          // Bei Nutzerzertifikaten nehmen wir addName aus CN
          addName = this.certificateData.rdns.CN.join(", ");
        }

        this.request = new Request({
          ca: custom.ca,
          ra_id: custom.ra_id,
          comment: this.comment,
          date: new Date(),
          addName: addName,
          addEmail: addEmail,
          addOrg: addOrg,
          plainPinHash: pinHash,
          role: this.role,
          publish: this.publish,
          subject: this.certificateData.rdns,
          altnames: this.certificateData.sans,
          serial: "",
          pkcs10PEM: pkcs10PEM,
          plainPrivateKey: privateKey,
          certType: this.certType,
          version: 3,
        });

        this.$router.push("2");
      }
    },
    // Wechselt auf Seite 3: Abschluss mit PDF-Anzeige.
    displayPDFButton() {
      this.$router.push("3");
    },
    // Setzt alle Felder des Formulars zurück
    reset() {
      // TODO: Zurücksetzen der Eingabefelder
      // Checkboxes werden zurückgesetzt
      this.termsAccepted = false;
      this.dataProtectionAgreementAccepted = false;
      // TODO: publishUser neu evaluieren
    },
    developerModeFunction() {
      this.termsAccepted = true;
      this.dataProtectionAgreementAccepted = true;
    },
    isPKCS10Upload() {
      return this.certType == CERT_TYPES.pkcs10;
    },

    /**
     * Setzt die Werte showPublishCheckbox, publish und publishRequired.
     */
    updatePublish() {
      let showPublishCheckbox;
      let publish;
      let publishRequired = false;

      // Hier wird ermittelt, ob das Veröffentlichen erforderlich ist, ob eine
      // Checkbox angezeigt wird und ob sie vorangekreuzt ist. Das geht auch
      // mit weniger Code, ist durch das Switch aber expliziter.
      const configuredPublish =
        this.certType == CERT_TYPES.server ||
        custom.serverRoles.includes(this.role)
          ? custom.publishServer
          : custom.publishUser;
      switch (configuredPublish) {
        case "no":
          showPublishCheckbox = false;
          publish = false;
          break;
        case "optional_optout":
          showPublishCheckbox = true;
          publish = true;
          break;
        case "optional":
          showPublishCheckbox = true;
          publish = false;
          break;
        case "required":
          showPublishCheckbox = true;
          publish = false;
          publishRequired = true;
          break;
        case "automatic":
          showPublishCheckbox = false;
          publish = true;
          break;
        default:
          logger.warn(
            "Unbekannter Wert " +
              custom.publishUser +
              " für custom.publishUser, nutze 'optional'"
          );
          showPublishCheckbox = true;
          publish = false;
      }
      if (brand.neverPublish) {
        // Für DFNCertPKI gelten eigene Regeln.
        showPublishCheckbox = false;
        publish = false;
      }

      // Werte tatsächlich setzen
      this.publish = publish;
      this.publishRequired = publishRequired;
      this.showPublishCheckbox = showPublishCheckbox;

      logger.trace(`publish etc. aktualisiert:
        role = ${this.role}
        configuredPublish = ${configuredPublish}
        showPublishCheckbox = ${showPublishCheckbox}
        publish = ${publish}
        publishRequired = ${publishRequired}`);
    },
  },
};
</script>
<style scoped>
#comment_input {
  margin-bottom: 15px;
}
#backbutton {
  margin-top: 10px;
}
</style>
