<!--
Zeigt an, dass der Request hochgeladen wurde und bietet das Antragsformular zum
Download an.

Childkomponente von NewRequest.
-->
<template>
  <div>
    <h1>{{ $t("page_title") }}</h1>
    <p>{{ $t("request_uploaded", { serial: request.serial }) }}</p>
    <p>
      {{ $t("instructions_" + receiptOrApplication, { pki_name: pki_name }) }}
    </p>
    <b-button @click="getRequestPDF" variant="primary">
      {{ $t("getPDF_" + receiptOrApplication) }}
    </b-button>
    <p>
      {{ $t("download_again", { fileName: fileName }) }}
    </p>
    <b-button @click="downloadRequest" variant="primary">
      {{ $t("download_request_button") }}
    </b-button>
    <b-modal id="loadingModal" size="sm" hide-footer hide-header centered>
      <div class="text-center">
        {{ $t("loading") }}&nbsp;…
        <b-spinner small type="grow" label="Spinning"></b-spinner>
      </div>
    </b-modal>
    <p>{{ $t("instructions2") }}</p>
  </div>
</template>

<i18n>
    en:
      page_title: "Your certificate application"
      getPDF_application: "Download certificate application form (PDF)"
      getPDF_receipt: "Download application receipt (PDF)"
      request_uploaded: "Your certificate application (number {serial}) has been uploaded."
      instructions_application: "Download the certificate application form (PDF) and hand it completed and signed to your local {pki_name} Subscriber-Service."
      instructions_receipt: "Download the application receipt (PDF). Please inform your local {pki_name} Subscriber-Service about your certificate application (including your application number)."
      instructions2: "Once your certificate has been issued you will be notified and instructed with all further necessary steps to download the certificate and merge it with the private key from your certificate application data file into a certificate file (.p12)."
      loading: "Downloading PDF"
      download_again: "Please make sure that you have successfully downloaded and saved the certificate application data file {fileName}.
          If an error occured while saving the file you can download and save the certificate application data file again."
      download_request_button: "Save certificate application data file (JSON) again"
    de:
      page_title: "Ihr Zertifikatantrag"
      back: "Zurück"
      getPDF_application: "Zertifikatantragsformular (PDF) herunterladen"
      getPDF_receipt: "Antragsquittung (PDF) herunterladen"
      request_uploaded: "Ihr Zertifikatantrag wurde unter der Nummer {serial} hochgeladen."
      instructions_application: "Laden Sie das Zertifikatantragsformular (PDF) herunter und geben Sie es vollständig ausgefüllt und unterschrieben an Ihren lokalen {pki_name} Teilnehmerservice."
      instructions_receipt: "Laden Sie die Antragsquittung (PDF) herunter und informieren Sie Ihren lokalen {pki_name} Teilnehmerservice über Ihre Antragsstellung (inkl. Ihrer Antragsnummer)."
      instructions2: "Sobald Ihr Zertifikat ausgestellt wurde, erhalten Sie eine Benachrichtigung mit allen weiteren nötigen Schritten, um das Zertifikat herunterzuladen und dieses mit dem privaten Schlüssel aus Ihrer Antragsdatei zu einer Zertifikatdatei (.p12) zu verbinden."
      loading: "Ihr PDF wird heruntergeladen"
      download_again: "Bitte überprüfen Sie, dass das Herunterladen und Speichern der Antragsdatei {fileName} erfolgreich war.
          Sollte beim Speichern ein Fehler aufgetreten sein, können Sie die Antragsdatei erneut herunterladen und speichern."
      download_request_button: "Antragsdatei (JSON) erneut speichern"
</i18n>

<script>
import { brand } from "@/brand/brand";
import { custom } from "@/customization/customization";
import { DFNSOAPClient } from "@/soap/dfnsoapclient";
import { downloadFile, getFileName } from "@/utils/filehandling";
import { Logger } from "@/logger/logger";

const logger = new Logger("ConfirmRequest");

export default {
  name: "DownloadPDF",
  data() {
    const prefix =
      "Antragsdatei_" + this.request.getCN() + "_" + this.request.serial;
    return {
      pki_name: brand.pkiName,
      fileName: getFileName(prefix, "json", this.request.date),
      receiptOrApplication: custom.receiptOnly ? "receipt" : "application",
    };
  },
  props: {
    request: { type: Object, required: true },
  },
  methods: {
    async getRequestPDF() {
      const client = new DFNSOAPClient(custom.pubURL, custom.ra_id);
      const prefix =
        "Zertifikatantrag_" + this.request.getCN() + "_" + this.request.serial;
      const fileName = getFileName(prefix, "pdf", this.request.date);

      try {
        this.$bvModal.show("loadingModal");
        const printout = await client.getRequestPrintout(
          this.request.serial,
          this.request.plainPinHash
        );
        this.$bvModal.hide("loadingModal");
        if (printout === "") {
          throw new Error("PDF ist leer.");
        }
        downloadFile(printout, fileName, document, true);
      } catch (exception) {
        logger.debug(exception);
        throw exception;
      }
    },
    /**
     * Speichert den request erneut beim Benutzer.
     */
    downloadRequest() {
      const data = JSON.stringify(this.request.getSaveableCopy(), null, 2);
      downloadFile(data, this.fileName, document, false);
    },
  },
};
</script>

<style scoped>
button {
  margin-bottom: 1em;
}
</style>
