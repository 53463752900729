<!--
Beispiel für ein einbindbares GUI-Element.

Hier oben sollte der Zweck der Komponente und Hinweise zur Verwendung stehen:

Diese Komponente erzeugt eine farbige Box mit variablem Titel und einem
hochzählbaren Counter.

#PROPS
@param {string} title die Überschrift in der Box
@param {number} initial-count Startwert des Counters
-->
<template>
  <div class="exampleBox">
    <h3>{{ title }}</h3>
    <p>Dies ist das Beispiel-GUI-Element ExampleElement</p>
    <p>
      Alle Werte aus <em>props</em> und <em>data</em> sind direkt über doppelte
      geschweifte Klammern verfügbar: initialCount: {{ initialCount }}, count:
      {{ count }}

      <button v-on:click="count++">+1</button>
    </p>
    <p>Im folgenden Slot wird der Inhalt des example-element-Tags angezeigt:</p>
    <p class="exampleSlot">
      <slot>
        Dies ist ein Platzhaltertext. Er wird nur angezeigt, wenn das
        Elternelement dem example-element-Tag keinen Inhalt gibt.
      </slot>
    </p>
  </div>
</template>

<script>
// Hier kann normaler Javascriptcode stehen. Es können Sachen importiert oder
// auch Funktionen und Variablen definiert werden.
const DEFAULT_COUNT = 42;

// Das hier exportierte Objekt macht zusammen mit dem <template> oben die
// Vue-Komponente aus.
export default {
  // Die props sind Werte, die beim Einbinden der Komponente angegeben werden.
  // Props sollten nicht verändert werden, sondern gegebenenfalls als
  // Initialwerte für eigene Werte in data dienen. #PROPS
  // https://vuejs.org/v2/guide/components.html#Passing-Data-with-Props
  props: {
    initialCount: { type: Number, default: DEFAULT_COUNT },
    title: { type: String, required: true },
  },
  data: function () {
    // Der Wert von count wird hier mit einem Wert aus props initialisiert.
    return { count: this.initialCount };
  },
};
</script>

<style scoped>
/* Hier stehen alle Styles, die diese Komponente betreffen. */
.exampleBox {
  background: #fc0;
  padding: 1em;
}
.exampleBox:hover {
  background: #fd0;
}
.exampleSlot {
  background: #f5c;
  padding: 1em;
}
</style>
