var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DomainDropdown',{attrs:{"server":_vm.showDomains,"email":_vm.showDomains}}),_c('br'),_c('b-form-group',{attrs:{"label":_vm.$t('file_label'),"description":_vm.$t('file_description'),"invalid-feedback":_vm.displayAllFeedback && _vm.fileState === null
        ? _vm.$t('no_file_selected')
        : _vm.fileFeedback,"valid-feedback":_vm.fileFeedback,"state":_vm.displayAllFeedback && _vm.fileState === null ? false : _vm.fileState}},[_c('b-form-file',{attrs:{"accept":".pem, .req, .csr, .p10","state":_vm.displayAllFeedback && _vm.fileState === null ? false : _vm.fileState,"placeholder":_vm.$t('file_placeholder'),"drop_placeholder":_vm.$t('file_placeholder')},on:{"input":_vm.uploadPKCS10},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('b-dropdown',{attrs:{"variant":"primary","id":"prefixes","text":_vm.$t(
        _vm.showOnlySTLPrefixesForServer
          ? 'available_dn_prefixes_stl'
          : 'available_dn_prefixes'
      )}},_vm._l((_vm.dnPrefixes),function(prefix){return _c('b-dropdown-item-button',{key:prefix},[_vm._v(" "+_vm._s(prefix)+" ")])}),1),_c('br')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }