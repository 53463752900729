<template>
  <div>
    <p>
      {{ $t("404") }}
    </p>
  </div>
</template>

<i18n>
    de:
      404: "Die angegebene Einrichtung bzw. RA-ID ist uns leider nicht bekannt. Wenn Sie einen Fehler vermuten, wenden Sie sich bitte an Ihren Teilnehmerservice."
    en:
      404: "The given CA-Name / RA-ID is unknown in our system."
</i18n>

<script>
// suppress eslint warning: 'process' is not defined
/* global process */

/* eslint no-unused-vars: "off" */
import { Logger } from "@/logger/logger";

const log = new Logger("Unknown");

export default {};
</script>
