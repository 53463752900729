/**
 * Konstanten für die Namen der Seiten. Die können von den Komponenten importiert werden.
 * Es ist wichtig, die Konstanten zu verwenden, damit Änderungen schon zur
 * Kompilierzeit bemerkt werden.
 */
export const HOME = "home";
export const LOG = "log";
export const NEW_REQUEST = "new-request";
export const NEW_REQUEST_PN = "new-request-pn";
export const NEW_REQUEST_GROUP = "new-request-group";
export const NEW_REQUEST_SERVER = "new-request-server";
export const NEW_REQUEST_PKCS10 = "new-request-pkcs10";
export const EXAMPLE = "example";
export const UNKNOWN = "unknown";
export const RETRIEVE_CERTIFICATE = "retrieve-certificate";
export const RETRIEVE_CERTIFICATE_SAVE = "retrieve-certificate-save";
export const RETRIEVE_CERTIFICATE_DONE = "retrieve-certificate-done";
export const REVOKE_CERTIFICATE = "revoke-certificate";
export const CONFIRM_REQUEST = "confirm-request";
export const CA_INFOS = "ca-information";
