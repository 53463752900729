<template>
  <div id="app">
    <b-container fluid>
      <router-view></router-view>
    </b-container>
  </div>
</template>

<script>
import { Logger } from "@/logger/logger";

const log = new Logger("App");
log.debug("App.vue startet ...");

export default {
  components: {},
};
</script>

<style>
.container-fluid {
  max-width: 1170px;
  padding-bottom: 1px;
}
</style>
