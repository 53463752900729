import {
  ALLOWED_RDN_TYPES,
  ALLOWED_SAN_TYPES,
  SYNTAX_PATTERN,
} from "@/pki/nameTypes";
import { CERT_TYPES } from "@/pki/certTypes";
import { custom } from "@/customization/customization";
import { Logger } from "@/logger/logger";
const logger = new Logger("InputsForNewRequestCertificate");

const compareInputs = function (input1, input2) {
  const { E, serialNumber, UID, GN, SN, pseudonym, CN, OU, O, L, ST, C, DC } =
    ALLOWED_RDN_TYPES;
  const SE = ALLOWED_SAN_TYPES.email;
  const UPN = ALLOWED_SAN_TYPES.Microsoft_UPN;
  const URI = ALLOWED_SAN_TYPES.URI;

  const order = [
    GN,
    SN,
    pseudonym,
    CN,
    E,
    SE,
    O,
    OU,
    L,
    ST,
    C,
    DC,
    serialNumber,
    UID,
    UPN,
    URI,
  ];
  return order.indexOf(input1.type) - order.indexOf(input2.type);
};

/**
 * Ergänzt die übergebenen Eingabefelder.
 *
 * Es werden die Felder state, id und value hinzugefügt. Das Feld type wird von
 * String in den entsprechenden Wert aus den übergebenen types umgewandelt.
 */
const prepareInputs = function (inputs, types) {
  return inputs.map((input) => ({
    ...input,
    type: types[input.type],
    state: null,
    id: Math.random().toString(36),
    value: "",
  }));
};

/**
 * Hier sind Pseudotypen für das CN-Feld bei Serverzertifikaten definiert.
 *
 * Sie sind grundsätzlich vom Typ CN, haben aber unterschiedliche Syntaxklassen.
 * Die ID ist wichtig, damit sie technisch auseinandergehalten werden können.
 * Javascript scheint die Objekte nur oberflächlich zu vergleichen und die
 * unterschiedlichen Pattern reichen nicht aus.
 */
export const SERVER_HOSTNAME_TYPE = {
  id: "SERVER_HOSTNAME_TYPE",
  pattern: SYNTAX_PATTERN.HOSTNAME,
  maxLength: 64,
  shortName: "CN",
};

export const SERVER_DOMAIN_TYPE = {
  id: "SERVER_DOMAIN_TYPE",
  pattern: SYNTAX_PATTERN.DOMAIN_OR_WILDCARDDOMAIN,
  maxLength: 64,
  shortName: "CN",
};

export const SERVER_IP_TYPE = {
  id: "SERVER_IP_TYPE",
  pattern: SYNTAX_PATTERN.IP,
  maxLength: 64,
  shortName: "CN",
};

export default {
  data() {
    const titleInput = {
      id: "addTitle",
      type: {
        pattern: SYNTAX_PATTERN.TEXT_WITH_AT, // wird nur so gemacht um den auf den Pattern Text_with_at zuzugreifen
        maxLength: 64,
        shortName: "subjectTitle",
      }, // Syntaxprüfung wie für CN
      label: {
        de: this.$t("subjectTitle", "de"),
        en: this.$t("subjectTitle", "en"),
      },
      placeholder: {
        de: this.$t("subjectTitle_placeholder", "de"),
        en: this.$t("subjectTitle_placeholder", "en"),
      },
      state: null,
      optional: true,
      value: "",
    };

    // Input für den Gruppennamen.
    const groupInput = {
      id: "GRP",
      type: {
        pattern: SYNTAX_PATTERN.TEXT_WITH_AT,
        maxLength: 58,
        shortName: "GRP",
      }, // Syntaxprüfung wie für CN
      label: {
        de: this.$t("GRP", "de"),
        en: this.$t("GRP", "en"),
      },
      placeholder: {
        de: this.$t("GRP_placeholder", "de"),
        en: this.$t("GRP_placeholder", "en"),
      },
      state: null,
      optional: false,
      value: "",
    };

    // Input für Server-CN
    const serverInput = {
      id: "server",
      type: SERVER_DOMAIN_TYPE,
      label: {
        de: this.$t("ServerCN", "de"),
        en: this.$t("ServerCN", "en"),
      },
      placeholder: {
        de: this.$t("ServerCN_placeholder", "de"),
        en: this.$t("ServerCN_placeholder", "en"),
      },
      state: null,
      optional: false,
      value: "",
    };

    const serverSANinput = {
      id: "serverSAN",
      type: ALLOWED_SAN_TYPES.IPAddress,
      label: {
        de: this.$t("ServerSAN", "de"),
        en: this.$t("ServerSAN", "en"),
      },
      placeholder: {
        de: this.$t("ServerSAN_placeholder", "de"),
        en: this.$t("ServerSAN_placeholder", "en"),
      },
      state: null,
      optional: true,
      value: "",
    };

    // Inputs für pseudonym/GN+SN, und E anlegen im selben Format wie in custom.
    const defaultInputs = ["pseudonym", "GN", "SN", "CN", "E"];
    // gegebenenfalls OU hinzufügen
    if (!custom.disableOUInput) {
      defaultInputs.push("OU");
    }
    const userApplicationValues = defaultInputs.map((type) => ({
      type: type,
      optional: type == "OU", // OU ist optional
      label: { de: this.$t(type, "de"), en: this.$t(type, "en") },
      placeholder: {
        de: this.$t(type + "_placeholder", "de"),
        en: this.$t(type + "_placeholder", "en"),
      },
    }));

    const allRDNInputObjects = prepareInputs(
      userApplicationValues.concat(custom.additionalUserRdns),
      ALLOWED_RDN_TYPES
    );

    // alle hinzugefügten SANs (nur für Server-Zertifikate)
    const sanInputObjects =
      this.certType == CERT_TYPES.server
        ? []
        : prepareInputs(custom.additionalUserSans, ALLOWED_SAN_TYPES);

    return {
      // developerMode: developerMode,
      titleInput: titleInput,
      groupInput: groupInput,
      serverInput: serverInput,
      serverSANinput: serverSANinput,
      custom: custom,
      rdnTypes: ALLOWED_RDN_TYPES,
      allRDNInputObjects: allRDNInputObjects,
      sanInputObjects: sanInputObjects,
      role: custom.userRoles[0],
      publish: false,
      /* dnPrefixes: dnPrefixes,
      selection: dnPrefixes[0].value, */
      validEmailDomains: null,
      loadingValidEmailDomains: false,
      noGivenName: false,
      cnInputDisabled: true,
      derivedCN: "",
      counterServerSAN: 1,
      noGNSN:
        this.certType == CERT_TYPES.user && custom.policy.newRequest.noGNSN,
    };
  },
  computed: {
    // Nur die angezeigten RDN-Inputs
    inputObjects() {
      // allRDNInputObjects enthält Inputs für alle RDNs:
      // [PN, GN, SN, CN, E, (RDNs aus Custom)]. Die Reihenfolge ist wichtig.
      // Hier werden entweder GN+SN und/oder PN entfernt.
      const inputObjects = [...this.allRDNInputObjects];
      if (this.certType == CERT_TYPES.pseudonym) {
        inputObjects.splice(1, 3);
      } else if (this.certType == CERT_TYPES.group) {
        inputObjects.splice(0, 4);
      } else if (this.certType == CERT_TYPES.user) {
        // Fallunterscheidung Policy #2002
        if (this.noGNSN) {
          inputObjects.splice(0, 3); // Entfernung von PN, SN und GN
        } else {
          inputObjects.shift(); //Entfernung von PN
        }
      } else if (this.certType == CERT_TYPES.server) {
        // Fallunterscheidung, falls keine additionalServerRdns gesetzt sind.
        if (!custom.additionalServerRdns) {
          return [this.serverInput];
        } else {
          return [this.serverInput].concat(
            prepareInputs(custom.additionalServerRdns, ALLOWED_RDN_TYPES)
          );
        }
      }
      return inputObjects;
    },
    // Alle angezeigten Inputs: ggf. Titel, RDNs und SANs
    allInputs() {
      const allInputs = this.inputObjects.concat(this.sanInputObjects);
      if (this.certType == CERT_TYPES.server) {
        return allInputs;
      }
      allInputs.sort(compareInputs);
      if (this.certType == CERT_TYPES.user) {
        // Fallunterscheidung Policy #2002
        if (!this.noGNSN) {
          allInputs.unshift(this.titleInput); // Titel hinzufügen
        }
      } else if (this.certType == CERT_TYPES.group) {
        allInputs.unshift(this.groupInput); // GRP hinzufügen
      }
      return allInputs;
    },
  },
  props: {
    certType: { type: Object, required: true },
  },
  methods: {},
};
