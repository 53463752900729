/**
 * Hier sind Hilfsfunktionen für das Erstellen von Dateinamen.
 */

/**
 * Gibt das übergebene Datum als String im Format YYYY-MM-DD aus.
 *
 * Beispiel: 2019-11-06
 *
 * @param {Date} date zu formatierendes Datum oder null für das aktuelle
 * @returns {String} Datumsstring Bsp.: 2019-11-06
 */
export function getDateString(date) {
  if (!date) {
    date = new Date();
  }
  // "0" voran und abgeschnitten auf die letzten beiden Zeichen
  const day = ("0" + date.getDate()).slice(-2);
  const month = ("0" + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();

  return year + "-" + month + "-" + day;
}

/**
 * Bereinigt Dateinamen auf zulässige Zeichen.
 *
 * Zulässige Zeichen sind: a-z, A-Z, 0-9, Punkt, Binde- und Unterstrich. Alle
 * anderen Zeichen werden ersetzt durch Unterstrich. Bei mehreren zu ersetzenden
 * Zeichen in Folge entsteht lediglich ein Unterstrich.
 *
 * Beispiel:
 * "PN: Teilnehmerservice Test RAID 64 2019-19-18.p12" --->
 * "PN_Teilnehmerservice_Test_RAID_64_2019-19-18.p12"
 *
 * @param {String} name der zu bereinigende Dateiname
 * @returns {String} der bereinigte Name
 */
export function sanitizeFileName(name) {
  return name.replace(/[^a-zA-Z0-9.-]+/gi, "_");
}

/**
 * Erstellt einen Dateinamen aus einem Prefix, einer Endung und einem Datum.
 *
 * Das Muster ist prefix_datum.suffix, wobei das Datum von getDateString()
 * formatiert wird. Der gesamte Name wird durch sanatizeFileName() bereinigt.
 *
 * Beispiel: getFileName("Cert:-", "p12", new Date(1573057070000)) gibt
 * "Cert_-_2019.11.06.p12"
 *
 * @param {String} prefix String am Beginn des Dateinamens
 * @param {String} suffix Dateiendung
 * @param {Date} date Datum, das vor der Dateiendung steht
 * @returns {String} Dateiname aus prefix_datum.suffix
 */
export function getFileName(prefix, suffix, date) {
  return sanitizeFileName(prefix + "_" + getDateString(date) + "." + suffix);
}

/**
 * Erstellt einen Dateinamen aus einem Prefix, einer Endung und einem Zeitraum.
 *
 * Für die Anforderungen siehe Ticket #1763
 *
 * Das Muster ist prefix_startDate_endDate.suffix,
 * wobei das Datum von getDateString() formatiert wird.
 * Der gesamte Name wird durch sanatizeFileName() bereinigt.
 *
 * Beispiel:
 * getFileName("Tim_Tester_105_117", "p12", 01.02.2022, 02.02.2023) gibt
 * "Tim_Tester_105_117_2022-02-01_2023-02-2.p12"
 *
 * @param {String} prefix String am Beginn des Dateinamens
 * @param {String} suffix Dateiendung
 * @param {Date} startDate Datum, ab dem das Zertifikat gültig ist
 * @param {Date} endDate Datum, ab dem das Zertifikat abläuft
 * @returns {String} Dateiname aus prefix_startDate_endDate.suffix
 */
export function getCertFileName(prefix, suffix, startDate, endDate) {
  return sanitizeFileName(
    prefix +
      "_" +
      getDateString(startDate) +
      "_" +
      getDateString(endDate) +
      "." +
      suffix
  );
}

/**
 * Bietet dem Benutzer eine Datei zum Download an.
 *
 * Die herunterzuladenen Daten können als Plaintext oder als Base64-kodierte
 * Binärdaten übergeben werden.
 *
 * @param {String} data zu speichernde Daten
 * @param {String} fileName der vorgeschlagene Dateiname
 * @param {Node} node muss document sein
 * @param {Boolean} isBase64 true, falls die Daten Base64-kodiert sind
 */
export function downloadFile(data, fileName, node, isBase64) {
  if (typeof data !== "string") {
    throw new TypeError(
      "data in downloadFile ist kein String sondern: " + JSON.stringify(data)
    );
  }
  if (typeof fileName !== "string") {
    throw new TypeError(
      "fileName in downloadFile ist kein String sondern: " +
        JSON.stringify(data)
    );
  }

  var dataType = "text/json;charset=utf-8,";

  if (isBase64) {
    dataType = "application/octet-stream;base64,";
  } else {
    // Sonderzeichen in Data encoden
    data = encodeURIComponent(data);
  }

  const link = node.createElement("a");
  link.href = "data:" + dataType + data;
  link.setAttribute("download", fileName);
  node.body.appendChild(link);
  link.click();
  node.body.removeChild(link);
}
