/**
 * In dieser Datei ist die erwartete Struktur der Customization und eine
 * Prüfroutine zu finden.
 */
import { Logger } from "@/logger/logger";

const log = new Logger("structure");

const pattern = {
  URL: /(^https?:\/\/.+$)|^$/, // leerer String wird auch genommen
  RDN_TYPES: /^(E|serialNumber|UID|CN|OU|O|L|ST|C|DC)$/,
  SAN_TYPES: /^(IP|DNS|email|Microsoft_UPN|URI)$/,
  PUBLISH: /^(no|optional|optional_optout|automatic|required)$/,
  POLICY: /^(Global|Grid|Community)$/,
};
// Struktur für einen RDN-/SAN-Input. Wird in expectedStructure eingebunden.
const rdnStructure = {
  type: Object,
  properties: [
    { name: "optional", type: Boolean },
    { name: "type", type: String, pattern: pattern.RDN_TYPES },
    {
      name: "label",
      type: Object,
      properties: [
        { name: "de", type: String },
        { name: "en", type: String },
      ],
    },
    {
      name: "placeholder",
      type: Object,
      properties: [
        { name: "de", type: String },
        { name: "en", type: String },
      ],
    },
  ],
};
// sanStructure unterscheidet sich nur im Pattern für "type".
// Daher wird eine (teilweise) Deep Copy erstellt und das Pattern angepasst.
const sanStructure = { ...rdnStructure };
sanStructure.properties = [...rdnStructure.properties];
sanStructure.properties[1] = { ...rdnStructure.properties[1] };
sanStructure.properties[1].pattern = pattern.SAN_TYPES;
/**
 * Hier wird die erwartete Struktur der Customization beschrieben.
 * Es gibt die Typen Boolean, Number, String, Array und Object.
 * Für die ersten beiden Typen wird lediglich der Name angegeben.
 * Für Strings kann unter pattern mit einem regulären Ausdruck der erwartete
 * Inhalt angegeben werden.
 * Für Arrays wird unter values die erwartete Struktur der Werte angegeben.
 * Für Object wird unter properties eine Liste der erwarteten Eigenschaften
 * angegeben.
 * Für optionale Werte kann mit "default" ein Defaultwert gesetzt werden.
 *
 * Zur besseren Übersicht sind die Werte nach den Typen und dann nach Alphabet
 * sortiert.
 */
export const expectedStructure = {
  type: Object,
  properties: [
    // Booleans
    { name: "disableOUInput", type: Boolean },
    { name: "nonWhitelistedEmailAddressesAllowed", type: Boolean },
    { name: "setCNOnly", type: Boolean, default: false },
    { name: "receiptOnly", type: Boolean, default: false },
    { name: "termsAndConditionsRequired", type: Boolean, default: true },
    // Numbers
    { name: "ra_id", type: Number },
    // Strings
    { name: "ca", type: String },
    { name: "policy", type: String, pattern: pattern.POLICY },
    { name: "publishServer", type: String, pattern: pattern.PUBLISH },
    { name: "publishUser", type: String, pattern: pattern.PUBLISH },
    { name: "pubURL", type: String, pattern: pattern.URL },
    { name: "windowTitle", type: String },
    {
      name: "profileHintURL",
      type: String,
      pattern: pattern.URL,
      default:
        "https://doku.tid.dfn.de/_media/de:dfnpki:doc:dfn-pki-zertifikatprofile.pdf",
    },
    // Arrays
    {
      name: "additionalUserRdns",
      type: Array,
      default: [],
      values: rdnStructure,
    },
    {
      name: "additionalServerRdns",
      type: Array,
      default: [],
      values: rdnStructure,
    },
    {
      name: "additionalUserSans",
      type: Array,
      default: [],
      values: sanStructure,
    },
    { name: "dnPrefixes", type: Array, values: { type: String } },
    { name: "pkcs10Roles", type: Array, values: { type: String } },
    { name: "serverRoles", type: Array, values: { type: String } },
    { name: "userRoles", type: Array, values: { type: String } },
    // Objects
    {
      name: "termsAndConditions",
      type: Object,
      properties: [
        { name: "de", type: String },
        { name: "en", type: String },
      ],
    },
    {
      name: "customLogo",
      type: Object,
      properties: [
        {
          name: "hrefs",
          type: Array,
          default: [],
          values: { type: String, pattern: pattern.URL },
        },
        {
          name: "imgs",
          type: Array,
          default: [],
          values: {
            type: Object,
            properties: [
              { name: "src", type: String },
              { name: "alt", type: String },
            ],
          },
        },
      ],
    },
    {
      name: "dataProtectionAgreement",
      type: Object,
      properties: [
        {
          name: "url",
          type: Object,
          properties: [
            { name: "de", type: String, pattern: pattern.URL },
            { name: "en", type: String, pattern: pattern.URL },
          ],
        },
        {
          name: "linkText",
          type: Object,
          properties: [
            { name: "de", type: String },
            { name: "en", type: String },
          ],
        },
        {
          name: "userText",
          type: Object,
          properties: [
            { name: "de", type: String },
            { name: "en", type: String },
          ],
        },
        {
          name: "serverText",
          type: Object,
          properties: [
            { name: "de", type: String },
            { name: "en", type: String },
          ],
        },
      ],
    },
  ],
};

/**
 * Prüft rekursiv ob ein Wert die erwartete Struktur hat. Es wird rekursiv
 * geprüft, ob die Werte von Arrays den richtigen Typ haben und ob Objekte
 * alle erwarteten Propertys vom richtigen Typ haben. Es wird aktuell nicht
 * geprüft, ob zu viele Propertys vorhanden sind.
 *
 * TODO: Unittest
 *
 * @param {any} value der zu prüfende Wert
 * @param {String} name der Name des Wertes (für die Ausgabe)
 * @param {Object} expectedStructure die erwartete Struktur des Wertes
 */
export function checkStructure(value, name, expectedStructure) {
  const type = expectedStructure.type;
  if (value.constructor !== type) {
    log.warn(
      name + " hat unerwarteten Typ " + value.constructor + " statt " + type
    );
    return false;
  }
  log.trace("Wert " + name + " hat den korrekten Typ " + type);

  let success = true;
  switch (type) {
    // Für die Typen Boolean, Number und String ist die Prüfung hier fertig.
    case Boolean:
    case Number:
      return true;
    case String: {
      const pattern = expectedStructure.pattern;
      if (pattern !== undefined && !pattern.test(value)) {
        // Falls ein Pattern definiert ist, String dagegen testen.
        log.warn(name + " = " + value + " passt nicht auf Muster " + pattern);
        return false;
      }
      return true;
    }
    // Für Arrays werden alle Werte geprüft.
    case Array: {
      let i = 0;
      for (let v of value) {
        const fullname = name + "[" + i + "]";
        success =
          checkStructure(v, fullname, expectedStructure.values) && success;
        i++;
      }
      return success;
    }
    // Für Objects wird die Objektstruktur überprüft.
    case Object:
      for (let prop of expectedStructure.properties) {
        const fullname = name + "." + prop.name;
        if (!Object.prototype.hasOwnProperty.call(value, prop.name)) {
          // Wenn ein erwartetes Feld nicht vorhanden ist, wird entweder ein
          // vorhandener Default gesetzt oder ein Fehler geloggt.
          if (Object.prototype.hasOwnProperty.call(prop, "default")) {
            const defaultString = JSON.stringify(prop.default);
            log.debug("Nutze default " + defaultString + " für " + fullname);
            value[prop.name] = prop.default;
          } else {
            log.warn("Objekt " + name + " hat keine Property " + prop.name);
            success = false;
          }
          continue;
        }
        // Rekursiver Aufruf für Property
        success = checkStructure(value[prop.name], fullname, prop) && success;
      }
      return success;
    // Andere Typen sind durch die Typprüfung oben ausgeschlossen.
    default:
      throw new Error("Wert " + name + " hat unbekannten Typ " + type);
  }
}
