/**
 * Javascript-Einstiegspunkt. Hier wird die Haupt-Vue-Komponente (App.vue)
 * in einer neuen Vue-Instanz mit dem vuex-Store und dem Router initialisiert.
 */

// // The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";

import { i18n } from "@/i18n/i18n";
import { router } from "@/router/router";
import App from "@/App";
import BootstrapVue from "bootstrap-vue";
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/styles/style.scss";

Vue.use(VueRouter);
Vue.use(BootstrapVue);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  i18n,
  render: (h) => h(App),
  router,
});
