<!--
Dies ist die Startseite von Wenja.
-->
<template>
  <div>
    <h1>{{ $t("title", { pki_name: pki_name }) }}</h1>
    <p>{{ $t("intro1") }}</p>
    <!-- Vorrübergehende Mitteilung zur Abkündigung der Serverzertifikate
        in der Global Policy, siehe Ticket #2049 -->
    <p
      v-if="this.custom.policy.showNotification && this.isBeforeDateGlobal"
      style="color: red"
    >
      {{ $t("notification") }}
    </p>

    <!-- Beliebig viele card-groups, die jeweils maximal drei Cards in einer
    Zeile enthalten. Die Texte der Card werden über link.id (+ _text|_button)
    aus den i18n-Texten geholt. Die Links werden durch link.name angegeben. -->
    <b-card-group deck v-for="links in linkGroup" :key="links[0].name + 'l'">
      <b-card
        v-for="link in links"
        :key="link.name"
        header-tag="header"
        footer-tag="footer"
      >
        <template #header>
          {{ $t(link.id) }}
        </template>
        <b-card-text>{{ $t(link.id + "_text") }}</b-card-text>
        <template #footer>
          <b-button
            variant="primary"
            :to="{ name: link.name, params: link.params }"
          >
            {{ $t(link.id + "_button") }}
          </b-button>
        </template>
      </b-card>
    </b-card-group>
  </div>
</template>

<i18n>
    en:
      notification: "Please note: As of 30.08.2023, certificates will no longer be issued in the DFN-PKI Global. In the future, you will receive new certificates via the GÉANT TCS service. Please contact your local subscriber service at your institution for information on specific application procedures."
      title: "Welcome to the {pki_name}"
      intro1: "Apply for certificates or retrieve or - if necessary - revoke the certificates you have applied for and which were issued for you."
      new_request: "User certificate (incl. key generation)"
      new_request_pn: "Pseudonym certificate (incl. key generation)"
      new_request_group: "Group certificate (incl. key generation)"
      new_request_server: "Server certificate (incl. key generation)"
      new_request_pkcs10: "CSR (PKCS#10) upload"
      retrieve_certificate: "Retrieve certificate"
      revoke_certificate: "Revoke certificate"

      new_request_text: "Apply for your new personal user certificate (example name 'John Q. Doe')."
      new_request_pn_text: "Apply for your new personal pseudonym certificate (example name 'PN - My Pseudonym')"
      new_request_group_text: "Apply for a new group certificate for a named group of people (example name 'GRP - IT Helpdesk')."
      new_request_server_text: "Apply for a new server certificate (key pair and CSR are generated by webbrowser)."
      new_request_pkcs10_text: "Apply for a new server, user, pseudonym or group certificate providing your own existing CSR (PKCS#10) file."
      retrieve_certificate_text: "Retrieve an issued certificate, once its certificate application has been received and approved by your local subscriber service."
      revoke_certificate_text: "Request revocation of your certificate."

      new_request_button: "Request user certificate"
      new_request_pn_button: "Request pseudonym certificate"
      new_request_group_button: "Request  group certificate"
      new_request_server_button: "Request server certificate"
      new_request_pkcs10_button: "Upload CSR (PKCS#10) file"
      retrieve_certificate_button: "Retrieve certificate"
      revoke_certificate_button: "Revoke certificate"
    de:
      notification: "Bitte beachten Sie: Ab 30.08.2023 werden in der DFN-PKI Global keine Zertifikate mehr ausgestellt. Neue Zertifikate erhalten Sie in Zukunft über den Dienst GÉANT TCS. Bitte wenden Sie sich für Informationen zu konkreten Beantragungsverfahren an Ihren lokalen Teilnehmerservice Ihrer Einrichtung."
      title: "Willkommen zur {pki_name}"
      intro1: "Hier können Sie Zertifikate beantragen oder Ihre beantragten und von Ihrem Teilnehmerservice ausgestellten Zertifikate abholen und im Bedarfsfall auch wieder sperren lassen."
      new_request: "Nutzerzertifikat (inkl. Schlüsselerzeugung)"
      new_request_pn: "Pseudonymzertifikat (inkl. Schlüsselerzeugung)"
      new_request_group: "Gruppenzertifikat (inkl. Schlüsselerzeugung)"
      new_request_server: "Serverzertifikat (inkl. Schlüsselerzeugung)"
      new_request_pkcs10: "Eigene CSR-Datei (PKCS#10) einreichen"
      retrieve_certificate: "Zertifikat abholen"
      revoke_certificate: "Zertifikat sperren"

      new_request_text: "Hier können Sie ein neues persönliches Nutzerzertifikat (Namensbeispiel 'Max B. Mustermann') für sich beantragen."
      new_request_pn_text: "Hier können Sie ein neues persönliches Pseudonymzertifikat (Namensbeispiel 'PN - Mein Pseudonym') für sich beantragen."
      new_request_group_text: "Hier können Sie ein neues Gruppenzertifikat (Namensbeispiel 'GRP - IT Helpdesk') für eine dedizierte Gruppe von Personen beantragen."
      new_request_server_text: "Hier können Sie ein neues Serverzertifikat beantragen, inkl. Schlüsselerzeugung durch den Web-Browser."
      new_request_pkcs10_text: "Hier können Sie eine bereits vorliegende CSR-Datei (PKCS#10) für die Beantragung eines Zertifikats einreichen."
      retrieve_certificate_text: "Hier können Sie ein ausgestelltes Zertifikat abholen, sofern der zugehörige Zertifikatantrag bereits eingegangen ist und genehmigt wurde."
      revoke_certificate_text: "Hier können Sie die Sperrung Ihres Zertifikats beauftragen."

      new_request_button: "Nutzerzertifikat beantragen"
      new_request_pn_button: "Pseudonymzertifikat beantragen"
      new_request_group_button: "Gruppenzertifikat beantragen"
      new_request_server_button: "Serverzertifikat beantragen"
      new_request_pkcs10_button: "CSR-Datei (PKCS#10) einreichen"
      retrieve_certificate_button: "Zertifikat abholen"
      revoke_certificate_button: "Zertifikat sperren"
</i18n>

<script>
import { Logger } from "@/logger/logger";
import { brand } from "@/brand/brand";
import { custom } from "@/customization/customization";
import {
  NEW_REQUEST,
  NEW_REQUEST_PN,
  NEW_REQUEST_GROUP,
  NEW_REQUEST_SERVER,
  NEW_REQUEST_PKCS10,
  RETRIEVE_CERTIFICATE,
  REVOKE_CERTIFICATE,
} from "@/router/names";

const logger = new Logger("StartPage");

// Listen der Links, die angezeigt werden sollen.
// Es sollten maximal 3 Links einer Liste hinzugefügt werden.
const linkRow1 = [
  {
    name: NEW_REQUEST,
    id: "new_request",
    params: { step: "1" },
  },
  {
    name: NEW_REQUEST_PN,
    id: "new_request_pn",
    params: { step: "1" },
  },
  {
    name: NEW_REQUEST_GROUP,
    id: "new_request_group",
    params: { step: "1" },
  },
];
const linkRow2 = [
  {
    name: NEW_REQUEST_SERVER,
    id: "new_request_server",
    params: { step: "1" },
  },
  {
    name: NEW_REQUEST_PKCS10,
    id: "new_request_pkcs10",
    params: { step: "1" },
  },
];
const linkRow3 = [
  {
    name: RETRIEVE_CERTIFICATE,
    id: "retrieve_certificate",
  },
  {
    name: REVOKE_CERTIFICATE,
    id: "revoke_certificate",
  },
];
const gridPolicyLinkRow = [
  {
    name: NEW_REQUEST,
    id: "new_request",
    params: { step: "1" },
  },
  {
    name: NEW_REQUEST_SERVER,
    id: "new_request_server",
    params: { step: "1" },
  },
  {
    name: NEW_REQUEST_PKCS10,
    id: "new_request_pkcs10",
    params: { step: "1" },
  },
];
const globalPolicyLinkRow1 = [
  {
    name: NEW_REQUEST,
    id: "new_request",
    params: { step: "1" },
  },
  {
    name: NEW_REQUEST_PN,
    id: "new_request_pn",
    params: { step: "1" },
  },
];
const globalPolicyLinkRow2 = [
  {
    name: NEW_REQUEST_GROUP,
    id: "new_request_group",
    params: { step: "1" },
  },
  {
    name: NEW_REQUEST_PKCS10,
    id: "new_request_pkcs10",
    params: { step: "1" },
  },
];

export default {
  data: function () {
    // Datum der Abkündigung #2073
    const isBeforeDateGlobal = new Date() < new Date(2023, 7, 30);

    // Je nach Policy werden verschiedene Links angezeigt
    const linkGroup = custom.policy.hidePseudonymGroup
      ? [gridPolicyLinkRow, linkRow3]
      : custom.policy.hideAllRequestPages && !isBeforeDateGlobal
      ? [linkRow3]
      : custom.policy.hideServer
      ? [globalPolicyLinkRow1, globalPolicyLinkRow2, linkRow3]
      : [linkRow1, linkRow2, linkRow3];

    return {
      linkGroup: linkGroup,
      pki_name: brand.pkiName,
      custom: custom,
      isBeforeDateGlobal: isBeforeDateGlobal,
    };
  },
};
</script>
