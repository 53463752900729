<template>
  <div>
    <b-overlay :show="showOverlay" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-spinner
            style="width: 3rem; height: 3rem"
            type="grow"
            label="Spinning"
          ></b-spinner>
          <p>{{ $t("loading_Message") }}</p>
        </div>
      </template>
      <h1>{{ $t("title") }}</h1>
      <!-- Schritt 1: Antragsdatei hochladen, Passwort eingeben. -->
      <form
        v-if="step === 1"
        v-on:submit.prevent
        v-on:keyup.enter="downloadAndDisplayCertificate"
      >
        <b-form-group
          :label="$t('file_label')"
          :description="$t('file_description')"
          :invalid-feedback="fileFeedback"
          :valid-feedback="fileFeedback"
          :state="fileState"
        >
          <b-form-file
            v-model="file"
            accept=".json"
            :state="fileState"
            :placeholder="$t('file_placeholder')"
            :drop_placeholder="$t('file_placeholder')"
            @input="loadTextFromFile"
          ></b-form-file>
        </b-form-group>
        <b-form-group
          :description="$t('password_description')"
          :invalid-feedback="passwordFeedback"
          :valid-feedback="passwordFeedback"
          :state="passwordState"
        >
          <label for="password_input"> {{ $t("password_label") }} </label>
          <b-form-input
            id="password_input"
            v-model="password"
            :placeholder="$t('password_placeholder')"
            :state="passwordState"
            @input="passwordState = null"
            type="password"
            autocomplete="current-password"
          ></b-form-input>
        </b-form-group>
        <!-- <b-button @click="$router.go(-1)">{{ $t("back") }}</b-button> -->
        <b-button
          variant="primary"
          :disabled="password == null || password.length < 8 || !fileState"
          @click="downloadAndDisplayCertificate"
          >{{ $t("continue") }}</b-button
        >
      </form>

      <!-- Schritt 2: Zertifikat runterladen und anzeigen. -->
      <div v-if="step === 2 && certPEM !== null">
        <p v-html="$t('cert_retrieved_' + (hasKey ? 'hasKey' : 'pem'))"></p>
        <display-certificate :certPEM="certPEM" :request="request">
        </display-certificate>
        <!--<pre> {{ JSON.stringify(certInfo, null, 2) }} </pre>-->
        <b-button variant="primary" @click="$router.go(-1)">{{
          $t("back")
        }}</b-button>
        <div></div>
        &nbsp;
        <!-- Bei Vorliegen eines privaten Schlüssels:
             Abfrage eines neuen Passworts -->
        <div v-if="hasKey">
          <NewPassword
            :title="$t('p12Password_title')"
            :label="$t('p12Password_label')"
            :buttonText="$t('save_certificate') + ' (.p12)'"
            @newPassword="saveP12"
          ></NewPassword>
          &nbsp;
          <NewPassword
            :title="$t('pemPassword_title')"
            :label="$t('pemPassword_label')"
            :buttonText="$t('save_certificate') + ' (.pem)'"
            @newPassword="savePem(true)"
          ></NewPassword>
          <!--<b-button variant="primary" @click="savePem(true)">
            {{ $t("save_certificate") }}
          </b-button>-->
        </div>
        <!-- Kein privater Schlüssel: Speichern des PEMs. -->
        <div v-else>
          <b-button variant="primary" @click="savePem(false)">
            {{ $t("save_certificate") }}
          </b-button>
          &nbsp;
          <b-button variant="primary" @click="savePem(true)">
            {{ $t("save_chain") }}
          </b-button>
        </div>
      </div>

      <!-- Schritt 3: Fertig -->
      <div v-if="step === 3">
        <div v-if="hasKey">
          <p
            v-html="
              $t('certificate_usage_' + (isP12 ? 'p12' : 'pemWithKey'), {
                file_name: file.name,
              })
            "
          ></p>
        </div>
        <div v-else>
          <p
            v-html="
              $t('certificate_usage_pem', {
                file_name: file.name,
              })
            "
          ></p>
        </div>
        <b-button variant="primary" @click="$router.go(-1)">{{
          $t("back")
        }}</b-button>
      </div>
    </b-overlay>
  </div>
</template>

<i18n>
    // Die i18n-Strings sind in der Reihenfolge aufgeführt, in der sie der Benutzer zu sehen bekommt:
    // Strings, die noch nicht abgenommen wurden, sind mit einem Warndreieck markiert:
    en:
      title: "Download your certificate"

      // Schritt 1 - Dateiauswahl:
      file_label: "To download your requested certificate you need the certificate application data file, that you downloaded when applying for this certificate."
      file_description: "Your certificate application data file with file extension .json"
      file_placeholder: "Certificate application data file (.json)"
      parse_error: "The selected file contains no certificate application data. Please choose a different certificate application data file!"
      wrong_file: "You are about to download the certificate for certificate application number {retrieveRequestSerial}.
        The certificate application data file you supplied contains certificate application number {serial} for \"{CN}\"
        from {date}. Please choose the certificate application data file for certificate application number {retrieveRequestSerial}."
      parsed_successfully: "Certificate application {serial} for \"{CN}\" from {date}"
      password_label: "Please enter the passphrase that protects the certificate application data file."
      password_description: "This passphrase has been set by you during the application process for this certificate whilst downloading the certificate application data file."
      password_placeholder: "Passphrase of the certificate application data file"
      password_error: "The passphrase is incorrect."
      password_success: "The passphrase is correct."
      continue: "Continue"
      error_fetching_cert: "Failure to download your certificate.
        Your certificate might not have been issued yet.
        Did you submit your certificate application to your local {pki_name} subscriber-service?"

      // Schritt 2 - Antrags- und Zertifikatsdaten anzeigen:
      back: "Back"
      cert_retrieved_hasKey: "<p>Your certificate was issued.</p>

        <p>Please click on the button \"Download certificate file
        (.p12)\" below, to download your certificate including its private key in
        PKCS#12 format (file extension .p12) onto your device for a
        direct import into certificate managers or keyrings of email
        programs, web-browsers, Microsoft Internet Information Servers
        (IIS), MS Exchange Servers and operating systems etc. This is the
        recommended option for S/MIME, email, client and user
        certificates.</p>

        <p>Please click on the button \"Download certificate file
        (.pem)\" below, to downlowd the certificate including its private key
        in PEM format (file extension .pem) and the full CA certificate
        chain onto your device, to configure it e.g. in Apache and
        Nginx web-servers, Sendmail and Postfix email servers, OpenLDAP
        and FreeRADIUS servers etc. This is the recommended option for
        server certificates (except for use in MS IIS and MS Exchange
        Servers etc) and for usage with the command-line tool \"openssl\".</p>"


      cert_retrieved_pem: "<p>Your certificate was issued.</p>

        <p>Please click on the button \"Download certificate file\" below, to
        download your certificate in PEM format (without CA certificate
        chain) onto your device.</p>

        <p>Please click the button \"Download certificate file with complete CA certificate
        chain\" below, to download your certificate including the full CA certificate
        chain in PEM format onto your device.</p>"
      p12Password_title: "Set certificate passphrase"
      p12Password_label: "Please enter a passphrase to protect your certificate file including its private key."
      pemPassword_title: "Set certificate passphrase"
      pemPassword_label: "Please enter a passphrase to protect your private key."
      save_certificate: "Download certificate file"
      save_chain: "Download certificate file with complete CA certificate chain"

      loading_Message: "Please wait, the certificate file is being composed by your webbrowser and will be available for saving shortly."

      // Schritt 3 - Abschlussseite:
      certificate_usage_p12: "<p>Your certificate and its private key have been saved into your web-browser's standard download folder or
        alternatively into the folder you chose during the download process.
        Your certificate file is in PKCS#12 format (file extension .p12).</p>

        <p>The certificate file is protected with the passphrase you just specified.</p>

        <p>You may import the certificate file for further usage into the software (e.g. web-browser, email program, operating system etc)
        in which you want to use your certificate. You may also copy the certificate file onto other devices if you need your
        certificate on those.</p>

        <p>Backup: Please backup your certificate file and its passphrase, in a way that both are still available if your device is lost or damaged, to be
        able to decrypt any encrypted data (e.g. emails and files etc) that have been encrypted with your certificate.</p>

        <p>Certificate application data file ({file_name}):
        Please note that this file contains the  password protected private key of your certificate.
        This file is still encrypted.
        If you want to keep the file, please protect it from unauthorized access.
        If you don't need the file anymore, please delete it reliably.</p>"
      certificate_usage_pem: "<p>Your certificate has been saved into
        your web-browser's standard download folder or alternatively
        into the folder you chose during the download process. Your
        certificate file is in PEM format (file extension .pem).</p>

        <p>You may import the certificate file for further usage into
        the software (e.g. web-browser, email program, operating
        system etc) in which you want to use your certificate. You may
        also copy the certificate file onto other devices if you need
        your certificate on those.</p>

        <p>The downloaded file does not contain a private key. The corresponding
        private key should be available to you on the device where you created
        the Certificate Signing Request (CSR).</p>"
      certificate_usage_pemWithKey: "<p>Your certificate and its
        private key have been saved together with the full CA
        certificate chain into your web-browser's standard download
        folder or alternatively into the folder you chose during the
        download process. Your certificate, its private key and the CA
        certificates in that file (file extension .pem) are in PEM
        format.</p>

        <p>The included private key is protected with the passphrase
        you just specified.</p>

        <p>You may configure/import the certificate file for further
        usage in/into the software (e.g. operating systems, servers that
        use PEM-formatted certificate configurations like e.g. Apache,
        Nginx, Sendmail, postfix, OpenLDAP, FreeRADIUS etc or the
        commandline tool \"openssl\") in which you want to use your
        certificate. You may also copy the certificate file onto other
        devices if you need your certificate on those.</p>

        <p>Backup: Please backup your certificate file and the
        passphrase for the contained private key, in a way that both
        are still available if your device is lost or damaged, to be
        able to decrypt any encrypted data (e.g. emails and files) that
        have been encrypted with your certificate.</p>

        <p>Certificate application data file ({file_name}):
        Please note that this file contains the password protected
        private key of your certificate.
        If you want to keep the file, please protect it from
        unauthorized access.
        If you don't need the file anymore, please delete it
        reliably.</p>"

    de:
      title: "Zertifikat abholen"

      // Schritt 1 - Dateiauswahl:
      file_label: "Um ein von Ihnen beantragtes Zertifikat abzuholen, benötigen
        Sie die Antragsdatei, die Sie bei der Antragsstellung gespeichert haben."
      file_description: "Ihre Antragsdatei mit der Dateiendung .json"
      file_placeholder: "Antragsdatei"
      parse_error: "Die Datei enthält keine Antragsdaten. Bitte wählen Sie eine andere Antragsdatei!"
      wrong_file: "Sie sind dabei das Zertifikat für Antrag {retrieveRequestSerial}
        herunterzuladen. Die Antragsdatei enthält aber Antrag {serial} für \"{CN}\"
        vom {date}. Bitte wählen Sie die Antragsdatei für Antrag {retrieveRequestSerial} aus."
      parsed_successfully: "Antrag {serial} für \"{CN}\" vom {date}"
      password_label: "Bitte geben Sie hier Ihr Passwort ein, mit dem die Antragsdatei geschützt ist."
      password_description: "Das Passwort haben Sie bei der Antragsstellung beim Abspeichern der Antragsdatei vergeben."
      password_placeholder: "Passwort der Antragsdatei"
      password_error: "Das Passwort ist leider nicht korrekt."
      password_success: "Das Passwort ist korrekt."
      continue: "Weiter"
      error_fetching_cert: "Ihr Zertifikat konnte leider nicht heruntergeladen
        werden. Wahrscheinlich wurde das Zertifikat noch nicht ausgestellt. Haben
        Sie den Zertifikatantrag schon bei Ihrem {pki_name} Teilnehmerservice abgegeben?"

      // Schritt 2 - Antrags- und Zertifikatsdaten anzeigen:
      back: "Zurück"
      cert_retrieved_hasKey: "<p>Ihr Zertifikat wurde ausgestellt.</p>

        <p>Klicken Sie unten auf den Button \"Zertifikatdatei speichern
        (.p12)\", um das Zertifikat zusammen mit dem privaten Schlüssel
        im PKCS#12-Format (Dateiendung .p12) auf Ihrem Gerät für den
        direkten Import in die Zertifikatverwaltungen bzw.
        Schlüsselringe von E-Mail-Programmen, Browsern, Microsoft
        Internet Information Server (IIS), Microsoft Exchange Server und
        Betriebssystemen usw. abzuspeichern. Empfohlen für S/MIME, E-Mail,
        Client- und Nutzerzertifikate.</p>

        <p>Klicken Sie unten auf den Button \"Zertifikatdatei speichern
        (.pem)\", um das Zertifikat zusammen mit dem privaten Schlüssel
        im PEM-Format (Dateiendung .pem) und der vollständigen CA-Zertifikatskette
        auf Ihrem Gerät für die Konfiguration von z.B. Apache- und
        Nginx-Web-Servern, Sendmail-, Postfix-, OpenLDAP- und
        FreeRADIUS-Servern usw. abzuspeichern. Empfohlen für
        Server-Zertifikate (außer bei Nutzung von MS IIS und Exchange
        Servern) und zur Nutzung mit dem Kommandozeilenwerkzeug
        \"openssl\".</p>"

      cert_retrieved_pem: "<p>Ihr Zertifikat wurde ausgestellt.</p>

        <p>Klicken Sie auf den Button \"Zertifikatdatei speichern\", um das
        Zertifikat im PEM-Format (ohne CA-Kette) auf Ihrem Gerät abzuspeichern.</p>

        <p>Klicken Sie unten auf den Button \"Zertifikatdatei inklusive vollständiger
        CA-Zertifikatskette speichern\", um das Zertifikat im PEM-Format inklusive der
        vollständigen CA-Zertifikatskette auf Ihrem Gerät abzuspeichern.</p>"
      p12Password_title: "Zertifikatpasswort setzen"
      p12Password_label: "Bitte setzen Sie ein Passwort zum Schutz Ihrer Zertifikatdatei."
      pemPassword_title: "Zertifikatpasswort setzen"
      pemPassword_label: "Bitte setzen Sie ein Passwort zum Schutz Ihres privaten Schlüssels."
      save_certificate: "Zertifikatdatei speichern"
      save_chain: "Zertifikatdatei inklusive vollständiger CA-Zertifikatskette speichern"
      loading_Message: "Bitte warten, die Zertifikatdatei wird durch Ihren Browser erstellt und steht gleich zum Abspeichern bereit."

      // Schritt 3 - Abschlussseite:
      certificate_usage_p12: "<p>Ihr Zertifikat ist zusammen mit dessen privaten Schlüssel im Standard-Download-Verzeichnis
        Ihres Browsers oder in dem von Ihnen beim Abspeichern ausgewählten Verzeichnis gespeichert worden.
        Die Zertifikatdatei liegt im PKCS#12-Format (Dateiendung .p12) vor.</p>

        <p>Die Zertifikatdatei ist mit dem von Ihnen eben vergebenen Passwort geschützt.</p>

        <p>Sie können die Zertifikatdatei zur weiteren Verwendung nun in die Software
        (z.B. Browser, Mail-Programm, Betriebssystem usw.) importieren, in der Sie das Zertifikat nutzen möchten.
        Sie können die Zertifikatdatei auch auf andere Geräte kopieren, auf denen Sie das Zertifikat nutzen wollen.</p>

        <p>Backup: Bitte sichern Sie diese Zertifikatdatei und das zugehörige Passwort, so dass beide auch bei Verlust
        oder Beschädigung Ihres Gerätes noch zur Verfügung stehen, um gegebenenfalls damit verschlüsselte Daten
        (z.B. E-Mails und Dateien usw.) wieder entschlüsseln zu können.</p>

        <p>Antragsdatei ({file_name}): Bitte beachten Sie, dass in dieser Datei der private Schlüssel zu Ihrem Zertifikat enthalten ist.
        Diese Datei ist nach wie vor passwortgeschützt.
        Sofern Sie diese Datei aufbewahren wollen, schützen Sie sie bitte angemessen vor unberechtigten Zugriffen.
        Ansonsten löschen Sie die Datei bitte nachhaltig.</p>"
      certificate_usage_pem: "<p>Ihr Zertifikat ist im Standard-Download-Verzeichnis
        Ihres Browsers oder in dem von Ihnen beim Abspeichern ausgewählten Verzeichnis gespeichert worden.
        Die Zertifikatdatei liegt im PEM-Format (Dateiendung .pem) vor.</p>

        <p>Sie können die Zertifikatdatei zur weiteren Verwendung nun in die Software
        (z.B. Browser, Mail-Programm, Betriebssystem usw.) importieren, in der Sie das Zertifikat nutzen möchten.
        Sie können die Zertifikatdatei auch auf andere Geräte kopieren, auf denen Sie das Zertifikat nutzen wollen.</p>

        <p>Die gespeicherte Datei enthält keinen privaten Schlüssel. Der zugehörige private Schlüssel sollte Ihnen
        bereits anderweitig zur Verfügung stehen, z.B. auf dem Gerät, auf dem Sie vorher den
        Certificate Signing Request (CSR) erstellt haben.</p>"
      certificate_usage_pemWithKey: "<p>Ihr Zertifikat ist zusammen mit dessen privaten Schlüssel sowie der vollständigen CA-Zertifikatskette
        im Standard-Download-Verzeichnis
        Ihres Browsers oder in dem von Ihnen beim Abspeichern ausgewählten Verzeichnis gespeichert worden.
        Die Zertifikatdatei liegt im PEM-Format (Dateiendung .pem) vor.</p>

        <p>Der private Schlüssel innerhalb der Zertifikatdatei ist mit dem von Ihnen eben vergebenen Passwort geschützt.</p>

        <p>Sie können die Zertifikatdatei zur weiteren Verwendung nun in Ihrer Software
        konfigurieren, in der Sie das Zertifikat nutzen möchten, z.B. in Apache- und Nginx-Web-Servern oder
        mit dem \"openssl\" Kommandozeilenwerkzeug.
        Sie können die Zertifikatdatei auch auf andere Geräte kopieren, auf denen Sie das Zertifikat nutzen wollen.</p>

        <p>Backup: Bitte sichern Sie diese Zertifikatdatei und das zugehörige Passwort, so dass beide auch bei Verlust
        oder Beschädigung Ihres Gerätes noch zur Verfügung stehen, um gegebenenfalls damit verschlüsselte Daten
        (z.B. E-Mails und Dateien usw.) wieder entschlüsseln zu können.</p>

        <p>Antragsdatei ({file_name}): Bitte beachten Sie, dass in dieser Datei ebenfalls der private Schlüssel zu Ihrem Zertifikat enthalten ist.
        Diese Datei ist nach wie vor passwortgeschützt.
        Sofern Sie diese Datei aufbewahren wollen, schützen Sie sie bitte angemessen vor unberechtigten Zugriffen.
        Ansonsten löschen Sie die Datei bitte nachhaltig.</p>"
</i18n>

<script>
import { brand } from "@/brand/brand";
import { custom } from "@/customization/customization";
import { DFNSOAPClient } from "@/soap/dfnsoapclient";
import { BFormFile } from "bootstrap-vue";
import DisplayCertificate from "@/components/pages/retrieve-certificate/DisplayCertificate";
import NewPassword from "@/components/gui-elements/NewPassword";
import { Request } from "@/pki/request";
import { encrypt, decrypt, createP12, encryptRSAKeyToPem } from "@/pki/dfnpki";
import {
  getFileName,
  getCertFileName,
  downloadFile,
} from "@/utils/filehandling";
import { Logger } from "@/logger/logger";
import { __decrypt, parseCert } from "@/pki/dfnpki";
import {
  RETRIEVE_CERTIFICATE,
  RETRIEVE_CERTIFICATE_SAVE,
  RETRIEVE_CERTIFICATE_DONE,
} from "@/router/names";

const logger = new Logger("RetrieveCertificate");

const pki_name = brand.pkiName;

export default {
  components: { BFormFile, DisplayCertificate, NewPassword },
  name: "RetrieveCertificate",
  props: {
    retrieveRequestSerial: { default: null },
    step: { type: Number, default: 1 },
  },
  data() {
    return {
      file: null,
      fileState: null,
      fileFeedback: "",
      password: null,
      passwordState: null,
      passwordFeedback: "",
      request: null,
      certPEM: null,
      showOverlay: false,
      hasKey: false, // true, wenn die Antragsdatei einen privaten Schluesseln beinhaltet
      isP12: false, // true, wenn die Zertifikatdatei als p12 gespeichert wurde
    };
  },
  created() {
    if (this.step > 1 && !this.request) {
      // Seiteneinstieg nicht bei /certificates sondern /certificates/save etc.
      logger.info("Seiteneinstieg nicht beim Start, mache $router.replace");
      this.$router.replace({ name: RETRIEVE_CERTIFICATE });
    }
  },
  methods: {
    /**
     * Funktion, die aufgerufen wird, wenn eine Datei ausgwählt wurde.
     *
     * Erstellt einen Reader, übergibt ihm die Datei und registriert parseFile()
     * als Parser.
     */
    async loadTextFromFile() {
      const file = this.file; // ev.target.files[0];
      if (!file) {
        throw new Error("keine Antragsdatei ausgewählt.");
      }
      logger.info("Lese Antragsdatei " + file.name);

      const reader = new FileReader();

      reader.onload = (e) => this.parseFile(e.target.result);
      reader.readAsText(file);
    },

    /**
     * Parst den Inhalt der ausgewählten Datei.
     *
     * Wenn der Inhalt kein JSON ist oder keine Request-Datenstruktur enthält,
     * wird eine Fehlermeldung angezeigt.
     * Wenn ein Request geparst wurde, wird eine Erfolgsmeldung angezeigt.
     *
     * Gesetzte Variablen: fileState, fileFeedback, request
     */
    parseFile(content) {
      var requestData;
      try {
        requestData = JSON.parse(content);
        this.request = new Request(requestData);
      } catch (e) {
        logger.error("Konnte Datei nicht parsen: " + e);
        this.fileState = false;
        this.fileFeedback = this.$t("parse_error");
        this.error(this.$t("parse_error"));
        return;
      }
      if (
        this.retrieveRequestSerial &&
        this.request.serial != this.retrieveRequestSerial
      ) {
        this.fileState = false;
        this.fileFeedback = this.$t("wrong_file", {
          retrieveRequestSerial: this.retrieveRequestSerial,
          serial: this.request.serial,
          CN: this.request.getCN(),
          date: this.request.date.toLocaleDateString(this.$i18n.locale),
        });
        return;
      }

      this.fileState = true;
      this.fileFeedback =
        this.$t("parsed_successfully", {
          serial: this.request.serial,
          CN: this.request.getCN(),
          date: this.request.date.toLocaleDateString(this.$i18n.locale),
        }) + (this.request.comment ? " (" + this.request.comment + ")" : "");

      if (this.request.encryptedPrivateKey) {
        // Wenn die Antragsdatei einen privaten Schlüssel beinhaltet, kann ein
        // P12 generiert werden, oder PEM mit private key.
        // Andernfalls wird nur ein PEM ohne private key angeboten.
        this.hasKey = true;
      } else {
        this.hasKey = false;
      }
    },

    /**
     * Wird aufgerufen, wenn Datei ausgewählt und Passwort eingegeben wurde.
     *
     * Entschlüsselt den PIN-Hash und lädt das Zertifikat herunter.
     *
     * Gesetzte Variablen:
     * passwordFeedback, passwordState, certPEM
     */
    async downloadAndDisplayCertificate() {
      const request = this.request;
      try {
        decrypt(this.request.encryptedPinHash, this.password);
      } catch (e) {
        this.passwordFeedback = this.$t("password_error");
        this.passwordState = false;
        this.error(this.$t("password_error"));
        return;
      }
      this.passwordFeedback = this.$t("password_success");
      this.passwordState = true;

      const client = new DFNSOAPClient(custom.pubURL, custom.ra_id);

      // PIN-Hash und privaten Schlüssel entschlüsseln
      request.decrypt(this.password);

      try {
        this.certPEM = await client.getCertificateByRequestSerial(
          request.serial,
          request.plainPinHash
        );
        logger.debug("Zertifikat heruntergeladen: " + this.certPEM);
      } catch (e) {
        logger.error("Fehler beim Holen des Zertifikats: " + e);
        this.error(this.$t("error_fetching_cert", { pki_name: pki_name }));
        return;
      }

      if (this.certPEM === "") {
        logger.info("Das Zertifikat wurde noch nicht ausgestellt.");
        this.error(this.$t("error_fetching_cert", { pki_name: pki_name }));
        return;
      }

      // Zertifikat anzeigen
      this.$router.push({ name: RETRIEVE_CERTIFICATE_SAVE });
    },

    /**
     * Holt die CA-Kette, erstellt ein P12 und speichert es.
     *
     * Gesetzte Variablen: showOverlay
     */
    async saveP12(p12Password) {
      this.showOverlay = true;
      const request = this.request;
      const chain = await this.getChain(true);
      const p12 = createP12(this.request.plainPrivateKey, chain, p12Password);
      this.showOverlay = false;
      this.isP12 = true;

      const certData = parseCert(this.certPEM);

      const filename =
        request.getCN() + "_" + request.serial + "_" + certData.certSerial;

      await downloadFile(
        p12,
        getCertFileName(filename, "p12", certData.notBefore, certData.notAfter),
        document,
        true
      );
      this.$router.push({ name: RETRIEVE_CERTIFICATE_DONE });
    },

    /**
     * Speichert die abgeholte PEM-Datei.
     *
     * Dies ist die Alternative zur P12-Datei, wenn kein privater Schlüssel
     * vorliegt.
     *
     * @param saveChain {Boolean} true gdw. mit Kette gespeichert werden soll
     */
    async savePem(saveChain) {
      const request = this.request;
      var content = "";
      var suffix = "-nochain"; // Suffix für Dateiname
      this.isP12 = false;

      const certData = parseCert(this.certPEM);

      if (this.hasKey) {
        // verschlüsselten private key als PEM und Zertifikat
        content = encryptRSAKeyToPem(
          this.request.plainPrivateKey,
          this.password
        );
      }
      // nun das Zertifikat
      content += "\nsubject= " + certData.subject + "\n";
      content += this.certPEM;

      if (saveChain) {
        // Falls Kette gespeichert werden soll: Kette holen und zusammenfügen
        // End-Entity-Zertifikat nicht an die Kette anfügen, da das bereits zum
        // content hinzugefügt wurde (plus Subject als Kommentar)
        const chain = await this.getChain(false);
        content += "\nFull CA certificate chain\n";
        content += chain.join("");
        logger.info("content: " + content);
        suffix = ""; // Kein Suffix, falls mit Kette gespeichert wird.
      }

      const filename =
        request.getCN() +
        "_" +
        request.serial +
        "_" +
        certData.certSerial +
        suffix;

      // Zertifikat (ggf. inklusive Kette) speichern
      await downloadFile(
        content,
        getCertFileName(filename, "pem", certData.notBefore, certData.notAfter),
        document,
        false
      );
      // Zur Abschlussseit
      this.$router.push({ name: RETRIEVE_CERTIFICATE_DONE });
    },

    /**
     * Holt die Chain.
     *
     * Erstes Zertifikat in der Kette ist this.certPEM, dann folgt der issuer.
     * Letztes Zertifikat ist die Root.
     *
     * @returns Array mit Zertifikatstrings
     */
    async getChain(addEECert) {
      const client = new DFNSOAPClient(custom.pubURL, custom.ra_id);

      const caInfoResponse = await client.getCAInfo();
      const chain = caInfoResponse.chain;
      if (addEECert) {
        chain.unshift(this.certPEM);
      }
      logger.info("Chain: " + JSON.stringify(chain));

      return chain;
    },

    /**
     * Stellt eine Fehlermeldung dar.
     */
    error(msg) {
      this.$emit("error-message", msg);
    },
  },
};
</script>

<style scoped></style>
