/* global require */ // suppress eslint warning: 'require' is not defined
const forge = require("node-forge");
import { Logger } from "@/logger/logger";
import { Subject } from "@/pki/subject";
import {
  // ALLOWED_RDN_TYPES,
  getSanTypeById,
  getRdnTypeByOid,
} from "@/pki/nameTypes";

const logger = new Logger("dfnpki");

// Pattern für PEM ohne Einränkungen für Anfang und Ende oder Zeilenlänge #2023
const PEM_PATTERN =
  /-----BEGIN [ A-Z]+-----\r?\n([A-Za-z0-9+/]+\r?\n)*[A-Za-z0-9+/]+={0,3}\r?\n-----END [ A-Z]+-----/;

// Crypto-Parameter für encrypt() und decrypt()
const ITERATIONS = 100100;
const ENCRYPTION_MODE = "AES-CBC";
const KEY_LENGTH = 16;
const IV_LENGTH = 32;
const SALT_LENGTH = 128;

// Crypto-Parameter für createP12()
const P12_SALT_LENGTH = 16;
const P12_ITERATIONS = 599999;
const P12_ALGORITHM = "3des";

/**
 * Prüft, ob eine Eingabe ein String ist. Falls nicht, wird ein TypeError
 * geworfen.
 *
 * Beispiel: checkString('key in myCryptoFunction', 42) gibt
 * TypeError('key in myCryptoFunction ist kein String sondern: 42')
 *
 * @param {string} name Bezeichner für den zu prüfenden Wert
 * @param {string} input der zu prüfende Wert
 */
export function checkString(name, input) {
  if (typeof input !== "string") {
    throw new TypeError(
      name + " ist kein String sondern: " + JSON.stringify(input)
    );
  }
}

/**
 * Prüft, ob ein String eine PEM-Nachricht ist. Falls nicht, wird ein TypeError
 * geworfen.
 *
 * Zeilenenden können entweder \r\n oder \n sein.
 *
 * Beispiel: checkPEM('key in myCryptoFunction', 'kein Key') gibt
 * TypeError('key in myCryptoFunction ist kein PEM sondern: "kein Key"')
 *
 * @param {string} name Bezeichner für den zu prüfenden Wert
 * @param {string} pem das zu prüfende PEM
 */
export function checkPEM(name, pem) {
  checkString(name, pem);

  const pattern = new RegExp("^" + PEM_PATTERN.source + "\\r?\\n$");

  if (!pem.match(pattern)) {
    throw new TypeError(name + " ist kein PEM sondern: " + JSON.stringify(pem));
  }
}

/**
 * Extrahiert aus einem Text die erste PEM-Nachricht.
 *
 * Gesucht wird anhand des Musters PEM_PATTERN.
 *
 * Falls kein PEM gefunden wird, wird ein TypeError geworfen.
 *
 * Zeilenenden können entweder \r\n oder \n sein.
 *
 * @param {string} name Bezeichner für den Text (nur wichtig für Fehler)
 * @param {string} text der Text, in dem die PEM-Nachricht gesucht wird
 * @returns {string} extrahiertes PEM mit abschließendem \n
 */
export function extractPEM(name, text) {
  checkString(name, text);

  const result = text.match(PEM_PATTERN);

  if (!result) {
    throw new TypeError(
      name + " enthält kein PEM sondern: " + JSON.stringify(text)
    );
  }

  // Gefundenes PEM ist vollständiger Match des regulären Ausdrucks
  const pem = result[0] + "\n";

  checkPEM("Extrahiertes PEM aus " + name, pem);

  return pem;
}

/**
 * Erzeugt den SHA1-Hash eines Strings.
 *
 * @param {String} input der zu hashende Text
 * @returns {String} Hexadezimaldarstellung des SHA1-Hashes
 */
export function sha1(input) {
  checkString("input in sha1", input);
  const md = forge.md.sha1.create();
  md.update(input);
  return md.digest().toHex();
}

/**
 * Kombiniert einen privaten Schlüssel und ein Zertifikat zu einem PKCS#12.
 * Schlüssel und Zertifikat müssen als PEM-String vorliegen. Die Ausgabe ist
 * ein Base64-String.
 * Das Zertifikat muss zusammen mit der Zertifikatskette als Array aus PEMs
 * übergeben werden.
 *
 * Der Zusatz {algorithm: '3des'} wird empfohlen, wenn man Probleme hat, das
 * resultierende P12 in den Browser zu importieren. Da das beim Testen der
 * Fall war, steht es hier mit drin.
 *
 * @param {String} privateKey privater Schlüssel im PEM-Format
 * @param {Array[String]} chain Zertifikat und Kette im PEM-Format
 * @param {String} password Passwort, mit dem die Ausgabe verschlüsselt wird
 * @returns {String} P12 als Base64-String
 */
export function createP12(privateKey, chain, password) {
  checkPEM("privateKey in createP12", privateKey);
  checkString("password in createP12", password);

  const encodedChain = [];
  for (let cert of chain) {
    checkPEM("Ein Zertifikat in chain in createP12", cert);
    encodedChain.push(forge.pki.certificateFromPem(cert));
  }

  const encodedPrivateKey = forge.pki.privateKeyFromPem(privateKey);
  if (!encodedPrivateKey || !encodedChain || !password) {
    logger.error("Ein Aufrufparameter von createP12() ist ungültig.");
    throw new Error("Ein Aufrufparameter von createP12() ist ungültig.");
  }

  // Friendly Name setzt sich zusammen aus CN des Certs und O des Issuers
  const certInfo = parseCert(chain[0]);
  const subjectCN = certInfo.subject.CN[0];
  var friendlyName = subjectCN + " issued by ";
  if (certInfo.issuer.O !== null && certInfo.issuer.O !== undefined) {
    friendlyName += certInfo.issuer.O[0];
  } else {
    friendlyName += certInfo.issuer;
  }

  logger.debug("Führe Key und Chain zusammen. friendlyName: " + friendlyName);

  var p12Asn1 = forge.pkcs12.toPkcs12Asn1(
    encodedPrivateKey,
    encodedChain,
    password,
    {
      friendlyName: friendlyName,
      saltSize: P12_SALT_LENGTH,
      count: P12_ITERATIONS,
      algorithm: P12_ALGORITHM,
    }
  ); // 3des: siehe oben

  // base64-encode p12
  var p12Der = forge.asn1.toDer(p12Asn1).getBytes();
  return forge.util.encode64(p12Der);
}

/**
 * Parst Altnames im Forge-Format zu unserem Format.
 *
 * Eigentlich ließe sich das auch per einfachem map() machen, aber Forge
 * speichert bei IP-Adressen und Microsoft-UPN den Wert leider nicht im value,
 * sondern an einer komplizierteren Stelle.
 *
 * @param {Array[Object]} forgeAltnames Altnames im Forge-Format
 * @returns {Array[Object]} Array mit Type-Value-Paaren
 */
function parseForgeAltnames(forgeAltnames) {
  const altnames = [];
  for (const altname of forgeAltnames) {
    var value;
    /*
    Sonderfall für IP-Adressen, S
    Struktur unterscheided sich leicht vom Standard:
    {
      "type": 7,
      "value": "À¨\u0001\u0001",
      "ip": "192.168.1.1"
    }
    */
    if (altname.type === 7) {
      value = altname.ip;
      /*
      Sonderfall für otherName bzw. Microsoft_UPN
      Komplexe Struktur die stark vom Standard abweicht:
      {
        "type": 0,
        "value": [
          {
            "tagClass": 0,
            "type": 6,
            "constructed": false,
            "composed": false,
            "value": "+\u0006\u0001\u0004\u0001�7\u0014\u0002\u0003"
          },
          {
            "tagClass": 128,
            "type": 0,
            "constructed": true,
            "composed": true,
            "value": [
              {
                "tagClass": 0,
                "type": 12,
                "constructed": false,
                "composed": false,
                "value": "TestUPN"
              }
            ]
          }
        ]
      }
      */
    } else if (altname.type === 0) {
      value = altname.value[1].value[0].value;
      /*
      Standardverfahren bei allen anderen SAN-Typen, Standardstruktur:
      {
        "type": 2,
        "value": "beispiel.com"
      }
      */
    } else {
      value = altname.value;
    }

    // Neuen SAN mit type und value erstellen und ins Array pushen.
    altnames.push({
      type: getSanTypeById(altname.type),
      value: value,
    });
  }
  return altnames;
}

/**
 * Parst mit Forge einen PKCS10-Request und extrahiert alle von uns benötigten
 * Informationen.
 *
 * Beispielrückgabe:
 * {rdns: [{type: ALLOWED_RDN_TYPES.C, value: "DE"}],
 * altnames: []}
 *
 * @param {type} requestPEM der Antrag als PEM
 * @returns {array} Object mit Type-Value-Arrays rdns und altnames
 */
export function parsePKCS10(requestPEM) {
  // logger.debug("parsePKCS10()");
  checkPEM("request in parsePKCS10", requestPEM);

  const csr = forge.pki.certificationRequestFromPem(requestPEM);

  if (!csr) {
    logger.error(
      "PKCS10-Antrag konnte nicht geparst werden. Eingabe: " + requestPEM
    );
    throw new Error("Konnte PKCS10-Antrag nicht parsen.");
  }

  // Subject vom Forge-Format ins Wenja-Format parsen
  const rdns = csr.subject.attributes.map((attribute) => ({
    type: getRdnTypeByOid(attribute.type),
    value: attribute.value,
  }));

  // SANS parsen
  var altnames = [];
  var extension = null;
  // Hier werden, falls verfügbar, die Extensions aus dem request extrahiert.
  const extensionRequest = csr.getAttribute({ name: "extensionRequest" });
  if (extensionRequest) {
    extension = extensionRequest.extensions.find(
      (ext) => ext.name === "subjectAltName"
    );
  }

  if (extension) {
    altnames = parseForgeAltnames(extension.altNames);
  }

  return { rdns: rdns, altnames: altnames };
}

/**
 * Parst ein Zertifikat im PEM-Format.
 *
 * @param {String} certPEM Zertifikat im PEM-Format
 * @returns {ForgeCert} ein Zertifikat im Forge-Format
 */
export function _parseCert(certPEM) {
  checkPEM("certPEM in _parseCert", certPEM);
  return forge.pki.certificateFromPem(certPEM);
}

/*
 * Konvertiert einen Hexstring in einen Dezimalstring.
 *
 * Brauchen wir um Seriennummern umzuwandeln. Wahrscheinlich bringt Forge auch
 * Werkzeuge mit, die das machen.
 *
 * @param {string} Hexstring
 * @returns {string} Dezimalstring
 */
export function hexToDec(s) {
  checkString("Eingabe in hexToDec", s);
  const hexPattern = /^[a-fA-F0-9]+$/;

  if (!s.match(hexPattern)) {
    throw new TypeError(
      "Eingabe in hexToDec ist kein Hexstring sondern: " + JSON.stringify(s)
    );
  }

  function add(xi, yi) {
    var c = 0;
    var r = [];
    var x = xi.split("").map(Number);
    var y = yi.split("").map(Number);
    while (x.length || y.length) {
      var s = (x.pop() || 0) + (y.pop() || 0) + c;
      r.unshift(s < 10 ? s : s - 10);
      c = s < 10 ? 0 : 1;
    }
    if (c) {
      r.unshift(c);
    }
    return r.join("");
  }

  var dec = "0";
  s.split("").forEach((chr) => {
    var n = parseInt(chr, 16);
    for (var t = 8; t; t >>= 1) {
      dec = add(dec, dec);
      if (n & t) {
        dec = add(dec, "1");
      }
    }
  });
  return dec;
}

/**
 * Parst einen DN wie Forge ihn verwendet und liefert ein DN-Objekt in unserem
 * Format.
 *
 * @param {Object} forgeDN DN aus einem Forge-Zertifikat Bsp.: forgeCert.subject
 * @returns {Object} DN im Wenja-Format Bsp.: {O: ["org"], CN: ["cn1", "cn2"]}
 */
export function getDN(forgeDN) {
  const dn = {};
  for (let attribute of forgeDN.attributes) {
    const type = getRdnTypeByOid(attribute.type).shortName;

    if (!Object.prototype.hasOwnProperty.call(dn, type)) {
      dn[type] = [];
    }

    dn[type].push(attribute.value);
  }
  return new Subject(dn);
}

/**
 * Parst ein Zertifikat im PEM-Format.
 * Extrahiert alle für uns relevanten Informationen.
 *
 * @param {String} certPEM Zertifikat im PEM-Format
 * @returns {CertInfo} ein Objekt mit Zertifikatinformationen
 */
export function parseCert(certPEM) {
  const forgeCert = _parseCert(certPEM);
  const extension = forgeCert.getExtension("subjectAltName");
  const forgeAltnames = extension !== null ? extension.altNames : [];
  const altnames = parseForgeAltnames(forgeAltnames);

  const certInfo = {
    subject: getDN(forgeCert.subject),
    issuer: getDN(forgeCert.issuer),
    notBefore: forgeCert.validity.notBefore,
    notAfter: forgeCert.validity.notAfter,
    certSerial: hexToDec(forgeCert.serialNumber),
    certPEM: certPEM,
    altnames: altnames,
  };

  return certInfo;
}

/**
 * Erzeugt eine Stringdarstellung aus einer Forge-DN-Datenstruktur.
 * DNs sind in Forge ein Array aus Attributen (RDNs). Für die Umwandlung
 * wird das Array reduziert, indem Shortname (AttributeType) und value jedes
 * Eintrags mit Gleichheitszeichen verbunden und durch Komma abgeschlossen
 * werden.
 *
 * Als Eingabe wird ein Name erwartet, wie er beispielsweise in cert.issuer
 * steht:
 *
 * "issuer": {
 * "attributes": [
 *   {
 *     "type": "2.5.4.6",
 *     "value": "DE",
 *     "valueTagClass": 19,
 *     "name": "countryName",
 *     "shortName": "C"
 *  }, ... ], "hash": ... }
 *
 * @param {Object} name Name aus Forge-Datenstruktur (bspw. cert.issuer)
 * @returns {String} Stringdarstellung des Namens (bspw. C=DE, ...)
 */
export function nameToDN(name) {
  if (!name.attributes) {
    throw new Error("Übergebener Name hat keine attributes");
  }

  return name.attributes
    .reduce(
      (start, next) => start + next.shortName + "=" + next.value + ", ",
      ""
    )
    .slice(0, -2);
}

/**
 * Verschlüsselt einen Text. Kann mit __decrypt() unter Angabe des selben
 * Passworts wieder entschlüsselt werden. Es gilt die Invariante
 * __decrypt(__encrypt(plaintext, password), password) == plaintext.
 * Die Rückgabe ist eine Datenstruktur {encrypted, salt, iv} mit dem
 * verschlüsselten Text und dem verwendeten Salz und Initialisierungsvektor.
 * Alle Werte sind in Base64 kodiert.
 *
 * @param {string} plaintext der zu verschlüsselnde Text
 * @param {string} password Passwort zum Verschlüsseln
 * @returns {Data} verschlüsselte Daten {encrypted, salt, iv}
 */
function __encrypt(plaintext, password) {
  checkString("plaintext in encrypt", plaintext);
  checkString("password in encrypt", password);

  const iv = forge.random.getBytes(IV_LENGTH);
  const salt = forge.random.getBytesSync(SALT_LENGTH);
  const key = forge.pkcs5.pbkdf2(password, salt, ITERATIONS, KEY_LENGTH);

  var cipher = forge.cipher.createCipher(ENCRYPTION_MODE, key);
  cipher.start({ iv: iv });
  cipher.update(forge.util.createBuffer(plaintext));
  cipher.finish();
  var encrypted = cipher.output.getBytes();

  return {
    encrypted: forge.util.encode64(encrypted),
    salt: forge.util.encode64(salt),
    iv: forge.util.encode64(iv),
  };
}

/**
 * Entschlüsselt eine mit __encrypt() verschlüsselte Nachricht. Die Eingabe
 * muss ein Output von __encrypt() und das verwendete Passwort sein.
 *
 * TODO: Nach der Migrationsphase zu Wenja 2.0 "export" entfernen.
 *
 * @param {string} encrypted verschlüsselte Nachricht als Base64-String
 * @param {string} salt beim Verschlüsseln verwendetes Salz als Base64-String
 * @param {string} iv beim Verschlüsseln verwendeter IV als Base64-String
 * @param {string} password Passwort zum Entschlüsseln
 * @returns {string} entschlüsselter Klartext
 */
export function __decrypt({ encrypted, salt, iv }, password) {
  checkString("encrypted in decrypt", encrypted);
  checkString("salt in decrypt", salt);
  checkString("iv in decrypt", iv);
  checkString("password in decrypt", password);

  try {
    const encryptedBin = forge.util.decode64(encrypted);
    const saltBin = forge.util.decode64(salt);
    const ivBin = forge.util.decode64(iv);

    const key = forge.pkcs5.pbkdf2(password, saltBin, ITERATIONS, KEY_LENGTH);
    var decipher = forge.cipher.createDecipher(ENCRYPTION_MODE, key);
    decipher.start({ iv: ivBin });
    decipher.update(forge.util.createBuffer(encryptedBin));
    decipher.finish();

    return decipher.output.toString();
  } catch (e) {
    logger.error("Konnte Daten nicht entschlüsseln: " + e);
    throw new Error("Konnte Daten nicht entschlüsseln.", e);
  }
}

/**
 * Verschlüsselt ein beliebiges Objekt. Kann mit decrypt() unter Angabe des
 * selben Passworts wieder entschlüsselt werden. Es gilt die Invariante
 * decrypt(encrypt(plainData, password), password) == plainData.
 * Die Rückgabe ist eine Datenstruktur {encrypted, salt, iv} mit dem
 * verschlüsselten Objekt und dem verwendeten Salz und Initialisierungsvektor.
 * Alle Werte sind in Base64 kodiert.
 *
 * @param {any} plainData die zu verschlüsselnden Daten
 * @param {string} password Passwort zum Verschlüsseln
 * @returns {Data} verschlüsselte Daten {encrypted, salt, iv}
 */
export function encrypt(plainData, password) {
  const data = { plainData: plainData, knownPlainText: "knownPlainText" };
  return __encrypt(JSON.stringify(data), password);
}

/**
 * Entschlüsselt eine mit encrypt() verschlüsselte Nachricht. Die Eingabe
 * muss ein Output von encrypt() und das verwendete Passwort sein.
 *
 * @param {string} encrypted verschlüsselte Nachricht als Base64-String
 * @param {string} salt beim Verschlüsseln verwendetes Salz als Base64-String
 * @param {string} iv beim Verschlüsseln verwendeter IV als Base64-String
 * @param {string} password Passwort zum Entschlüsseln
 * @returns {any} entschlüsselte Daten
 */
export function decrypt({ encrypted, salt, iv }, password) {
  const data = JSON.parse(__decrypt({ encrypted, salt, iv }, password));

  if (!data.knownPlainText || data.knownPlainText != "knownPlainText") {
    throw new Error("Konnte Daten nicht entschlüsseln.");
  }

  return data.plainData;
}

/** Verschlüsselt einen RSA-Schlüssel, der als Plain-PEM vorliegt
 *   als Encrypted-PEM
 *
 * @param {*} plainKey unverschlüsselter RSA-Private-Key als PEM
 * @param {*} password Paswort zum Verschlüsseln
 * @returns
 */
export function encryptRSAKeyToPem(plainKey, password) {
  var privKey = forge.pki.privateKeyFromPem(plainKey);
  return forge.pki.encryptRsaPrivateKey(privKey, password);
}
