<template>
  <header class="app-header">
    <div id="custom-logo">
      <a v-if="href" target="_blank" rel="noopener noreferrer" :href="href">
        <img v-if="img" id="custom-logo-image" :src="img" :alt="alt" />
        <!--
        <div v-else>{{ custom.windowTitle }}</div>
        -->
      </a>
      <router-link v-else :to="{ name: HOME }">
        <img v-if="img" id="custom-logo-image" :src="img" :alt="alt" />
        <!--
        <div v-else>{{ custom.windowTitle }}</div>
        -->
      </router-link>
    </div>
    <!-- header logo copied from https://www.dfn.de/ -->
    <div id="header-logo-lines"></div>
    <a target="_blank" rel="noopener noreferrer" :href="header.href">
      <img id="header-logo-image" :src="header.img" :alt="header.alt" />
    </a>
  </header>
</template>

<script>
import { HOME } from "@/router/names";
import { custom } from "@/customization/customization";
import { brand } from "@/brand/brand";

export default {
  data() {
    const imgs = custom.customLogo.imgs;
    return {
      custom: custom,
      href:
        custom.customLogo.hrefs.length > 0 ? custom.customLogo.hrefs[0] : "",
      alt: imgs.length > 0 && imgs[0].alt ? imgs[0].alt : custom.windowTitle,
      img: imgs.length > 0 && imgs[0].src ? imgs[0].src : false,
      HOME: HOME,
      header: brand.header,
    };
  },
};
</script>
<style scoped>
.app-header {
  position: relative;
}

#custom-logo-image {
  position: relative;
  top: 30px;
  left: 5px;
  max-width: 40%;
}

#header-logo-image {
  position: absolute;
  right: 0px;
  top: 5px;
  height: 110px;
  max-width: 40%;
}

#header-logo-lines {
  position: relative;
  background: url(../assets/www.dfn.de/Linien-links.jpg) no-repeat;
  background-size: auto 60px;
  height: 150px;
  left: 0px;
  background-position-y: 88px;
  border-bottom: 0px solid #ffffff;
}

.help-tooltip {
  text-align: center;
  background-color: #bcdbea;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 12px;
  line-height: 26px;
  display: inline-block;
}

.checkbox {
  vertical-align: middle;
  position: relative;
  top: -1px;
}

.error-msg {
  color: red;
}

.bm-menu {
  background-color: #d9f1ff;
}
</style>
