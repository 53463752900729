/**
 * Die unterschiedlichen Typen, die ein Zertifikat haben kann.
 *
 * Aktuell werden die nur zur Unterscheidung verwendet, wie ein Enum. Später
 * könnten wir daran auch weitere Informationen knüpfen.
 */
export const CERT_TYPES = {
  user: { name: "user" },
  pseudonym: { name: "pseudonym" },
  group: { name: "group" },
  server: { name: "server" },
  pkcs10: { name: "pkcs10" },
};
