import { Logger } from "@/logger/logger";

// suppress eslint warning: 'process' is not defined
/* global process */

const logger = new Logger("brand.js");

/**
 * In den BRAND_PREFERENCES sind Einstellungen zu den Brandings gespeichert.
 *
 * Dieser Mechanismus funktioniert analog zu den POLICY_PREFERENCES in
 * src/customization/policy.js, steht aber schon zur Compilezeit zur Verfügung.
 * Anhand der Variable process.env.VUE_APP_BASE_PATH wird das korrekte Branding
 * ermittelt und alle entsprechenden Einstellungen werden dann über die Variable
 * "brand" exportiert.
 *
 * Beispiel: Wenja wird für edupki gebaut, dann gilt
 * brand = BRAND_PREFERENCES.edupki und brand.footer.imprintText.en = "About",
 * weil es in der Edupki nicht "Imprint" sondern "About" heißt.
 *
 * Erklärung der einzelnen Parameter:
 * pkiName: Anzeigename wie "DFN-PKI"
 * basePath: URL-Abschnitt direkt vor dem CA-Namen
 * baseURL: Teil der pubURL
 * header: Informationen zum Logo rechts oben. Pfade zu Bildern müssen per
 *   require angegeben werden, damit Webpack die Pfade anpassen kann.
 * footer: Links zur Datenschutzerklärung und zum Impressum (inkl. Linktext)
 * neverPublish: Falls "true" werden die Werte publishUser und publishServer aus
 *   der Customization überschrieben zu "no".
 * showProfilesHint: Falls "true" wird bei der Rollenauswahl in NewRequest ein
 *   Link zu den Rollenbeschreibungen angezeigt.
 */
const BRAND_PREFERENCES = {
  dfn: {
    pkiName: "DFN-PKI",
    basePath: "/dfn-pki/",
    baseURL: "https://pki.pca.dfn.de",
    header: {
      href: "https://www.dfn.de/",
      img: require("../assets/www.dfn.de/head_rechts_12.2017.svg"),
      alt: "Deutsches Forschungsnetz (DFN)",
    },
    footer: {
      privacyUrl: {
        en: "https://info.pca.dfn.de/doc/datenschutz_en.html",
        de: "https://info.pca.dfn.de/doc/datenschutz.html",
      },
      imprintUrl: {
        en: "https://www.dfn.de/Impressum/",
        de: "https://www.dfn.de/Impressum/",
      },
      imprintText: { en: "Imprint", de: "Impressum" },
      contactUrl: "https://doku.tid.dfn.de/de:dfnpki:contact",
    },
    neverPublish: false,
    showProfilesHint: true,
    showTSMAInfos: true,
  },
  dfncert: {
    pkiName: "DFN-CERT-PKI",
    basePath: "/dfn-cert-pki/",
    baseURL: "https://pki.dfn-cert.de",
    header: {
      href: "https://www.dfn-cert.de/",
      img: require("../assets/www.dfn-cert.de/dfn-cert-logo.svg"),
      alt: "DFN Services GmbH (DFN-CERT)",
    },
    footer: {
      privacyUrl: {
        en: "https://pki.dfn-cert.de/datenschutz.html",
        de: "https://pki.dfn-cert.de/datenschutz.html",
      },
      imprintUrl: {
        en: "​https://www.dfn-cert.de/impressum/",
        de: "https://www.dfn-cert.de/impressum/",
      },
      imprintText: { en: "Imprint", de: "Impressum" },
      contactUrl: "https://www.dfn-cert.de/kontakt/​",
    },
    neverPublish: true,
    showProfilesHint: false,
    showTSMAInfos: false,
  },
  edupki: {
    pkiName: "eduPKI",
    basePath: "/edupki/",
    baseURL: "https://pki.edupki.org",
    header: {
      href: "https://www.edupki.org/",
      img: require("../assets/www.edupki.org/GEANT.png"),
      alt: "GEANT (GEANT)",
    },
    footer: {
      privacyUrl: {
        en: "https://www.edupki.org/privacy/",
        de: "https://www.edupki.org/privacy/",
      },
      imprintUrl: {
        en: "https://www.geant.org/About",
        de: "https://www.geant.org/About",
      },
      imprintText: { en: "About", de: "Über" },
      contactUrl: "https://www.edupki.org/edupki-contact​",
    },
    neverPublish: false,
    showProfilesHint: false,
    showTSMAInfos: false,
  },
};

/**
 * Das ausgewählte Branding. Enthält die Einstellungen aus BRAND_PREFERENCES.
 */
export var brand;

for (const [key, value] of Object.entries(BRAND_PREFERENCES)) {
  if (value.basePath === process.env.VUE_APP_BASE_PATH) {
    brand = value;
  }
}

if (!brand) {
  throw new Error(
    "Kein Branding gefunden für process.env.VUE_APP_BASE_PATH =  " +
      process.env.VUE_APP_BASE_PATH
  );
}

logger.info("Branding: " + brand.pkiName + ", basePath: " + brand.basePath);
