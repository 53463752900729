<!--
Zeigt alle Lognachrichten an. Diese Komponente soll nicht für normale Benutzer
sichtbar sein sondern hauptsächlich für Entwickler. Man könnte aber überlegen,
sie trotzdem über eine URL zur Verfügung zu stellen, die wir nur Nutzern mit
akuten Problemen mitteilen. Die dort zu findenden Informationen können uns dann
beim Debugging helfen.

Ideen für Verbesserungen: Einfärben nach unterschiedlichen Komponenten.
Hervorhebung von Fehlern.
-->
<template>
  <div>
    <h1>Lognachrichten</h1>
    <table>
      <tr
        v-for="message in shownMessages"
        :key="message.date"
        :class="levels[message.loglevel].class"
      >
        <td>{{ format.format(message.date) }}</td>
        <td>{{ levels[message.loglevel].name }}</td>
        <td>{{ message.name }}</td>
        <td v-bind:title="format.format(message.date) + ': ' + message.text">
          <pre>{{ message.text }}</pre>
        </td>
      </tr>
    </table>

    Zeige Loglevel
    <select v-model="loglevelShown">
      <option v-for="level in levels" :key="level.level" :value="level.level">
        {{ level.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { messages, loglevels } from "@/logger/logger";

const loglevelShown = loglevels.DEBUG;

const levels = {
  [loglevels.ERROR]: { level: loglevels.ERROR, class: "error", name: "Fehler" },
  [loglevels.WARNING]: {
    level: loglevels.WARNING,
    class: "warning",
    name: "Warnung",
  },
  [loglevels.INFO]: { level: loglevels.INFO, class: "info", name: "Info" },
  [loglevels.DEBUG]: { level: loglevels.DEBUG, class: "debug", name: "Debug" },
  [loglevels.TRACE]: { level: loglevels.TRACE, class: "trace", name: "Trace" },
};

// Optionen für die Formatierung der Timestamps
// Siehe https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
const formatOptions = {
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
};
const format = new Intl.DateTimeFormat("de-DE", formatOptions);

export default {
  data: function () {
    return {
      messages: messages,
      format: format,
      levels: levels,
      loglevelShown: loglevelShown,
    };
  },
  computed: {
    shownMessages() {
      return this.messages.filter((m) => m.loglevel <= this.loglevelShown);
    },
  },
};
</script>

<style scoped>
td {
  vertical-align: top;
  padding: 0.2em;
  border-bottom: 1px solid black;
}
pre {
  margin: 0;
}

/* Farben für die verschiedenen Loglevel */
.error pre {
  color: red;
}
.warning pre {
  color: yellow;
}
.info pre {
  color: blue;
}
.debug pre {
  color: black;
}
.trace pre {
  color: gray;
}
</style>
