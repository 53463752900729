<template>
  <div>
    <div v-for="domainObject in domainObjects" :key="domainObject.type">
      <b-dropdown
        variant="primary"
        :id="domainObject.type"
        v-if="domainObject.visible"
        :text="
          $t(
            domainObject.type === 'email' &&
              custom.nonWhitelistedEmailAddressesAllowed
              ? 'emailNonWhitelisted'
              : domainObject.type + 'Text'
          )
        "
      >
        <b-dropdown-item-button
          v-for="domain in domainObject.domains"
          :key="domain"
        >
          {{ domain.name }}
        </b-dropdown-item-button>
      </b-dropdown>

      <template v-if="domainObject.visible && loadingValidDomains">
        <div class="spinner-border spinner-border-sm"></div>
      </template>
    </div>
  </div>
</template>

<i18n>
  en:
    emailNonWhitelisted: "Email addresses with domain names from this list can be used without further confirmation. Email addresses with all other domain names must be confirmed separately."
    emailText: "You can use the following domain names in email addresses:"
    serverText: "You can use the following domain names in the CN attribute and SubjectAlternativeNames of type 'DNS':"
  de:
    emailNonWhitelisted: "E-Mail-Adressen mit folgenden Domainnamen können ohne weitere Bestätigung verwendet werden. E-Mail-Adressen mit anderen Domainnamen müssen separat bestätigt werden."
    emailText: "Die folgenden Domainnamen können Sie in E-Mail-Adressen nutzen:"
    serverText: "Die folgenden Domainnamen können Sie im CN-Attribut und in SubjectAlternativeNames (SaN) des Typs 'DNS' nutzen:"
</i18n>

<script>
import { custom } from "@/customization/customization";
import { Logger } from "@/logger/logger";
import { DFNSOAPClient } from "@/soap/dfnsoapclient";
const logger = new Logger("DomainDropdown");
// const developerMode = process.env.NODE_ENV === "development";
export default {
  name: "DomainDropdown",
  mounted() {
    this.getValidDomains();
  },
  data() {
    const domainObjects = [
      { type: "email", visible: this.email, domains: [] },
      { type: "server", visible: this.server, domains: [] },
    ];
    return {
      custom: custom,
      loadingValidDomains: false,
      domainObjects: domainObjects,
    };
  },
  props: {
    email: { type: Boolean, default: false },
    server: { type: Boolean, default: false },
  },
  methods: {
    /**
     * Lädt die möglichen Email oder Server Domains, abhängig davon welcher
     * Zertifikattyp ausgewählt ist
     */
    async getValidDomains() {
      const client = new DFNSOAPClient(custom.pubURL, custom.ra_id);
      for (let domainObject of this.domainObjects) {
        if (!domainObject.visible) continue;
        logger.debug(
          "getValidDomains(): hole Domains vom Typ " + domainObject.type
        );
        this.loadingValidDomains = true;
        try {
          domainObject.domains = await client.getValidDomains(
            domainObject.type
          );
        } catch (exception) {
          logger.error(
            "Fehler beim Ermitteln der gültigen Domains: " + exception
          );
        }
      }
      this.loadingValidDomains = false;
    },
  },
};
</script>
<style scoped>
#email,
#server {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
