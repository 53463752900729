import { SOAPClient, SOAPClientParameters } from "@/soap/soapclient";
import { Logger } from "@/logger/logger";

const logger = new Logger("dfnsoapclient");

/**
 * Prüft ob die Eingabe ein SHA1-Hashwert (hexadezimal, Kleinbuchstaben) ist.
 *
 * Bei Fehlern fliegt ein TypeError.
 *
 * @param {any} pinHash zu testende Eingabe
 */
function checkPin(pinHash) {
  if (typeof pinHash !== "string") {
    throw new TypeError(
      "Pinhash ist kein String sondern: " + JSON.stringify(pinHash)
    );
  }
  const pattern = /^[a-f0-9]{40}$/;

  if (!pinHash.match(pattern)) {
    throw new TypeError("Pinhash ist kein SHA1 sondern: " + pinHash);
  }
}

/**
 * DFN-PKI- und DFN-CERT-PKI-SOAP-Client. Hängt ab von soapclient.js.
 *
 * In der Doku der einzelnen SOAP-Methoden ist die Nummer des zugehörigen
 * Abschnitts in der Doku der SOAP-Schnittstelle aufgeführt.
 */

export class DFNSOAPClient {
  /**
   * Erzeugt einen neuen Client.
   *
   * @param {String} url URL zum Interface mit dem kommuniziert werden soll
   * @param {int} raid die RA-ID des Benutzers
   * @returns {DFNSOAPClient}
   */
  constructor(url, raid) {
    this.url = url;
    this.raid = raid;

    // soap.createClientAsync(url).then((client) => {
    // return client.MyFunctionAsync(args)
    // }).then((result) => {
    // logger.debug(result)
    // })
  }
  /**
   * Sendet eine SOAP-Anfrage an die Schnittstelle. CA und RA-ID stehen bereits
   * fest und müssen nicht mehr angegeben werden.
   *
   * @param {String} method Methodenname, beispielsweise "newRequest"
   * @param {SOAPClientParameters} parameters Parameter (ohne RA-ID)
   * @returns {Promise} Promise zur Weiterverarbeitung. Beim Erfüllen der
   * Promise wird das komplette XML der Antwort zurückgegeben
   */
  _sendRequest(method, parameters) {
    var async = true;
    var url = this.url; // this.url funktioniert nicht innerhalb der Promise

    parameters.add("RaID", this.raid);

    return new Promise(function (resolve, reject) {
      var callback = function (responseObject, responseXML) {
        if (responseObject + "" === "Error: 500") {
          // SOAP-Schnittstelle hat Fehler geliefert
          const errorMessage =
            responseXML.getElementsByTagName("faultstring")[0].childNodes[0]
              .nodeValue;
          logger.warn("SOAP-Fehler: " + errorMessage);
          reject(errorMessage, responseXML);
        } else {
          // Anfrage war erfolgreich
          resolve(responseXML);
        }
      };
      logger.debug("Sende SOAP-Request (" + method + ") an url " + url);
      try {
        SOAPClient.invoke(url, method, parameters, async, callback);
      } catch (e) {
        // TODO: Error werfen?
        logger.error("SOAP Anfrage fehlgeschlagen");
      }
    });
  }

  /**
   * Sendet eine SOAP-Anfrage, deren erwartete Antwort ein einfacher String
   * ist. Viele der weiter unten implementierten Funktionen benötigen nur
   * einen Wert aus der Antwort, die _sendRequest() liefert. In diesem Fall
   * ist _sendRequestWithSimpleResponse() die bessere Wahl.
   *
   * @param {String} method Methodenname, beispielsweise "newRequest"
   * @param {SOAPClientParameters} parameters Parameter (ohne RA-ID)
   * @returns {Promise} Promise zur Weiterverarbeitung. Beim Erfüllen der
   * Promise wird der Inhalt des "Return"-Tags aus der Antwort zurückgegeben.
   */
  _sendRequestWithSimpleResponse(method, parameters) {
    return this._sendRequest(method, parameters).then(
      function (responseXML) {
        // Erfolg
        return responseXML.getElementsByTagName(method + "Return")[0].innerHTML;
      },
      // Wirft einen Error bei SOAP-Fehlern
      function (errorMessage) {
        throw new Error(errorMessage);
      }
    );
  }
  /**
   * 4.1.1 Schickt einen neuen Request zur SOAP-Schnittstelle.
   *
   * @param {String} pkcs10 der Request als PKCS10 im PEM-Format
   * @param {String[]} altnames Array mit Subject-Altnames
   * @param {String} role Rolle des Zertifikats ("User", "Web Server" etc.)
   * @param {String} pinHash SHA1-Hash hexadezimal der PIN des Benutzers
   * @param {String} addname Name des Antragsstellers
   * @param {String} addemail Email des Antragsstellers
   * @param {String} addorgunit Organisation des Antragsstellers
   * @param {boolean} publish true, wenn der Antrag veröffentlicht werden soll
   * @returns {Promise} Promise zur Weiterverarbeitung. Beim Erfüllen der
   * Promise wird die Seriennummer des neuen Antrags als String zurückgegeben
   */
  _newRequest(
    pkcs10,
    altnames,
    role,
    pinHash,
    addname,
    addemail,
    addorgunit,
    publish
  ) {
    var parameters = new SOAPClientParameters();
    var method = "newRequest";

    parameters.add("PKCS10", pkcs10);
    parameters.add("AltNames", altnames); // TODO: leere Arrays behandeln
    parameters.add("Role", role);
    parameters.add("Pin", pinHash);
    parameters.add("AddName", addname);
    parameters.add("AddEMail", addemail);
    parameters.add("AddOrgUnit", addorgunit);
    parameters.add("Publish", publish ? "true" : "false");

    return this._sendRequestWithSimpleResponse(method, parameters);
  }
  newRequest(request) {
    return this._newRequest(
      request.pkcs10PEM,
      // SANs werden zum type:value-Stringarray reduziert.
      request.altnames.map((san) => san.type.shortName + ":" + san.value),
      request.role,
      request.plainPinHash,
      request.addName,
      request.addEmail,
      request.addOrg,
      request.publish
    );
  }

  /**
   * 4.1.2 Sperrt ein Zertifikat.
   *
   * @param {String} certSerial Seriennummer des Zertifikats
   * @param {String} reason Grund für die Sperrung
   * @param {String} pinHash SHA1-Hash hexadezimal der PIN des Benutzers
   * @returns {Promise} Promise zur Weiterverarbeitung. Beim Erfüllen der
   * Promise wird die Seriennummer des neuen Sperrantrags zurückgegeben
   *
   * TODO: testen! Bislang noch nicht verwendet.
   */
  newRevocationRequest(certSerial, reason, pinHash) {
    var parameters = new SOAPClientParameters();
    var method = "newRevocationRequest";

    checkPin(pinHash);

    parameters.add("Serial", certSerial);
    parameters.add("Reason", reason);
    parameters.add("Pin", pinHash);

    return this._sendRequestWithSimpleResponse(method, parameters);
  }

  /**
   * 4.1.3 Holt das PDF-Antragsformular eines Antrags von der Schnittstelle.
   *
   * @param {int} serial die Seriennummer des Antrags
   * @param {String} pinHash SHA1-Hash hexadezimal der PIN des Benutzers
   * @returns {Promise} Promise zur Weiterverarbeitung. Beim Erfüllen der
   * Promise wird der Antrag als Base64-String zurückgegeben
   */
  getRequestPrintout(serial, pinHash) {
    const parameters = new SOAPClientParameters();
    const method = "getRequestPrintout";

    checkPin(pinHash);

    parameters.add("Serial", serial);
    parameters.add("Format", "application/pdf");
    parameters.add("Pin", pinHash);

    return this._sendRequestWithSimpleResponse(method, parameters);
  }

  /**
   * 4.1.4 Holt ein Zertifikat per Antragsnummer.
   *
   * @param {String} serial Seriennummer des Antrags
   * @param {String} pinHash SHA1-Hash hexadezimal der PIN des Benutzers
   * @returns {Promise} Promise zur Weiterverarbeitung. Beim Erfüllen der
   * Promise wird das Zertifikat als PEM-String zurückgeliefert
   */
  getCertificateByRequestSerial(serial, pinHash) {
    var parameters = new SOAPClientParameters();
    var method = "getCertificateByRequestSerial";

    checkPin(pinHash);

    parameters.add("Serial", serial);
    parameters.add("Pin", pinHash);

    return this._sendRequestWithSimpleResponse(method, parameters);
  }

  /**
   * 4.1.5 Holt die gültigen Domains.
   *
   * @param {String} type Typ der Domain (email/server/all)
   * @returns {Promise} Promise zur Weiterverarbeitung. Beim Erfüllen der
   * Promise wird ein Array mit Domains (Bsp.: {name: dfn.de, type: server})
   * zurückgegeben
   */
  getValidDomains(type) {
    const parameters = new SOAPClientParameters();
    const method = "getValidDomains";

    parameters.add("Type", type);

    return this._sendRequest(method, parameters).then(
      function (responseXML) {
        const domains = [];
        // Erfolg
        for (let item of responseXML.getElementsByTagName("item")) {
          domains.push({
            name: item.getElementsByTagName("Name")[0].innerHTML,
            type: item.getElementsByTagName("Type")[0].innerHTML,
          });
        }
        return domains;
      },
      function (errorMessage) {
        // Fehler
        throw new Error(errorMessage);
      }
    );
  }

  /**
   * 4.1.7 Liefert Informationen zur CA.
   *
   * @returns {Promise} Promise zur Weiterverarbeitung. Beim Erfüllen der
   * Promise wird ein Objekt mit Informationen zur CA zurückgegeben.
   * Aktuell ist darin nur die Chain hinterlegt.
   * TODO: Andere Informationen ebenfalls auswerten.
   */
  getCAInfo() {
    const parameters = new SOAPClientParameters();
    const method = "getCAInfo";

    return this._sendRequest(method, parameters).then(
      function (responseXML) {
        // logger.debug("responseXML: " + JSON.stringify(responseXML));
        const chainElement = responseXML.getElementsByTagName("CAChain")[0];
        const chain = [];
        // Erfolg
        for (let item of chainElement.getElementsByTagName("item")) {
          chain.push(item.innerHTML);
        }
        return { chain: chain };
      },
      function (errorMessage) {
        // Fehler
        throw new Error(errorMessage);
      }
    );
  }
}
