<!--
Beispiel für eine Wenjaseite.

Hier oben sollte der Inhalt der Seite kurz beschrieben werden.

Auf dieser Seite finden sich verschiedene Beispiele wie Vue eingesetzt werden
kann um coole dynamische Seiten zu erzeugen. Dies kann und will allerdings kein
Ersatz für die Doku auf https://vuejs.org/v2/guide/index.html sein.

Manche Beispiele bestehen aus mehreren Teilen, die sich an verschiedenen Stellen
im Code finden (beispielsweise im HTML-Template und im Script-Teil). Um die
einander zuordnen zu können sind sie mit Tags (beispielsweise #GUI) markiert.
Wenn dich ein Thema interessiert, such nach dem Tag um alle Stellen zu finden,
die dafür relevant sind.
-->
<template>
  <div>
    <h1>Willkommen auf der Wenjabeispielseite</h1>
    <p>
      Das Betrachten der gerenderten Seite erklärt nicht, was hier passiert.
      Schau in den Quelltext src/components/pages/developer/ExamplePage, um zu
      wissen, wie die Seite entsteht.
    </p>

    <h2>Daten anzeigen und verändern. #DATA</h2>
    <p>
      Jede Komponente hat einen eigenen (temporären) Datenspeicher für die
      dynamische Darstellung von Daten. Alle benötigten Daten müssen dafür in
      der Funktion data() (definiert im export des script-Tags) geliefert
      werden.
    </p>
    <p>
      Die in Data gelieferten Werte stehen direkt im Template zur Verfügung. Mit
      zwei geschweiften Klammern wird entsprechendes Javascript eingebunden:
    </p>
    <p>Hallo {{ name }}!</p>
    <p>
      Die Daten können auch durch ausgeführtes Javascript geändert werden:
      <button v-on:click="name = 'Welt'">{{ name }} --> Welt!</button>
    </p>
    <p>
      Die Daten können auch an Inputs gebunden werden, so dass eine Beziehung in
      beide Richtungen entsteht: <input v-model="name" /> Egal wo der Wert
      geändert wird, alle Referenzen werden entsprechend aktualisiert.
    </p>

    <h2>CSS verwenden. #CSS</h2>
    <p>
      Jede Komponente kann eigenes CSS in einem style-Tag am Ende der Datei
      definieren. Mit dem Schlüsselwort <em>scoped</em> beziehen sich alle
      Style-Angaben nur auf die Komponente selbst. Außer in Ausnahmen solltest
      du immer die scoped-Variante verwenden. Willst du wirklich das Aussehen
      <em>aller</em> Elemente einer Art (beispielsweise alle Links) in Wenja
      verändern, dann mach das an zentraler Stelle.
    </p>
    <p>
      Die folgende Überschrift ist im style-Tag modifiziert worden. An anderer
      Stelle in Wenja sind die Überschriften normal.
    </p>
    <h3>Testüberschrift</h3>

    <h2>Ein eigenes GUI-Element einbinden. #GUI</h2>
    <p>
      Der folgende Kasten ist aus '../gui-elements/ExampleElement.vue'
      eingebunden. Dazu ist im Script-Teil ein Import und eine Erwähnung im
      exportierten components nötig.
    </p>
    <p>
      Die Schreibweise des Tags muss die Kebap-Case-Variante des Namens der
      Komponente sein. Aus ExampleElement wird also der Tag example-element.
    </p>
    <p>https://vuejs.org/v2/guide/components.html</p>
    <example-element title="Eingebundene Komponente">
      Übergebener Inhalt aus der einbindenden Seite ExamplePage.
    </example-element>
    <p>
      Jetzt wird ein zweites Mal das ExampleElement eingebunden. Dieses Mal wird
      der Wert für initialCount geändert. Achtung: Auch der Attributname muss in
      Kebap-Case verwandelt werden (initial-count).
    </p>
    <p>
      Damit der Wert nicht als String übergeben wird, sondern als Number, muss
      vor den Attributnamen ein Doppelpunkt gesetzt werden. (Dadurch wird der
      Wert als Javascript interpretiert, wo die Zahl dann als Number
      interpretiert wird.) Schlussendlich ist :initial-count="1337" das fertige
      Attribut. #PROPS
    </p>
    <example-element title="Anderer Titel" :initial-count="1337">
    </example-element>

    <h2>Methoden aufrufen #METHODS</h2>
    <p>
      Wenn ein Klick auf eine Komponente eine Aktion auslösen soll, passiert das
      über die exportierten <em>methods</em>.
    </p>
    <p>
      <button v-on:click="hello('World')">Hello World</button>
    </p>

    <h2>Nachrichten ins Log schreiben #LOG</h2>
    <p>
      Um Informationen oder Debugmeldungen in das Entwicklerlog zu schreiben,
      können Methoden den Logger verwenden. Dazu muss der Logger importiert und
      initialisiert werden. (Siehe unten)
    </p>
    <p>
      <button v-on:click="logSomething('Hello')">Log something</button>
    </p>

    <h2>Nicht abgefangene Fehler</h2>
    <p>
      Fehler die nicht abgefangen wurden und der Benutzer kein Feedback dazu
      bekommt warum die Seite auf einmal nicht mehr funktioniert, sollen
      abgefangen werden und als Popup dem Benutzer angezeigt werden, damit
      dieser eine greifbare Fehlermeldung an den Support weiterleiten kann.
      Diese Funktion soll natürlich nie auftauchen.
    </p>
    <p>
      <button v-on:click="breakSomething()">Fehler werfen</button>
    </p>

    <h2>SOAP-Aufrufe starten. #API</h2>
    <p>
      Für SOAP-Aufrufe haben wir den SOAP-Client soapclient.js, der die zu
      sendenden Daten nach XML konvertiert und die HTTP-Anfrage macht. Über
      dieser Schicht kommt der dfnsoapclient.js, der alle von uns benötigten
      SOAP-Aufrufe implementiert. Er ist das Pendant zum SOAP-Client in Java.
      Der dfnsoapclient wird in den Aktionen initialisiert und auch nur dort
      aufgerufen. Das Ergebnis wird asynchron geholt. Werden andere
      SOAP-Methoden benötigt, dann sollten sie im dfnsoapclient mit Verweis auf
      die Doku hinzugefügt werden. TODO: Mehr technische Details.
    </p>
    <h2>Internationalization (i18n)</h2>
    <p>navigator.language: {{ language }} | locale: {{ locale }}</p>
    <p>
      {{ $t("hello") }}
    </p>
    <p>
      {{ $t("hello_var", { msg: "hallihallo" }) }}
    </p>
    <p v-html="$t('hello_html')"></p>
    <p>
      {{ $tc("car", 1) }}
    </p>
    <p>
      {{ $tc("car", 2) }}
    </p>
    <p>
      {{ $tc("apple", 0) }}
    </p>
    <p>
      {{ $tc("apple", 1) }}
    </p>
    <p>
      workaroundish:
      {{ $tc("apple", 2, { count: "zwei" }) }}
    </p>
    <p>
      {{ $tc("apple", 10, { count: 10 }) }}
    </p>
    <p>message: {{ $t("hihi") }}</p>
  </div>
</template>

<i18n>
en:
  hihi: "wuah wuah wuah (laughing in english)"
  hello: "hello world"
  hello_var: "{msg} var"
  hello_html: "hello  <p> html </p>"
  car: "car | cars"
  apple: "no apples | one apple | {count} apples"
de:
  hihi: "harr harr harr (auf deutsch lachen)"
  hello: "hallo welt"
  hello_var: "{msg} var"
  hello_html: "hallo <p> html </p>"
  car: "Auto | Autos"
  apple: "keine Äpfel | ein Apfel | {count} Äpfel"
</i18n>

<script>
// Hier kann normaler Javascriptcode stehen. Es können Sachen importiert oder
// auch Funktionen und Variablen definiert werden.

// Hier wird die eingebundene Komponente ExampleElement importiert. #GUI
// Achtung: Keine geschweiften Klammern um "ExampleElement"!
import ExampleElement from "@/components/pages/developer/ExampleElement";

// Hier wird der Logger importiert. #LOG
// Achtung: Geschweifte Klammern um "Logger"!
import { Logger } from "@/logger/logger";

// Logger für diese Komponente initialisieren #LOG
const log = new Logger("ExamplePage");

// Das hier exportierte Objekt macht zusammen mit dem <template> oben die
// Vue-Komponente aus.
export default {
  // In Components müssen alle eingebundenen Komponenten angegeben werden. #GUI
  components: { ExampleElement },
  // #DATA
  data: function () {
    return {
      name: "nobody",
      language: navigator.language,
      locale: navigator.language.substr(0, 2),
    };
  },
  // Alle Methoden, die im Template aufgerufen werden können. #METHODS
  methods: {
    hello(name) {
      window.alert("Hello " + name);
    },
    logSomething(msg) {
      log.info(msg);
    },
    popUpClosed() {
      log.info("Popup geschlossen");
    },
    breakSomething() {
      throw Error("Mein schöner Beispiel-Fehler");
    },
  },
};
</script>

<style scoped>
/*
Hier stehen alle Styles, die diese Komponente betreffen. #CSS

Das Schlüsselwort scoped im style-Tag sorgt dafür, dass allgemeine Angaben sich
nur auf die Elemente dieser Komponente beziehen.
Beispiel: Die Überschrift h3 wird auf dieser Seite in grün dargestellt, aber
nicht auf anderen Seiten. Auch die h3 in dem eingebundenen ExampleElement ist
nicht grün.

Grundsätzlich ist aber das Verwenden von Klassennamen besser, weil es schneller
rendert.

https://vue-loader.vuejs.org/en/features/scoped-css.html
*/
h3 {
  color: #066;
}

h2 {
  margin-top: 1em;
  border-bottom: solid 1px grey;
}
</style>
