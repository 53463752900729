var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',[_c('h2',[_vm._v(_vm._s(_vm.$t("title2")))]),_c('p',[_vm._v(_vm._s(_vm.$t("hint")))]),_c('p',[_vm._v(_vm._s(_vm.$t("mandatory_inputs")))]),_c('DomainDropdown',{attrs:{"server":_vm.showServerDomains,"email":_vm.showEmailDomains}}),_vm._l((_vm.allInputs),function(inputObject){return _c('div',{key:inputObject.id},[_c('b-container',{staticClass:"px-0",attrs:{"fluid":"md"}},[_c('p'),_c('b-row',{attrs:{"no-gutters":"","align-h":"start"}},[_c('label',{attrs:{"for":inputObject.id}},[_vm._v(_vm._s(inputObject.label[_vm.$i18n.locale])+" "+_vm._s(inputObject.optional ? "" : "*")+" ")])]),_c('b-row',{attrs:{"no-gutters":"","align-h":"start"}},[(inputObject.type.shortName == 'GN')?_c('div',[_c('b-form-checkbox',{attrs:{"id":"checkbox-GN","name":"checkbox-1GN","aria-describedby":"input-true input-false input-help"},on:{"change":function($event){_vm.clearGN();
              _vm.setDerivedCN();
              _vm.submitData();},"input":function($event){return _vm.setDerivedCN()}},model:{value:(_vm.noGivenName),callback:function ($$v) {_vm.noGivenName=$$v},expression:"noGivenName"}},[_c('p',[_vm._v(_vm._s(_vm.$t("noGNText")))])])],1):_vm._e()]),_c('b-row',{attrs:{"no-gutters":"","align-h":"start"}},[_c('b-input-group',[(
              _vm.certType == _vm.certTypes.server &&
              (inputObject.type == _vm.SERVER_DOMAIN_TYPE ||
                inputObject.type == _vm.sanTypes.IPAddress)
            )?_c('b-col',{staticClass:"px-0",attrs:{"cols":"4"}},[_c('b-form-select',{attrs:{"options":_vm.serverCNoptions.some((o) => o.value == inputObject.type)
                  ? _vm.serverCNoptions
                  : _vm.serverSANoptions},on:{"change":function($event){return _vm.displayFeedback(inputObject)}},model:{value:(inputObject.type),callback:function ($$v) {_vm.$set(inputObject, "type", $$v)},expression:"inputObject.type"}})],1):_vm._e(),_c('b-col',{staticClass:"px-0"},[_c('b-form-input',{attrs:{"id":inputObject.id,"type":"text","disabled":(inputObject.type === _vm.rdnTypes.GN && _vm.noGivenName) ||
                (inputObject.type === _vm.rdnTypes.CN &&
                  _vm.cnInputDisabled &&
                  !_vm.noGNSN),"state":_vm.displayAllFeedback
                  ? !_vm.inputHasError(inputObject)
                  : inputObject.state,"maxlength":inputObject.type.maxLength,"aria-describedby":"input-true input-false input-help","placeholder":inputObject.placeholder[_vm.$i18n.locale],"trim":""},on:{"change":function($event){_vm.setDerivedCN();
                _vm.submitData();},"input":function($event){_vm.setDerivedCN();
                _vm.displayFeedback(inputObject);
                _vm.submitData();}},model:{value:(inputObject.value),callback:function ($$v) {_vm.$set(inputObject, "value", $$v)},expression:"inputObject.value"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-false"}},[_vm._v(_vm._s(_vm.$t( (inputObject.type === _vm.SERVER_DOMAIN_TYPE ? "DNS" : inputObject.type === _vm.SERVER_IP_TYPE ? "IP" : inputObject.type.shortName) + "_feedback" )))]),_c('b-form-valid-feedback',{attrs:{"id":"input-true"}})],1)],1)],1)],1)],1)}),(this.certType == _vm.certTypes.server)?_c('b-button',{staticClass:"mt-3",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.addSanInput()}}},[_vm._v(" "+_vm._s(_vm.$t("ServerSAN_button"))+" ")]):_vm._e(),_c('div',{attrs:{"id":"div-namespace"}},[_c('label',[_vm._v(" "+_vm._s(_vm.$t("namespace"))+" ")]),_c('b-form-select',{attrs:{"options":_vm.dnPrefixes},on:{"input":function($event){return _vm.submitData()}},model:{value:(_vm.selection),callback:function ($$v) {_vm.selection=$$v},expression:"selection"}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }