<!--
Button und Modal für das Abfragen eines neuen Passworts.

Wenn der Button gedrückt wird, öffnet sich das Modal.
Titel, Beschriftung und Buttonbeschriftung können geändert werden.

Wenn der benutzer Ok drückt, wird das neue Passwort in dem Event "newPassword"
emitted.

#PROPS
@param {string} button-text die Beschriftung des Buttons zum Anzeigen
@param {string} title die Überschrift in der Box (optional)
@param {string} label Beschreibung über den Inputs
-->
<template>
  <span>
    <!-- TODO: modal-backdrop.opacity wird irgendwo global überschrieben. Daher erstmal eine lokale Änderung der Opacity bis der globale Wwrt gefunden wurde-->
    <b-modal
      header-bg-variant="primary"
      header-text-variant="light"
      v-model="showPasswordModal"
      v-b-modal.modal-lg
      variant="primary"
      centered
      :title="title ? title : $t('default_title')"
    >
      <form>
        <b-form-group
          :label="label"
          :description="$t('password_description')"
          :invalid-feedback="passwordFeedback"
          :valid-feedback="passwordFeedback"
          :state="passwordState"
        >
          <b-input
            v-model="password1"
            type="password"
            autocomplete="new-password"
            :placeholder="$t('password_placeholder1')"
            @change="checkPasswords"
          ></b-input>
          <b-input
            id="password2"
            v-model="password2"
            type="password"
            autocomplete="new-password"
            :placeholder="$t('password_placeholder2')"
            @input="checkPasswords"
          ></b-input>
        </b-form-group>
      </form>
      <div slot="modal-footer" class="w-100">
        <b-button @click="emitPasswords" variant="primary" block>Ok</b-button>
      </div>
    </b-modal>

    <!-- Der Button zum Anzeigen des Modals -->
    <b-button @click="showPasswordModal = true" variant="primary">{{
      buttonText
    }}</b-button>
  </span>
</template>

<i18n>
    en:
      default_title: "New Passphrase"
      password_description: "Please enter a new Passphrase (at least 8 characters)."
      password_placeholder1: "Passphrase"
      password_placeholder2: "Confirm Passphrase"
      password_matching: "The entered Passphrases match up."
      password_error_too_short: "The Passphrase must be at least 8 characters long."
      password_error_not_matching: "The entered Passphrases differ."
    de:
      default_title: "Neues Passwort"
      password_description: "Bitte wählen Sie ein neues Passwort (mindestens 8 Zeichen)."
      password_placeholder1: "Passwort"
      password_placeholder2: "Passwort bestätigen"
      password_matching: "Die beiden Passwörter stimmen überein."
      password_error_too_short: "Ihr Passwort muss mindestens 8 Zeichen lang sein."
      password_error_not_matching: "Die beiden Passwörter stimmen nicht überein."
</i18n>

<script>
export default {
  props: {
    buttonText: { type: String, required: true },
    title: { type: String, default: null },
    label: { type: String, required: true },
  },
  data() {
    return {
      password1: null,
      password2: null,
      passwordState: null,
      passwordFeedback: null,
      showPasswordModal: false,
    };
  },
  methods: {
    /**
     * Emittiert das neue Passwort, falls dieses in Ordnung ist.
     *
     * Das Modal wird anschließend geschlossen.
     *
     * Gesetzte Variablen: showPasswordModal
     */
    emitPasswords() {
      this.checkPasswords();
      if (!this.passwordState) {
        return;
      }
      this.$emit("newPassword", this.password1);
      this.showPasswordModal = false;
    },
    /**
     * Überprüft die P12-Passwortinputs auf Fehler.
     *
     * Gesetzte Variablen: passwordState, passwordFeedback
     */
    checkPasswords() {
      const p1 = this.password1;
      const p2 = this.password2;

      this.passwordState = null;

      if (p1 == null || p1.length < 8) {
        this.passwordState = false;
        this.passwordFeedback = this.$t("password_error_too_short");
        return;
      }
      if (p1 !== p2) {
        this.passwordState = false;
        this.passwordFeedback = this.$t("password_error_not_matching");
        return;
      }
      this.passwordState = true;
      this.passwordFeedback = this.$t("password_matching");
    },
  },
};
</script>

<style>
.modal-backdrop {
  opacity: 0.5 !important;
}
#password2 {
  margin-top: 10px;
}
</style>
