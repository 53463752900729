<template>
  <b-nav
    class="navbar navbar-expand fixed-bottom navbar-dark bg-dark"
    id="footer"
  >
    <div class="navbar-nav mx-auto small">
      <a
        class="nav-item nav-link"
        target="_blank"
        rel="noopener noreferrer"
        :href="brand.footer.contactUrl"
      >
        {{ $t("contact") }}
      </a>
      <span class="navbar-text px-2">|</span>
      <a
        class="nav-item nav-link"
        target="_blank"
        rel="noopener noreferrer"
        :href="brand.footer.privacyUrl[i18n.locale]"
      >
        {{ $t("privacy_notice") }}
      </a>
      <span class="navbar-text px-2">|</span>
      <a
        class="nav-item nav-link"
        target="_blank"
        rel="noopener noreferrer"
        :href="brand.footer.imprintUrl[i18n.locale]"
      >
        {{ brand.footer.imprintText[i18n.locale] }}
      </a>
      <span class="navbar-text px-2">|</span>
      <b-nav-text> {{ custom.windowTitle }} </b-nav-text>
    </div>
  </b-nav>
</template>

<i18n>
  en:
    contact: "Contact"
    privacy_notice: "Privacy Notice"
  de:
    contact: "Kontakt"
    privacy_notice: "Datenschutz"
</i18n>

<script>
import { i18n } from "@/i18n/i18n";
import { brand } from "@/brand/brand";
import { custom } from "@/customization/customization";

export default {
  data() {
    return {
      brand,
      i18n,
      custom,
    };
  },
};
</script>

<style scoped>
/* Abstand zum Inhalt oberhalb */
#footer {
  height: 30px;
}
</style>
