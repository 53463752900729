<!--
Komponente für die Darstellung von Fehlermeldungen an den Benutzer.
-->
<template>
  <div>
    <b-modal
      v-model="showMessage"
      :title="$t('error')"
      header-bg-variant="danger"
      header-text-variant="light"
      centered
    >
      <p class="pre-formatted">{{ message }}</p>
      <div slot="modal-footer" class="w-100">
        <b-button @click="showMessage = false" variant="primary" block
          >Ok</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<i18n>
en:
  error: "Error"
de:
  error: "Fehler"
</i18n>

<script>
import { Logger } from "@/logger/logger";

const log = new Logger("ErrorModal");

export default {
  data() {
    return {
      showMessage: false,
      message: "",
    };
  },
  methods: {
    error(message) {
      log.error(message);
      this.showMessage = true;
      this.message = message;
    },
  },
};
</script>

<style scoped>
.pre-formatted {
  white-space: pre-wrap;
}
</style>
