/**
 * Reguläre Ausdrücke zum Prüfen der RDNs wie auf
 * https://projects.dfn-cert.de/mtrac/openca/wiki/SpezifikationZertifikatfelder
 * beschrieben.
 */
export const SYNTAX_PATTERN = {
  // EMAIL: '^[0-9a-zA-Z\\-_.+]{1,64}@([a-zA-Z0-9_]+(-+[a-zA-Z0-9_]+)*\\.?)+(?<!\\.)$',
  //EMAIL: /^[0-9a-zA-Z\-_.+]{1,64}@([a-zA-Z0-9_]+(-+[a-zA-Z0-9_]+)*\.?)+([a-zA-Z0-9_]+(-+[a-zA-Z0-9_]+)*)+$/,
  EMAIL:
    /^(?=.{1,254}$)(?=.{1,64}@.+$)[-_+a-zA-Z0-9]+(\.[-_+a-zA-Z0-9]+)*@((xn--)|(XN--)?[a-zA-Z0-9]+([-_][a-zA-Z0-9]+)*\.)+((xn--)|(XN--)?[a-zA-Z0-9]+)$/,
  TEXT: /^[a-zA-Z0-9() ,'.:/-]*[a-zA-Z0-9][a-zA-Z0-9() ,'.:/-]*$/,
  TEXT_WITH_AT: /^[a-zA-Z0-9@() ,'.:/-]*[a-zA-Z0-9][a-zA-Z0-9@() ,'.:/-]*$/,
  // Genau wie TEXT_WITH_AT aber mit vorangestelltem *. für Wildcards. #2008
  TEXT_WITH_AT_AND_WILDCARD:
    /^(\*\.)?[a-zA-Z0-9@() ,'.:/-]*[a-zA-Z0-9][a-zA-Z0-9@() ,'.:/-]*$/,
  DOMAIN_OR_WILDCARDDOMAIN:
    /^(\*\.)?([0-9a-zA-Z](-*[0-9a-zA-Z])*\.)+[0-9a-zA-Z](-*[0-9a-zA-Z])*$/,
  IPv4: /^([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])\.([01]?\d\d?|2[0-4]\d|25[0-5])$/,
  IPv6: /^([23][0-9a-fA-F]{3}|ff00):.+$/,
  HOSTNAME: /^[0-9a-zA-Z-]+$/,
  SERVER:
    /((^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$)|(^((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?$)|((^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$)|([0-9a-zA-Z-]+)))/,
  IP: /((^((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/,
  DNS: /^(([0-9a-zA-Z](-*[0-9a-zA-Z])*\\.)+[0-9a-zA-Z](-*[0-9a-zA-Z])*)|([0-9a-zA-Z-]+)$/,
};

/**
 * Alle von uns erlaubten RDN-Typen mit sprechender Bezeichnung und Beispiel.
 * Die Reihenfolge ist wichtig, sie bestimmt die Rückgabe der Sortierfunktion
 * und damit die Reihenfolge, in der wir RDNs sortieren.
 */
export const ALLOWED_RDN_TYPES = {
  E: {
    oid: "1.2.840.113549.1.9.1",
    shortName: "E",
    pattern: SYNTAX_PATTERN.EMAIL,
    maxLength: 256,
  },
  //  x500UniqueIdentifier: {
  //    shortName: 'x500UniqueIdentifier',
  //    hint: 'x500UniqueIdentifier',
  //    example: '',
  //    pattern: pattern.TEXT
  //  },
  serialNumber: {
    oid: "2.5.4.5",
    shortName: "serialNumber",
    pattern: SYNTAX_PATTERN.TEXT,
    maxLength: 64,
  },
  UID: {
    oid: "0.9.2342.19200300.100.1.1",
    shortName: "UID",
    pattern: SYNTAX_PATTERN.TEXT_WITH_AT,
    maxLength: 128,
  },
  CN: {
    oid: "2.5.4.3",
    shortName: "CN",
    pattern: SYNTAX_PATTERN.TEXT_WITH_AT_AND_WILDCARD,
    maxLength: 64,
  },
  pseudonym: {
    oid: "2.5.4.65",
    shortName: "pseudonym",
    pattern: SYNTAX_PATTERN.TEXT_WITH_AT,
    maxLength: 59,
  },
  GN: {
    oid: "2.5.4.42",
    shortName: "GN",
    pattern: SYNTAX_PATTERN.TEXT_WITH_AT,
    maxLength: 64,
  },
  SN: {
    oid: "2.5.4.4",
    shortName: "SN",
    pattern: SYNTAX_PATTERN.TEXT_WITH_AT,
    maxLength: 64,
  },
  OU: {
    oid: "2.5.4.11",
    shortName: "OU",
    pattern: SYNTAX_PATTERN.TEXT,
    maxLength: 64,
  },
  O: {
    oid: "2.5.4.10",
    shortName: "O",
    pattern: SYNTAX_PATTERN.TEXT,
    maxLength: 64,
  },
  L: {
    oid: "2.5.4.7",
    shortName: "L",
    pattern: SYNTAX_PATTERN.TEXT,
    maxLength: 128,
  },
  ST: {
    oid: "2.5.4.8",
    shortName: "ST",
    pattern: SYNTAX_PATTERN.TEXT,
    maxLength: 128,
  },
  C: {
    oid: "2.5.4.6",
    shortName: "C",
    pattern: /[A-Z]{2}/,
    maxLength: 2,
  },
  DC: {
    oid: "0.9.2342.19200300.100.1.25",
    shortName: "DC",
    pattern: SYNTAX_PATTERN.TEXT,
    maxLength: 128,
  },
};

/**
 * Alle erlaubten SAN-Typen. Bezeichner und shortName müssen übereinstimmen.
 * Die typeId ist nur wichtig für die PKCS10-Erzeugung mit forge.
 * Die verschiedenen Typen von Subject-Altnames werden von Forge mit den
 * folgenden Nummern identifiziert:
 *   otherName      [0] INSTANCE OF OTHER-NAME, Microsoft_UPN
 *   rfc822Name     [1] IA5String,              email
 *   dNSName        [2] IA5String,              DNS
 *   x400Address    [3] ORAddress,
 *   directoryName  [4] Name,
 *   ediPartyName   [5] EDIPartyName,
 *   uniformResourceIdentifier [6] IA5String,  URI
 *   IPAddress      [7] OCTET STRING,          IP
 *   registeredID   [8] OBJECT IDENTIFIER
 */
export const ALLOWED_SAN_TYPES = {
  email: {
    shortName: "email",
    typeId: 1,
    pattern: SYNTAX_PATTERN.EMAIL,
    maxLength: 256,
  },
  Microsoft_UPN: {
    shortName: "Microsoft_UPN",
    typeId: 0,
    pattern: SYNTAX_PATTERN.EMAIL,
    maxLength: 256,
  },
  URI: {
    shortName: "URI",
    typeId: 6,
    pattern: SYNTAX_PATTERN.TEXT,
    maxLength: 256,
  },
  IPAddress: {
    shortName: "IP",
    typeId: 7,
    pattern: SYNTAX_PATTERN.IP,
    maxLength: 256,
  },
  DNS: {
    shortName: "DNS",
    typeId: 2,
    pattern: SYNTAX_PATTERN.DNS,
    maxLength: 256,
  },

  // TODO: DNS, IP Address, Directory Name, General Name (sind für Serverzertifikate)
  // TODO Directory Name und General Name sind nicht in Spezifikationen?
};

/**
 * Gibt den RDN-Typ aus den ALLOWED_RDN_TYPES zur zugehörigen OID.
 *
 * Sollte die gegebene OID unbekannt sein, wird ein Fehler geworfen.
 *
 * @param {String} oid die OID des gesuchten RDN-Typs
 */
export function getRdnTypeByOid(oid) {
  for (let type in ALLOWED_RDN_TYPES) {
    type = ALLOWED_RDN_TYPES[type];
    if (type.oid === oid) {
      return type;
    }
  }
  throw new Error("Nicht unterstützte OID " + oid);
}

/**
 * Gibt den SAN-Typ aus den ALLOWED_SAN_TYPES zur zugehörigen ID.
 *
 * Die typeId zurückgegebenen Typs ist identisch mit der übergebenen ID.
 * Sollte die gegebene ID unbekannt sein, wird ein Fehler geworfen.
 *
 * @param {Integer} typeId von Forge verwendete ID des SAN-Typs
 */
export function getSanTypeById(typeId) {
  for (let type in ALLOWED_SAN_TYPES) {
    type = ALLOWED_SAN_TYPES[type];
    if (type.typeId === typeId) {
      return type;
    }
  }
  throw new Error("Nicht unterstützte typeId " + typeId);
}
