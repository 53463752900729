<!--
Dies ist die Seite mit allgemeinen CA-Informationen von Wenja.
-->
<template>
  <div>
    <h1>{{ $t("title") }}</h1>
    <p>{{ $t("intro", { pki_name: brand.pkiName }) }}</p>

    <b-card-group deck>
      <b-card header-tag="header" footer-tag="footer">
        <template #header>
          {{ $t("ca_certs") }}
        </template>
        <b-card-text>{{ cacerts_text }}</b-card-text>
        <template #footer>
          <p v-if="hasRoot">
            <a target="_blank" rel="noopener noreferrer" :href="root_cert">{{
              $t("rootcert_link_text")
            }}</a>
          </p>
          <p v-if="hasIntermediate">
            <a
              target="_blank"
              rel="noopener noreferrer"
              :href="intermediate_cert"
              >{{ $t("intermediate_link_text") }}</a
            >
          </p>
          <p>
            <a target="_blank" rel="noopener noreferrer" :href="ca_cert">
              {{ $t("cacert_link_text") }}
            </a>
          </p>
          <p>
            <a target="_blank" rel="noopener noreferrer" :href="chain">
              {{ $t("chain_link_text") }}
            </a>
          </p>
        </template>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <template #header>
          {{ $t("crl_infos") }}
        </template>
        <b-card-text>
          {{ $t("crl_infos_text") }}
        </b-card-text>
        <template #footer>
          <p>
            <a target="_blank" rel="noopener noreferrer" :href="cacrl_crl">
              {{ $t("cacrl_crl_link_text") }}
            </a>
          </p>
          <p>
            <a target="_blank" rel="noopener noreferrer" :href="cacrl_txt">
              {{ $t("cacrl_txt_link_text") }}
            </a>
          </p>
        </template>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <template #header>
          {{ $t("policy_infos") }}
        </template>
        <b-card-text>
          {{ $t("policy_infos_text", { pki_name: brand.pkiName }) }}
        </b-card-text>
        <template #footer>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              :href="custom.policy.policyUrl"
              >{{ $t("policy_url_text", { pki_name: brand.pkiName }) }}
            </a>
          </p>
        </template>
      </b-card>
    </b-card-group>

    <b-card-group deck v-if="brand.showTSMAInfos">
      <b-card header-tag="header" footer-tag="footer">
        <template #header>
          {{ $t("tsma_infos") }}
        </template>
        <b-card-text>{{ $t("tsma_infos_text") }}</b-card-text>
        <template #footer>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://blog.pki.dfn.de/tag/guira-releases"
            >{{ $t("tsma_url_text") }}</a
          >
        </template>
      </b-card>
    </b-card-group>
  </div>
</template>

<i18n>
  en:
    title: "CA information"
    intro: "Here you can find additional information on {pki_name}"

    ca_certs: "CA certificates"
    crl_infos: "Revoked certificates"
    policy_infos: "Policy information"
    tsma_infos: "Information for local subscriber service"

    ca_certs_text: "Download {root}{intermediate} the CA certificate as well as the certificate chain."
    root_text: "the root certificate,"
    intermediate_text: " the intermediate certificate, "
    crl_infos_text: "Get the Certificate Revocation List (CRL)."
    policy_infos_text: "Get policy information about the operating of the {pki_name}."
    tsma_infos_text: "Get the latest version of the Java RA application for the local subscriber service."

    rootcert_link_text: "Root-CA-Certificate (DER-format)"
    intermediate_link_text: "Intermediate-CA-Certificate (DER-format)"
    cacert_link_text: "CA-Certificate (DER-format)"
    chain_link_text: "Full CA certificate chain (PEM-format)"
    cacrl_crl_link_text: "Certificate Revocation List (DER-format)"
    cacrl_txt_link_text: "Certificate Revocation List (TXT/PEM-format)"
    policy_url_text: "Information about the {pki_name}"
    tsma_url_text: "https://blog.pki.dfn.de/tag/guira-releases"

  de:
    title: "CA Informationen"
    intro: "Hier erhalten Sie zusätzliche Informationen zur {pki_name}."

    ca_certs: "CA Zertifikate"
    crl_infos: "Gesperrte Zertifikate"
    policy_infos: "Policy-Informationen"
    tsma_infos: "Informationen für den Teilnehmer-Service"

    ca_certs_text: "Hier können Sie {root}{intermediate} das CA-Zertifikat einzeln sowie die Zertifikatskette herunterladen."
    root_text: "das Wurzelzertifikat,"
    intermediate_text: " das Zwischenzertifikat, "
    crl_infos_text: "Hier können Sie die Zertifikatsperrliste (CRL) herunterladen."
    policy_infos_text: "Hier können Sie die Informationen zum Betrieb der {pki_name} herunterladen."
    tsma_infos_text: "Hier können Sie die aktuelle Version der Java RA-Oberfläche für den Teilnehmer-Service herunterladen."

    rootcert_link_text: "Wurzel-CA-Zertifikat (DER-Format)"
    intermediate_link_text: "Zwischen-CA-Zertifikat (DER-Format)"
    cacert_link_text: "CA-Zertifikat (DER-Format)"
    chain_link_text: "Vollständige CA-Zertifikatskette (PEM-Format)"
    cacrl_crl_link_text: "Zertifikatsperrliste (DER-Format)"
    cacrl_txt_link_text: "Zertifikatsperrliste (TXT/PEM-Format)"
    policy_url_text: "Informationen über die {pki_name}"
    tsma_url_text: "https://blog.pki.dfn.de/tag/guira-releases"
</i18n>

<script>
import { Logger } from "@/logger/logger";
import { brand } from "@/brand/brand";
import { custom } from "@/customization/customization";
import { DFNSOAPClient } from "@/soap/dfnsoapclient";

const logger = new Logger("CAInformationPage");

export default {
  data: function () {
    return {
      custom: custom,
      brand: brand,
      cacrl_crl: custom.downloadBaseURL + "crl/cacrl.crl",
      cacrl_txt: custom.downloadBaseURL + "crl/cacrl.txt",
      root_cert: custom.downloadBaseURL + "cacert/rootcert.crt",
      intermediate_cert:
        custom.downloadBaseURL + "cacert/intermediatecacert.crt",
      ca_cert: custom.downloadBaseURL + "cacert/cacert.crt",
      chain: custom.downloadBaseURL + "cacert/chain.txt",
      hasRoot: false,
      hasIntermediate: false,
    };
  },

  computed: {
    cacerts_text() {
      this.getChainLength();
      let text = this.$t("ca_certs_text");
      if (this.hasRoot) {
        text = text.replace("{root}", this.$t("root_text"));
      } else {
        text = text.replace("{root}", "");
      }
      if (this.hasIntermediate) {
        text = text.replace("{intermediate}", this.$t("intermediate_text"));
      } else {
        text = text.replace("{intermediate}", "");
      }
      return text;
    },
  },

  methods: {
    async getChainLength() {
      const client = new DFNSOAPClient(custom.pubURL, custom.ra_id);

      const caInfoResponse = await client.getCAInfo();
      const chain = caInfoResponse.chain;
      //logger.info("Chain: " + JSON.stringify(chain));
      logger.info("Länge der chain: " + chain.length);

      let chainLen = chain.length;
      if (chainLen > 1) {
        this.hasRoot = true;
      }
      if (chainLen > 2) {
        this.hasIntermediate = true;
      }

      return chainLen;
    },

    /**
     * Stellt eine Fehlermeldung dar.
     */
    error(msg) {
      this.$emit("error-message", msg);
    },
  },
};
</script>
