var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-navbar-nav',[_c('b-nav-item-dropdown',{attrs:{"text":_vm.$t('certificate_nav'),"right":""}},_vm._l((_vm.requestLinks),function(link){return _c('b-dropdown-item',{key:link.name},[_c('router-link',{staticClass:"nav-item nav-link",attrs:{"to":{
          name: link.name,
          params: link.params,
        },"active-class":"active","exact":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(link.title)))])])],1)}),1),_vm._l((_vm.links),function(link){return _c('b-nav-item',{key:link.name},[_c('router-link',{staticClass:"nav-item nav-link",attrs:{"to":{
        name: link.name,
        params: link.params,
      },"active-class":"active","exact":""}},[_c('span',[_vm._v(_vm._s(_vm.$t(link.title)))])])],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }