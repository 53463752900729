/**
 *
 * Hier werden die Übersetzungen abgelegt die über Javascript abgefragt werden,
 * aktuell betrifft das allerdings nur den Router.
 *
 */

export const messages = {
  en: {
    message: {
      examplePage: "Example page",
      log: "Log",
      start: "Start",
      newCert: "New certificate",
      newCertPseudoym: "New pseudonym certificate",
      newCertGroup: "New group certificate",
      newCertServer: "New server dertificate",
      uploadPKCS10: "PKCS10 upload",
      retrieveCert: "Retrieve certificate",
      saveCert: "Save certificate",
      doneCert: "Use certificate",
      revokeCert: "Revoke certificate",
      error: "Error",
      confirmRequest: "Confirm request",
    },
  },
  de: {
    message: {
      examplePage: "Beispielseite",
      log: "Log",
      start: "Start",
      newCert: "Neues Zertifikat",
      newCertPseudoym: "Neues Pseudonymzertifikat",
      newCertGroup: "Neues Gruppenzertifikat",
      newCertServer: "Neues Serverzertifikat",
      uploadPKCS10: "PKCS10 Upload",
      retrieveCert: "Zertifikat abholen",
      saveCert: "Zertifikat speichern",
      doneCert: "Zertifikat verwenden",
      revokeCert: "Zertifikat sperren",
      error: "Fehler",
      confirmRequest: "Antrag bestätigen",
    },
  },
};
