/**
 * In dieser Datei wird die Customization geladen und bereitgestellt.
 */
import axios from "axios";
import { brand } from "@/brand/brand";
import { expectedStructure, checkStructure } from "@/customization/structure";
import { POLICY_PREFERENCES } from "@/customization/policy";
import { Logger } from "@/logger/logger";

const log = new Logger("customization");

/**
 * Diese Variable muss zum Zugriff auf die Customization importiert werden.
 *
 * Bis zum erfolgreich durchgeführten fetch() ist custom undefined. Nach
 * erfolgtem fetch() ist custom initialisiert und nicht mehr änderbar.
 */
export let custom;

let fetched = false;

/**
 * Holt die Customization für die aktuelle RA.
 *
 * Nach dem Aufruf von fetch steht die Variable "custom" zur Verfügung.
 * Darf nur ein Mal aufgerufen werden.
 *
 * @param {String} ca die CA für die die Customization gilt
 * @param {Number} raid die RAID für die die Customization gilt
 */
export async function fetch(ca, raid) {
  if (fetched) {
    throw new Error("Customization wurde schon geholt.");
  }
  log.debug("Hole Customization für ca: " + ca + ", raid: " + raid);
  const url =
    brand.basePath + "customization/" + ca + "/custom-" + raid + ".json";
  const response = await axios.get(url);
  // TODO: Fehlerbehandlung
  custom = response.data;

  log.trace("Customization geholt:" + JSON.stringify(custom, null, 2));

  const customizationOk = checkStructure(custom, "custom", expectedStructure);

  if (customizationOk) {
    log.info("Customization ohne Fehler geladen.");
  } else {
    log.warn("Die Customization enthält Fehler.");
  }

  // In custom.policy stehen die Einstellungen zur aktuellen Policy. Siehe #1833
  custom.policy = POLICY_PREFERENCES[custom.policy];

  // Wenn keine pubURL beim erstellen der customization übergeben wurde, wird hier eine erstellt
  if (custom.pubURL === "") {
    custom.pubURL = `${brand.baseURL}/${custom.ca}/cgi-bin/pub/soap/DFNCERT/Public`;
  }
  // Ebenso für downlaodBaseURL
  if (custom.downloadBaseURL === "") {
    custom.downloadBaseURL = `${brand.baseURL}/${custom.ca}/pub/`;
  }

  // Alle Properties von custom vor Zugriff schützen.
  Object.freeze(custom);

  fetched = true;
}
