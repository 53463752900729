<!--
Diese Komponente zeigt den erstellten Request an und bietet nach Passwortabfrage
das Speichern in einer Datei an.

Childkomponente von NewRequest.
-->
<template>
  <div>
    <!-- TODO: Funktion, die vom Wenja-Format in ein DN umwandelt -->
    <h1>{{ $t("page_title") }}</h1>
    <p
      v-html="
        $t('instructions', {
          download_instructions: $t(
            custom.receiptOnly
              ? 'download_instructions_receipt'
              : 'download_instructions_application',
            { pki_name: pki_name }
          ),
        })
      "
    ></p>
    <DisplayRequest :request="request"></DisplayRequest>
    <div>
      <p v-html="$t('important')"></p>
      <!-- Fallunterscheidung bei PKCS10-Upload -->
      <NewPassword
        :buttonText="$t('button_text')"
        :title="$t('title')"
        :label="$t(isPKCS10Upload() ? 'label_pkcs10' : 'label')"
        @newPassword="submitRequest"
      ></NewPassword>
    </div>
    <b-modal id="loadingModal" size="sm" hide-footer hide-header centered>
      <div class="text-center">
        {{ $t("loading") }}&nbsp;…
        <b-spinner small type="grow" label="Spinning"></b-spinner>
      </div>
    </b-modal>
  </div>
</template>

<i18n>
    en:
      page_title: "Your certificate application"
      back: "Back"
      instructions: "<p>Now take the following steps:</p>
          <ol>
          <li>Check the data below for correctness. Use the \"Change data\" button to change any data.</li>
          <li>Please click on the button \"Save certificate application data file\". You will be asked to set a passphrase for your certificate application data file and to save that file on your device. You will need this certificate application data file and its passphrase later on to download the certificate once it has been issued.</li>
          <li>{download_instructions}</li>
          </ol>"
      download_instructions_application: "Download the certificate application form (PDF) on the next page and hand it completed and signed to your local {pki_name} Subscriber-Service."
      download_instructions_receipt: "Download the certificate application receipt (PDF) on the next page and inform your local {pki_name} Subscriber-Service about your certificate application (including your application number)."
      important: "<b>Important:</b> If you lose the certificate application file before the certificate has been issued, all the data will be irretrievably lost and the process must be repeated."
      button_text: "Save certificate application data file"
      title: "Set passphrase"
      label: "Set passphrase for certificate application data file including the private key"
      label_pkcs10: "Set passphrase for certificate application data file"
      loading: "Uploading your certificate application"
      upload_error: "Your certificate application could not be uploaded: {error}"
    de:
      page_title: "Ihr Zertifikatantrag"
      back: "Zurück"
      instructions: "<p>Führen Sie jetzt noch folgende Schritte durch:</p>
          <ol>
          <li>Überprüfen Sie bitte Ihre Angaben auf Richtigkeit. Über den \"Daten ändern\"-Button können Sie alle Daten ändern.</li>
          <li>Bitte klicken Sie auf den Button \"Antragsdatei speichern\". Sie werden aufgefordert ein Passwort für die Antragsdatei zu setzen und die Datei auf Ihrem Gerät abzuspeichern. Sie benötigen diese Antragsdatei und das zugehörige Passwort später wieder, um das Zertifikat abholen zu können, sobald es ausgestellt wurde.</li>
          <li>{download_instructions}</li>
          </ol>"
      download_instructions_application: "Laden Sie auf der nächsten Seite das Zertifikatantragsformular (PDF) herunter und geben Sie es vollständig ausgefüllt und unterschrieben an Ihren lokalen {pki_name} Teilnehmerservice."
      download_instructions_receipt: "Laden Sie auf der nächsten Seite die Antragsquittung (PDF) herunter und informieren Sie Ihren lokalen {pki_name} Teilnehmerservice über Ihre Antragstellung (inkl. Ihrer Antragsnummer)."
      important: "<b>Wichtig:</b> Wenn Sie die Antragsdatei verlieren, bevor die Ausstellung des Zertifikats abgeschlossen ist, gehen auch die Daten unwiederbringlich verloren und der Vorgang muss wiederholt werden."
      button_text: "Antragsdatei speichern"
      title: "Passwort setzen"
      label: "Passwort für Antragsdatei inkl. privaten Schlüssel setzen"
      label_pkcs10: "Passwort für Antragsdatei setzen"
      loading: "Ihr Antrag wird hochgeladen"
      upload_error: "Ihr Antrag konnte nicht hochgeladen werden: {error}"
</i18n>

<script>
import { brand } from "@/brand/brand";
import { custom } from "@/customization/customization";
import { downloadFile, getFileName } from "@/utils/filehandling";
import DisplayRequest from "@/components/pages/new-certificate/DisplayRequest";
import NewPassword from "@/components/gui-elements/NewPassword";
import { Logger } from "@/logger/logger";
import { DFNSOAPClient } from "@/soap/dfnsoapclient";
import { CERT_TYPES } from "@/pki/certTypes";

const logger = new Logger("ConfirmRequest");

export default {
  name: "ConfirmRequest",
  components: {
    NewPassword,
    DisplayRequest,
  },
  props: {
    request: { type: Object, required: true },
    certType: { type: Object, required: true },
  },
  data() {
    return {
      custom,
      pki_name: brand.pkiName,
    };
  },
  methods: {
    /**
     * Erstellt aus den Antragsdaten einen Request, lädt ihn hoch und stellt die
     * entsprechende Antragsdatei zum Download bereit.
     *
     * @param {String} password das Passwort zum Verschlüsseln von pin und key
     */
    async submitRequest(password) {
      const request = this.request;
      const client = new DFNSOAPClient(custom.pubURL, custom.ra_id);

      // Hochladen
      this.$bvModal.show("loadingModal");

      logger.trace("Verschlüssel Request");
      request.encrypt(password);
      logger.trace("Request verschlüsselt");

      try {
        request.serial = await client.newRequest(request);
      } catch (error) {
        this.$bvModal.hide("loadingModal");
        logger.error("Fehler beim Upload: " + error);
        this.$emit("error-message", this.$t("upload_error", { error: error }));
        return;
      }
      this.$bvModal.hide("loadingModal");
      logger.info("Antrag hochgeladen, Serial: " + request.serial);

      const prefix = "Antragsdatei_" + request.getCN() + "_" + request.serial;
      const fileName = getFileName(prefix, "json", request.date);

      // Datei speichern
      const data = JSON.stringify(request.getSaveableCopy(), null, 2); // TODO: Linebreaks
      downloadFile(data, fileName, document, false);
      this.$emit("requestSaved");
    },
    isPKCS10Upload() {
      return this.certType == CERT_TYPES.pkcs10;
    },
  },
};
</script>

<style scoped></style>
