/* global process */ // suppress eslint warning: 'process' is not defined

export const loglevels = {
  ERROR: 1,
  WARNING: 2,
  INFO: 3,
  DEBUG: 4,
  TRACE: 5,
};

// Alle Logmeldungen. Format einer Nachricht: siehe Logger._log()
export const messages = [];

const IS_PRODUCTION = process.env.NODE_ENV === "production";

/**
 * Loggerklasse zum Loggen von Meldungen. Jede Datei sollte ihre eigene Instanz
 * verwenden.
 */
export class Logger {
  /**
   * Erstellt eine Loggerinstanz. Der angegebene Name kann später verwendet
   * werden um Nachrichten modulweise zu filtern.
   *
   * @param {type} name Dateiname, in der der Logger verwendet wird
   */
  constructor(name) {
    this.name = name;
  }

  /**
   * Die eigentliche Logfunktion, die die Nachricht über die Konsole ausgibt.
   * Die Nachricht wird nur ausgegeben, wenn das Loglevel des Loggers
   * mindestens so groß ist wie das angegebene Loglevel.
   *
   * Die Datei und Zeilennummer des Aufrufers wird über einen Trick ermittelt:
   * Es wird ein Error geworfen und aus dessen Stacktrace die Informationen
   * ermittelt. Das könnte man ggf. in eine extra Funktion auslagern.
   *
   * @param {String} msg Logmeldung
   * @param {int} loglevel das Loglevel der Nachricht
   */
  _log(msg, loglevel) {
    if (typeof msg !== "string") {
      msg = JSON.stringify(msg);
    }
    // Funktion um ein Error-Objekt zu bekommen. Dessen Stacktrace dient dazu
    // Informationen über die aufrufende Zeile zu bekommen.
    function getErrorObject() {
      try {
        throw Error("");
      } catch (err) {
        return err;
      }
    }

    const err = getErrorObject();
    const callerLine = err.stack.split("\n")[4];
    const logMessage = callerLine + " (" + this.name + "): " + msg;

    if (
      (loglevel <= loglevels.DEBUG && !IS_PRODUCTION) ||
      loglevel <= loglevels.ERROR
    ) {
      switch (loglevel) {
        case loglevels.TRACE:
          console.trace(logMessage);
          break;
        case loglevels.DEBUG:
          console.debug(logMessage);
          break;
        case loglevels.INFO:
          console.info(logMessage);
          break;
        case loglevels.WARNING:
          console.warn(logMessage);
          break;
        case loglevels.ERROR:
          console.error(logMessage);
          break;
      }
    }
    messages.push({
      name: this.name,
      text: msg,
      loglevel: loglevel,
      date: new Date(),
    });
  }

  /**
   * Loggt eine Fehlermeldung.
   *
   * @param {String} msg Logmeldung
   */
  error(msg) {
    this._log(msg, loglevels.ERROR);
  }

  /**
   * Loggt eine Warnmeldung.
   *
   * @param {String} msg Logmeldung
   */
  warn(msg) {
    this._log(msg, loglevels.WARNING);
  }

  /**
   * Loggt eine Infomeldung.
   *
   * @param {String} msg Logmeldung
   */
  info(msg) {
    this._log(msg, loglevels.INFO);
  }

  /**
   * Loggt eine Debugmeldung.
   *
   * @param {String} msg Logmeldung
   */
  debug(msg) {
    this._log(msg, loglevels.DEBUG);
  }

  /**
   * Loggt eine Tracemeldung.
   *
   * @param {String} msg Logmeldung
   */
  trace(msg) {
    this._log(msg, loglevels.TRACE);
  }
}
