/**
 * In den POLICY_PREFERENCES sind die unterschiedlichen Verhaltensweisen von
 * Wenja je nach Policy festgelegt. Nach Laden der Customization steht die zu
 * verwendende Policy fest und es gilt dann genau ein Regelset.
 *
 * In https://projects.dfn-cert.de/mtrac/openca/ticket/1833#comment:3 ist die
 * Idee dokumentiert.
 */
import { brand } from "@/brand/brand";
export const POLICY_PREFERENCES = {
  Global: {
    newRequest: {
      showDomains: true,
      noGNSN: false,
      dontSetPseudonym: false,
      showOnlySTLPrefixesForServer: true,
    },
    hideAllRequestPages: true,
    hidePseudonymGroup: false,
    hideServer: true,
    showNotification: true,
    policyUrl: "https://doku.tid.dfn.de/de:dfnpki:global",
  },
  Grid: {
    newRequest: {
      showDomains: false,
      noGNSN: true,
      dontSetPseudonym: false,
      showOnlySTLPrefixesForServer: false,
    },
    hideAllRequestPages: false,
    hidePseudonymGroup: true,
    hideServer: false,
    showNotification: false,
    // edu-pki und Kommerz-CAs laufen auch unter Grid-Policy, auch wenn es unterschiedliche CP/CPS gibt
    // Daher wird hier nach dem Brand unterschieden (internal-CAs bekommen dadurch Grid-CP/CPS angezeigt)
    // Wird mit der Aufteilung der Policies besser (s. https://projects.dfn-cert.de/mtrac/openca/wiki/Softwareentwicklung/Projekte/ModernisierungGridPolicy)
    policyUrl:
      brand.pkiName == "eduPKI"
        ? "https://www.edupki.org/fileadmin/Documents/eduPKI-CA-CP-CPS.pdf"
        : brand.pkiName == "DFN-CERT-PKI"
        ? "https://pki.dfn-cert.de/cpcps.pdf"
        : "https://doku.tid.dfn.de/de:dfnpki:grid",
  },
  Community: {
    newRequest: {
      showDomains: true,
      noGNSN: true,
      dontSetPseudonym: true,
      showOnlySTLPrefixesForServer: false,
    },
    hideAllRequestPages: false,
    hidePseudonymGroup: false,
    hideServer: false,
    showNotification: false,
    policyUrl:
      "https://doku.tid.dfn.de/_media/de:dfnpki:doc:dfn-verein_community_pki_cps.pdf",
  },
};
