<!--
Diese Komponente wird nur zur Darstellung der Navigationslinks verwendet.
Sie ist im Menü (AppNav.vue) eingebunden.
Die Links selbst basieren auf einem Array, das im Quelltext angelegt ist.
-->
<template>
  <b-navbar-nav>
    <b-nav-item-dropdown :text="$t('certificate_nav')" right>
      <b-dropdown-item v-for="link in requestLinks" :key="link.name">
        <router-link
          :to="{
            name: link.name,
            params: link.params,
          }"
          class="nav-item nav-link"
          active-class="active"
          exact
        >
          <span>{{ $t(link.title) }}</span>
        </router-link>
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-nav-item v-for="link in links" :key="link.name">
      <router-link
        :to="{
          name: link.name,
          params: link.params,
        }"
        class="nav-item nav-link"
        active-class="active"
        exact
      >
        <span>{{ $t(link.title) }}</span>
      </router-link>
    </b-nav-item>
  </b-navbar-nav>
</template>

<i18n>
    en:
      new_request: "User certificate"
      new_request_pn: "Pseudonym certificate"
      new_request_grp: "Group certificate"
      new_request_server: "Server certificate"
      new_request_pkcs10: "CSR (PKCS#10) upload"
      retrieve_certificate: "Retrieve certificate"
      revoke_certificate: "Revoke certificate"
      log: "Log (devel)"
      example: "Example-Page (devel)"
      certificate_nav: "New certificate "
      ca_information: "CA information"
    de:
      new_request: "Nutzerzertifikat"
      new_request_pn: "Pseudonymzertifikat"
      new_request_grp: "Gruppenzertifikat"
      new_request_server: "Serverzertifikat"
      new_request_pkcs10: "CSR-Datei (PKCS#10) einreichen"
      retrieve_certificate: "Zertifikat abholen"
      revoke_certificate: "Zertifikat sperren"
      log: "Log (Entwickler)"
      example: "Beispielseite (Entwickler)"
      certificate_nav: "Zertifikat beantragen"
      ca_information: "CA Informationen"
</i18n>

<script>
// suppress eslint warning: 'process' is not defined
/* global process */

import {
  EXAMPLE,
  LOG,
  NEW_REQUEST,
  NEW_REQUEST_PN,
  NEW_REQUEST_GROUP,
  NEW_REQUEST_SERVER,
  NEW_REQUEST_PKCS10,
  RETRIEVE_CERTIFICATE,
  REVOKE_CERTIFICATE,
  CA_INFOS,
} from "@/router/names";
import { custom } from "@/customization/customization";

import { Logger } from "@/logger/logger";

const log = new Logger("NavLinkList");

// Liste der Links, die angezeigt werden sollen.
const links = [
  { name: RETRIEVE_CERTIFICATE, title: "retrieve_certificate" },
  { name: REVOKE_CERTIFICATE, title: "revoke_certificate" },
];

export default {
  data: function () {
    const requestLinks = [
      {
        name: NEW_REQUEST,
        title: "new_request",
        params: { step: "1" },
      },
      {
        name: NEW_REQUEST_PN,
        title: "new_request_pn",
        params: { step: "1" },
      },
      {
        name: NEW_REQUEST_GROUP,
        title: "new_request_grp",
        params: { step: "1" },
      },
      {
        name: NEW_REQUEST_SERVER,
        title: "new_request_server",
        params: { step: "1" },
      },
      {
        name: NEW_REQUEST_PKCS10,
        title: "new_request_pkcs10",
        params: { step: "1" },
      },
    ];

    if (custom.policy.hidePseudonymGroup) {
      // 2. und 3. Eintrag (PN und GRP) entfernen
      requestLinks.splice(1, 2);
    }

    if (custom.policy.hideServer && new Date()) {
      // Der 4. Eintrag wird entfernt (SERVER) Ticket #2034
      requestLinks.splice(3, 1);
    }

    if (
      custom.policy.hideAllRequestPages &&
      new Date() > new Date(2023, 7, 30)
    ) {
      // Alle Einträge werden entfernt. Ticket #2073
      requestLinks.splice(0, requestLinks.length);
    }

    // Link zu Entwicklerseiten nur im Devel-Modus
    if (process.env.NODE_ENV !== "production") {
      links.push({ name: LOG, title: "log" });
      links.push({ name: EXAMPLE, title: "example" });
    }

    // Link zur Seite mit allgemeinen CA-Infos
    links.push({ name: CA_INFOS, title: "ca_information" });

    return {
      links: links,
      requestLinks: requestLinks,
    };
  },
};
</script>
