<!--
Auf dieser Seite kann der Benutzer ein Zertifikat sperren.
-->
<template>
  <div>
    <h1>{{ $t("title") }}</h1>
    <form v-if="!success">
      <!-- Seriennummer -->
      <b-form-group
        :description="$t('serial_description')"
        :invalid-feedback="serialFeedback"
        :valid-feedback="serialFeedback"
        :state="serialState"
      >
        <label for="serial">
          {{ $t("serial_label") }}
        </label>
        <b-form-input
          v-model="serial"
          :placeholder="$t('serial_placeholder')"
          :state="serialState"
          @input="checkSerial(serial)"
          type="text"
          autocomplete="off"
          id="serial"
          trim
        ></b-form-input>
      </b-form-group>
      <!-- PIN -->
      <b-form-group
        :description="$t('password_description')"
        :invalid-feedback="passwordFeedback"
        :valid-feedback="passwordFeedback"
        :state="passwordState"
      >
        <label for="password">
          {{ $t("password_label") }}
        </label>
        <b-form-input
          id="password"
          v-model="password"
          :placeholder="$t('password_placeholder')"
          :state="passwordState"
          @input="passwordState = null"
          type="password"
          autocomplete="current-password"
        ></b-form-input>
      </b-form-group>
      <!-- Sperrgrund -->
      <b-form-group
        :description="$t('reason_description')"
        :invalid-feedback="$t('choose_reason')"
        :valid-feedback="reason !== null ? $t('explanation_' + reason) : null"
        :state="reasonState"
      >
        <label for="reason">
          {{ $t("reason_label") }}
        </label>
        <b-form-select
          id="reason"
          v-model="reason"
          :options="reasons"
          :state="reasonState"
          @input="reasonState = reason !== null"
        ></b-form-select>
      </b-form-group>
      <!-- OK-Button -->
      <b-button
        variant="primary"
        :disabled="password == null || serial == null"
        @click="revoke"
        >{{ $t("revoke") }}</b-button
      >
    </form>
    <div v-else>
      <!-- Erfolgreich Sperrantrag abgeschickt. -->
      <p>{{ $t("crr_sent") }}</p>
      <!-- Zurück-Button -->
      <b-button variant="primary" @click="back">
        {{ $t("back") }}
      </b-button>
    </div>
  </div>
</template>

<i18n>
    // Die i18n-Strings sind in der Reihenfolge aufgeführt, in der sie der Benutzer zu sehen bekommt:
    // Strings, die noch nicht abgenommen wurden, sind mit einem Warndreieck markiert:
    en:
      title: "Certificate revocation"

      serial_label: "Serial number of the certificate to be revoked"
      serial_description: "If the certificate you want to revoke was sent to you
        via email after certificate issuance, you can find the relevant serial
        number of the certificate in that email. Otherwise you can find the
        certificate serial number in the certificate details as they are
        displayed by the certificate viewer of your operating system or software
        application, e.g. email client software or web browser."
      serial_placeholder: "Please enter the certificate serial number of the
        certificate you wish to revoke."
      serial_error: "The serial number must be a decimal or hexadecimal number."
      serial_not_found: "There is no certificate with this serial number."

      password_label: "Revocation PIN"
      password_description: "Usually the revocation PIN was supplied during the
        certificate application process by the applicant. If you don't know the
        revocation PIN, please contact your local subscriber service."
      password_placeholder: "Please enter the revocation PIN of the certificate
        you wish to revoke."
      password_too_short: "The entered revocation PIN is too short."
      password_error: "The entered revocation PIN is not correct."
      wrong_serial_or_password: "There is no certificate with this serial
        number or the entered revocation PIN is not correct."
      certificate_expired: "The certificate has already expired and can no longer be revoked."

      reason_label: "Reason for revocation"
      reason_description: "Please check whether you need to provide information
       on the revocation reason, e.g. if the private key has been compromised.
       Please select the most appropriate revocation reason."
      choose_reason: "Please select a revocation reason from the list."

      unspecified: "Unspecified"
      keyCompromise: "Key Compromise"
      affiliationChanged: "Change of Affiliation"
      superseded: "Superseded"
      cessationOfOperation: "Cessation of Operation"
      explanation_0: "If the other reasons do not apply, you must select the
        revocation reason \"Unspecified\"."
      explanation_1: "You must select the revocation reason \"Key Compromise\"
        if you have reason to believe that the private key of your certificate
        has been compromised, e.g. if an unauthorised person has had access to
        the private key."
      explanation_3: "You should select the revocation reason
        \"Change of Affiliation\" if the name of your organisation or other
        organisational information in the certificate has changed."
      explanation_4: "You should select the revocation reason \"Superseded\"
        if you have requested a new certificate to replace your
        existing certificate."
      explanation_5: "You should select the revocation reason \"Cessation of
        Operation\" if you no longer own all domain names of the certificate or
        if you no longer use the certificate because you discontinue the website."

      revoke: "Revoke certificate"

      crr_sent: "Your certificate revocation request has been sent."
      back: "️️Back"

    de:
      title: "Zertifikat sperren"

      serial_label: "Seriennummer des zu sperrenden Zertifikats"
      serial_description: "Sofern Sie bei der Ausstellung des zu sperrenden
        Zertifikats eine E-Mail mit dem Zertifikat bekommen haben, finden Sie
        die fragliche Seriennummer in dieser E-Mail. Ansonsten entnehmen Sie
        die Seriennummer des zu sperrenden Zertifikats bitte dessen
        Zertifikatdetails wie sie von der Zertifikatanzeige Ihres
        Betriebssystems bzw. Ihrer Anwendungs-Software (z.B. E-Mail-Programm
        oder Web-Browser) ausgegeben wird."
      serial_placeholder: "Geben Sie hier die Seriennummer des zu sperrenden
        Zertifikats ein."
      serial_error: "Die Seriennummer muss als Dezimal- oder Hexadezimalzahl
        eingegeben werden."
      serial_not_found: "Zu dieser Seriennummer existiert kein Zertifikat."

      password_label: "Sperr-PIN des Zertifikats"
      password_description: "Die Sperr-PIN wurde bei der Beantragung des
        Zertifikats von dem/der Beantragenden selbst gesetzt. Sofern Sie die
        Sperr-PIN nicht mehr kennen, wenden Sie sich bitte an Ihren lokalen
        Teilnehmerservice."
      password_placeholder: "Geben Sie hier die Sperr-PIN des zu sperrenden
        Zertifikats ein."
      password_too_short: "Die eingegebene Sperr-PIN ist zu kurz."
      password_error: "Die eingegebene Sperr-PIN ist leider nicht korrekt."
      wrong_serial_or_password: "Zu dieser Seriennummer existiert kein
        Zertifikat oder die eingegebene Sperr-PIN ist nicht korrekt."
      certificate_expired: "Das Zertifikat ist bereits abgelaufen und kann nicht mehr gesperrt werden."

      reason_label: "Sperrgrund"
      reason_description: "Bitte prüfen Sie, ob Sie Angaben zum Grund der Sperrung
       machen müssen, z.B. bei einer Kompromittierung des privaten Schlüssels.
       Bitte wählen Sie den passendsten Sperrgrund aus."
      choose_reason: "Bitte wählen Sie einen Sperrgrund aus der Liste."

      unspecified: "Ohne Angabe"
      keyCompromise: "Schlüssel kompromittiert"
      affiliationChanged: "Änderung der Zugehörigkeit"
      superseded: "Ersetzt"
      cessationOfOperation: "Nicht mehr im Einsatz"
      explanation_0: "Wenn die anderen Gründe nicht zutreffen, müssen Sie den
        Sperrgrund \"Ohne Angabe\" wählen."
      explanation_1: "Sie müssen den Sperrgrund \"Schlüssel kompromittiert\"
        wählen, wenn Sie Grund zu der Annahme haben, dass der private Schlüssel
        Ihres Zertifikats kompromittiert wurde, z. B. wenn eine unbefugte
        Person Zugriff auf den privaten Schlüssel hatte. "
      explanation_3: "Sie sollten den Sperrgrund \"Änderung der Zugehörigkeit\"
        wählen, wenn sich der Name Ihrer Organisation oder andere
        organisatorische Informationen im Zertifikat geändert haben."
      explanation_4: "Sie sollten den Sperrgrund \"Ersetzt\" wählen, wenn
        sie ein neues Zertifikat beantragt haben, um Ihr bestehendes Zertifikat
        zu ersetzen."
      explanation_5: "Sie sollten den Sperrgrund \"Nicht mehr im Einsatz\" wählen,
        wenn Sie nicht mehr im Besitz aller Domainnamen des Zertifikats sind oder
        wenn Sie das Zertifikat nicht mehr verwenden, weil Sie die Website einstellen"

      revoke: "Zertifikat sperren"

      crr_sent: "Ihr Sperrantrag ist eingegangen und wird bearbeitet."
      back: "️️Zurück"
</i18n>

<script>
import { custom } from "@/customization/customization";
import { DFNSOAPClient } from "@/soap/dfnsoapclient";
import { sha1, hexToDec } from "@/pki/dfnpki";
import { Logger } from "@/logger/logger";

const logger = new Logger("RevokeCertificate");

export default {
  name: "RevokeCertificate",
  props: {
    certSerial: { default: null },
  },
  data() {
    return {
      serial: this.certSerial,
      serialState: null,
      serialFeedback: null,
      password: null,
      passwordState: null,
      passwordFeedback: null,
      reason: 0,
      reasonState: true, // feedback bei der Auswahl des Sperrgrunds direkt anzeigen
      success: false, // True, wenn der CRR gesendet wurde.
    };
  },
  computed: {
    reasons() {
      return [
        // diese Zuordnung könnte auch nach pki ausgelagert werden.
        // { value: null, text: this.$t("choose_reason") },
        { value: 0, text: this.$t("unspecified") },
        { value: 3, text: this.$t("affiliationChanged") },
        { value: 1, text: this.$t("keyCompromise") },
        { value: 4, text: this.$t("superseded") },
        { value: 5, text: this.$t("cessationOfOperation") },
      ];
    },
  },
  methods: {
    /**
     * Überprüft die eingegebene Seriennummer und setzt State und Feedback.
     */
    checkSerial() {
      if (!this.serial.match(/^(0[xX])?[0-9a-fA-F][0-9a-fA-F:]+$/)) {
        this.serialFeedback = this.$t("serial_error");
        this.serialState = false;
      } else {
        this.serialFeedback = null;
        this.serialState = true;
      }
    },

    /**
     * Prüft die Inputs, ob sie sinnvolle Eingaben enthalten und setzt den
     * entsprechenden Status sowie das Feedback.
     *
     * @returns true gdw. alle Inputs sinnvolle Eingaben enthalten
     */
    checkInputs() {
      this.checkSerial();
      if (this.password.length < 8) {
        this.passwordFeedback = this.$t("password_too_short");
        this.passwordState = false;
      } else {
        this.passwordFeedback = null;
        this.passwordState = true;
      }
      this.reasonState = this.reason !== null;

      return this.serialState && this.passwordState && this.reasonState;
    },

    /**
     * Überprüft die Inputs und stellt per SOAP einen Sperrantrag.
     */
    async revoke() {
      if (!this.checkInputs()) {
        // Inputs enthalten Fehler, Feedback ist schon gesetzt. --> Abbruch
        logger.error("Inputs enthalten Fehler.");
        return;
      }

      // Seriennummer nach dezimal wandeln, falls nicht schon dezimal.
      var decimalSerial = this.serial;
      if (!decimalSerial.match(/^[0-9]+$/)) {
        decimalSerial = hexToDec(decimalSerial.replace(/^0[xX]|:/g, ""));
      }

      logger.debug("Sperre Zertifikat " + decimalSerial + " / " + this.serial);
      const client = new DFNSOAPClient(custom.pubURL, custom.ra_id);
      try {
        const response = await client.newRevocationRequest(
          decimalSerial,
          this.reason,
          sha1(this.password)
        );
        logger.info("CRR abgeschickt: " + response);
      } catch (e) {
        // Fehler von der SOAP-Schnittstelle
        if (e.message.match(/^Kein Zertifikat mit Seriennummer.+gefunden.$/)) {
          // Falsche Seriennummer
          this.serialFeedback = this.$t("serial_not_found");
          this.serialState = false;
          this.passwordState = null;
        } else if (e.message === "Die PIN stimmt nicht.") {
          // Falsche PIN
          this.passwordFeedback = this.$t("password_error");
          this.passwordState = false;
        } else if (e.message === "Das Zertifikat ist bereits abgelaufen und kann nicht mehr gesperrt werden.") {
          this.serialFeedback = this.$t("certificate_expired");
          this.serialState = false;
          this.passwordState = null;
        } else {
          // Unbekannter Fehler
          logger.error("Unbekannter Fehler: " + e);
          // Damit wir keine Leere Fehlermeldung ausgeben, raten wir, dass PIN
          // oder Seriennummer falsch sind. Siehe #1745.
          this.error(this.$t("wrong_serial_or_password"));
        }
        return;
      }
      // Kein Fehler --> Erfolgsseite anzeigen
      this.success = true;
    },
    /**
     * Setzt serial, password und reason auf 0 und success auf false.
     * Dadurch wird wieder ein leeres Formular angezeigt.
     */
    back() {
      this.success = false;
      this.serial = null;
      this.password = null;
      this.reason = 0;
      this.serialState = null;
      this.passwordState = null;
      this.reasonState = true; // feedback direkt anzeigen
    },
    error(msg) {
      this.$emit("error-message", msg);
    },
  },
};
</script>

<style scoped></style>
