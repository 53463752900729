<template>
  <div class="locale-changer">
    <a>{{ $t("select_language") }}:</a>
    <br />
    <select class="b-nav-item" v-model="locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang.short">
        <span v-html="lang.flag"></span> {{ lang.name }}
      </option>
    </select>
  </div>
</template>

<i18n>
en:
  select_language: "Sprache auswählen"
de:
  select_language: "Select language"
</i18n>

<script>
import { i18n, setLocale } from "@/i18n/i18n";
export default {
  name: "locale-changer",
  data() {
    return {
      langs: [
        {
          name: "Deutsch",
          short: "de",
          flag: "&#x1F1E9;&#x1F1EA;",
        },
        {
          name: "English",
          short: "en",
          flag: "&#x1F1EC;&#x1F1E7;",
        },
      ],
    };
  },
  computed: {
    locale: {
      get() {
        return i18n.locale;
      },
      set(val) {
        setLocale(val);
      },
    },
  },
};
</script>

<style scoped>
a {
  margin-right: 10px;
}

label {
  margin-top: 24px;
  font-size: medium;
}

select {
  font-size: large;
}
</style>
