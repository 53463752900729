var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t("page_title")))]),_c('p',{domProps:{"innerHTML":_vm._s(
      _vm.$t('instructions', {
        download_instructions: _vm.$t(
          _vm.custom.receiptOnly
            ? 'download_instructions_receipt'
            : 'download_instructions_application',
          { pki_name: _vm.pki_name }
        ),
      })
    )}}),_c('DisplayRequest',{attrs:{"request":_vm.request}}),_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('important'))}}),_c('NewPassword',{attrs:{"buttonText":_vm.$t('button_text'),"title":_vm.$t('title'),"label":_vm.$t(_vm.isPKCS10Upload() ? 'label_pkcs10' : 'label')},on:{"newPassword":_vm.submitRequest}})],1),_c('b-modal',{attrs:{"id":"loadingModal","size":"sm","hide-footer":"","hide-header":"","centered":""}},[_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("loading"))+" … "),_c('b-spinner',{attrs:{"small":"","type":"grow","label":"Spinning"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }