import VueI18n from "vue-i18n";
import Vue from "vue";

import { de_or_en } from "@/i18n/locale";
import { messages } from "@/i18n/translations";

Vue.use(VueI18n);

function initLocale() {
  const lang = de_or_en();
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

export const i18n = new VueI18n({
  locale: initLocale(),
  fallback_locale: "de", // used in case of missing translation strings
  messages,
});

export function setLocale(lang) {
  i18n.locale = lang;
  document.querySelector("html").setAttribute("lang", lang);
}
