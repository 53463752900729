/* global require */ // suppress eslint warning: 'require' is not defined
const forge = require("node-forge");

import { checkRDNs } from "@/pki/subject";
import { ALLOWED_RDN_TYPES, ALLOWED_SAN_TYPES } from "@/pki/nameTypes";

/**
 * Erzeugt PKCS10-Requeste. Beispiel:
 * const builder = new RequestBuilder();
 * builder.addRDN('O', 'My Organization');
 * builder.addRDN('CN', 'My Name');
 * const promise = builder.build();
 * promise.then(({request, privateKey}) => doSomething(request, privateKey));
 */
export class RequestBuilder {
  constructor() {
    this.subject = [];
    this.altnames = [];
  }

  /**
   * Setzt das Subject dieses Antrags.
   *
   * @param {type} rdns ein Array mit {type, value}-Paaren, type muss einer der
   * ALLOWED_RDN_TYPES sein
   */
  setSubject(rdns) {
    checkRDNs(rdns);
    this.subject = [];
    for (let rdn in rdns) {
      if (Object.prototype.hasOwnProperty.call(rdns, rdn)) {
        for (let val of rdns[rdn]) {
          this.subject.push({
            type: ALLOWED_RDN_TYPES[rdn].oid,
            //shortName: rdn,
            value: val,
          });
        }
      }
    }
    // console.log("setSubject() this.subject: " + JSON.stringify(this.subject));
  }
  /**
   * Fügt einen RDN zum DN hinzu.
   *
   * @param {String} type ein erlaubter Typ
   * @param {String} value der zugehörige Wert
   */
  //  addRDN (type, value) {
  //        // logger.trace('Füge RDN hinzu: ' + type + ' = ' + value);
  //    if (this.allowedRDNTypes.indexOf(type) === -1) {
  //      throw new Error('RDNs vom Typ ' + type + ' können nicht hinzugefügt werden.')
  //    }
  //    this.subject.push({shortName: type, value: value})
  //  }

  /**
   * Erzeugt einen PKCS10-Antrag aus den vorher gesetzten Daten. Das Erzeugen
   * findet asynchron statt, als Rückgabe gibt es eine Promise. Dem Aufruf von
   * resolve() wird dann ein Objekt {request: String, privateKey: Key} mit
   * PKCS10-Antrag und privatem Schlüssel als PEM übergeben.
   *
   * @returns {Promise} Promise, die resolve() mit Request und Key aufruft
   */
  build() {
    const builder = this;

    return new Promise(function (resolve, reject) {
      const KEY_SIZE = 4096;
      // generate a key pair
      forge.pki.rsa.generateKeyPair(
        { bits: KEY_SIZE, workers: -1 },
        (err, keys) => {
          if (err) {
            // logger.error('Konnte kein Schlüsselpaar erzeugen: ' + err);

            reject(err);
          } else {
            // create a certification request (CSR)
            try {
              const csr = builder.createCSR(keys);
              // convert certification request to PEM-format
              const pkcs10PEM = forge.pki.certificationRequestToPem(csr);
              const privateKey = forge.pki.privateKeyToPem(keys.privateKey);
              resolve({ pkcs10PEM, privateKey });
            } catch (err) {
              reject(err);
            }
          }
        }
      );
    });
  }

  /**
   * Erzeugt einen PKCS10-Antrag auf Basis der gesetzten Daten und dem
   * übergebenen Schlüsselpaar.
   *
   * @param {KeyPair} keys Schlüsselpaar
   * @returns {CSR} Antrag
   */
  createCSR(keys) {
    const csr = forge.pki.createCertificationRequest();
    csr.publicKey = keys.publicKey;
    csr.setSubject(this.subject);
    // set (optional) attributes
    csr.setAttributes([
      {
        name: "extensionRequest",
        extensions: [
          {
            name: "subjectAltName",
            altNames: this.altnames,
          },
        ],
      },
    ]);
    // sign certification request
    csr.sign(keys.privateKey);
    return csr;
  }

  /**
   * Setzt die Altnames.
   *
   * @param {Array} altnames Array mit type-value-Paaren
   */
  setAltnames(altnames) {
    const { email, Microsoft_UPN, URI } = ALLOWED_SAN_TYPES;
    this.altnames = [];

    for (let san of altnames) {
      switch (san.type) {
        case email:
        case URI:
          this.altnames.push({ type: san.type, value: san.value });
          break;
        case Microsoft_UPN:
          /** Dieser Fall ist noch nicht implementiert. Typ 0 steht für
           * 'otherName' und muss noch auf unbekannte Weise genauer
           * spezifiziert werden. Die zugehörige OID ist 1.3.6.1.4.1.311.20.2.3.
           * Vielleicht muss die verwendet werden.
           */
          break;
        default:
          throw new Error("Unbekannter SAN-Typ: " + JSON.stringify(san.type));
        // case IP: // Sonderfall: ip statt value verwenden!
        //  this.altnames.push({ type: san.type, ip: san.value });
      }
    }
  }
}
