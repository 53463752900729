<template>
  <div>
    <!-- TODO: Spaltenabstand ist zwischen certDataItems und userDataItems unterschiedlich -->
    <h2>{{ $t("certificate_data") }}</h2>
    <b-table
      :items="certDataItems"
      small
      fixed
      borderless
      striped
      thead-class="hidden_header"
    ></b-table>

    <h2>{{ $t("additional_data") }}</h2>
    <b-table
      :items="userDataItems"
      small
      fixed
      borderless
      striped
      thead-class="hidden_header"
    ></b-table>
  </div>
</template>

<i18n>
    en:
      certificate_data: "Certificate data"
      CN: "Common Name (CN)"
      E: "Email"
      GN: "Given Name (GN)"
      SN: "Surname (SN)"
      pseudonym: "Pseudonym (pseudonym)"
      OU: "Organisational Unit (OU)"
      O: "Organisation (O)"
      L: "Location (L)"
      ST: "State (ST)"
      C: "Country (C)"
      email: "Email"
      additional_data: "Additional data"
      role: "Certificate profile"
      addName: "Name"
      addEmail: "Email"
      addOrg: "Department"
      publish: "Publish"
      publish_value: "Your certificate will {not}be published."
      not: "not "
      date: "Date"
      comment: "Personal note"
      empty_comment: "not available"
      Microsoft_UPN: "Microsoft UserPrincipalName (UPN)"
      URI: "URI"
      IP: "IP"
      DNS: "DNS"
    de:
      certificate_data: "Zertifikatsdaten"
      CN: "Name (CN)"
      GN: "Vorname (GN)"
      SN: "Nachname (SN)"
      pseudonym: "Pseudonym (pseudonym)"
      E: "E-Mail"
      OU: "Organisationseinheit (OU)"
      O: "Organisation (O)"
      L: "Standort (L)"
      ST: "Bundesland (ST)"
      C: "Land (C)"
      email: "E-Mail"
      additional_data: "Zusätzliche Daten"
      role: "Zertifikatsprofil"
      addName: "Name"
      addEmail: "E-Mail"
      addOrg: "Abteilung"
      publish: "Veröffentlichen"
      publish_value: "Ihr Zertifikat wird {not}veröffentlicht."
      not: "nicht "
      date: "Datum"
      comment: "Persönliche Notiz"
      empty_comment: "keine persönliche Notiz vorhanden"
      Microsoft_UPN: "Microsoft UserPrincipalName (UPN)"
      URI: "URI"
      IP: "IP"
      DNS: "DNS"
</i18n>

<script>
import { ALLOWED_SAN_TYPES } from "@/pki/nameTypes";

export default {
  name: "DisplayRequest",
  computed: {
    certDataItems: function () {
      let certItems = [];
      const subject = this.request.subject;
      for (let type in this.request.subject) {
        if (Object.prototype.hasOwnProperty.call(subject, type)) {
          certItems = certItems.concat(
            subject[type].map((value) => ({
              key: this.$t(type), // TODO
              value: value,
            }))
          );
        }
      }
      for (let san of this.request.altnames) {
        certItems.push({
          key: this.$t(san.type.shortName),
          value: san.value,
        });
      }
      return certItems;
    },
    userDataItems: function () {
      const userDataItems = [];
      // role, addName, addEmail und addOrg kommen nur in die Tabelle, wenn gesetzt.
      for (let key of ["role", "addName", "addEmail", "addOrg"]) {
        if (this.request[key]) {
          userDataItems.push({ key: this.$t(key), value: this.request[key] });
        }
      }
      // publish, date und comment werden angehängt und alles zurückgegeben.
      return userDataItems.concat([
        {
          key: this.$t("publish"),
          value: this.$t("publish_value", {
            // Bei unveröffentlichten Zertifikaten wird ein "nicht" eingefügt.
            not: this.request["publish"] ? "" : this.$t("not"),
          }),
        },
        {
          key: this.$t("date"),
          value: this.request["date"].toLocaleDateString(this.$i18n.locale),
        },
        {
          key: this.$t("comment"),
          value:
            this.request.comment.trim() === ""
              ? "(" + this.$t("empty_comment") + ")"
              : this.request.comment,
        },
      ]);
    },
  },
  props: {
    request: { type: Object, required: true },
  },
  methods: {},
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>
