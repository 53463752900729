import { ALLOWED_RDN_TYPES } from "@/pki/nameTypes";
/**
 * Kapselt Informationen über einen DN.
 *
 * Die Felder einer Subject-Instanz sind benannt nach den erlaubten RDN-Typen.
 * Das heißt, in einer Instanz subject enthält das Feld subject.CN ein Array mit
 * allen CNs.
 */
export class Subject {
  constructor(rdnsInWenjaFormat) {
    for (let type in ALLOWED_RDN_TYPES) {
      type = ALLOWED_RDN_TYPES[type];
      if (
        Object.prototype.hasOwnProperty.call(rdnsInWenjaFormat, type.shortName)
      ) {
        this[type.shortName] = rdnsInWenjaFormat[type.shortName];
        checkRDN(type.shortName, this[type.shortName]);
      }
    }
  }

  /**
   * Erzeugt ein neues Subject aus einem Array mit type-value-Paaren.
   *
   * Beispiel: Subject.fromTypeValueArray([{type: "CN", value: "Tester"}]) gibt
   * ein Subject mit {CN: ["Tester"]}.
   *
   * @param {Array} rdns Array mit type-value-Paaren
   */
  static fromTypeValueArray(rdns) {
    const dict = {}; // Ergebnis
    for (let rdn of rdns) {
      const type = typeof rdn.type === "string" ? rdn.type : rdn.type.shortName;
      const value = rdn.value;
      if (Object.prototype.hasOwnProperty.call(dict, type)) {
        dict[type].push(value);
      } else {
        dict[type] = [value];
      }
    }
    return new Subject(dict);
  }

  /**
   * Gibt eine Stringdarstellung des Subjects mit RDNsToString().
   */
  toString() {
    return RDNsToString(this);
  }

  /**
   * Gibt alle CNs kommasepariert.
   *
   * Wirft einen Fehler, falls kein CN vorhanden ist.
   *
   * @returns {String} alle CNs kommasepariert
   */
  getCN() {
    if (Object.prototype.hasOwnProperty.call(this, "CN")) {
      return this.CN.join(", ");
    }
    throw Error("Kein CN im Subject " + this.toString() + " vorhanden.");
  }
}

function compareRDNTypes(type1, type2) {
  const keys = Object.keys(ALLOWED_RDN_TYPES);
  return keys.indexOf(type1.shortName) - keys.indexOf(type2.shortName);
}

/**
 * Vergleicht zwei RDNs. Gibt eine negative Zahl, wenn der erste RDN vor dem
 * zweiten steht, eine positive, wenn der zweite vor den ersten gehört und eine
 * 0, wenn beide RDNs den selben Typ haben.
 *
 * Beispiel: compareRDNs(cn, o) = -2
 *
 * @param {RDN} rdn1 erster RDN
 * @param {RDN} rdn2 zweiter RDN
 * @returns {Number} negativ für in Reihenfolge, 0 für gleich, sonst positiv
 */
export function compareRDNs(rdn1, rdn2) {
  return compareRDNTypes(rdn1.type, rdn2.type);
}

/**
 * Bringt ein Array mit RDNs in die richtige Reihenfolge.
 * Die Reihenfolge wird vorgegeben durch compareRDNs().
 *
 * Achtung, das übergebene Array wird verändert.
 *
 * @param {RDN[]} rdns zu sortierendes RDN-Array
 */
export function sortRDNs(rdns) {
  checkRDNs(rdns);
  rdns.sort(compareRDNs); // sort() ist eventuell nicht stabil.
}

/**
 * Wandelt ein Object mit RDNs in eine Stringdarstellung um.
 *
 * Die Darstellung entspricht der OpenSSL-Option
 * -nameopt RFC2253,sep_comma_plus_space (wobei nicht sortiert wird).
 *
 * @param {type} rdns Object mit RDNs
 * @returns {String} DN mit kommaseparierten RDNs
 */
export function RDNsToString(rdns) {
  checkRDNs(rdns);

  let rdnsAsString = "";
  for (let type in rdns) {
    if (Object.prototype.hasOwnProperty.call(rdns, type)) {
      rdnsAsString +=
        rdns[type].map((value) => type + "=" + value).join(", ") + ", ";
    }
  }

  return rdnsAsString.slice(0, -2);
}

/**
 * Prüft, ob das übergebene Objekt ein RDN im Wenja-Format ist. Es wird lediglich die
 * Objektstruktur geprüft, nicht die Gültigkeit des Wertes!
 * Um ein gültiger RDN im Wenja-Format zu sein, müssen
 * - alle Schlüssel im RDN Teil der ALLOWED_RDN_TYPES sein
 * - die Values der Schlüssel Arrays sein
 * - die Objekte im Array Strings sein
 *
 * Bei einem Fehler wird ein TypeError geworfen. Die Fehlermeldung ist nicht für
 * Anwender bestimmt! Der Fehler sollte nicht zum Browser durchschlagen, sondern
 * vorher abgefangen werden.
 *
 * @param {type} type der zu prüfende ALLOWED_RDN_TYPES
 * @param {Array} value die zugeordneten Werte für den ALLOWED_RDN_TYPES value
 * @throws {TypeError} wenn die Prüfung fehlschlägt
 */
export function checkRDN(type, value) {
  if (type === null || value === null) {
    throw TypeError("type oder value ist null");
  }
  if (type === undefined || value === undefined) {
    throw TypeError("type oder value ist undefined");
  }
  if (typeof type !== "string") {
    throw TypeError("type ist kein String");
  }
  if (!Object.prototype.hasOwnProperty.call(ALLOWED_RDN_TYPES, type)) {
    throw TypeError(
      "Type " + JSON.stringify(type) + " ist nicht in den ALLOWED_RDN_TYPES"
    );
  }
  if (value.constructor !== Array) {
    throw TypeError("Wert von Type " + value + " ist kein Array");
  }
  const pattern = ALLOWED_RDN_TYPES[type].pattern;
  const length = ALLOWED_RDN_TYPES[type].maxLength;
  for (let val of value) {
    if (typeof val !== "string") {
      throw TypeError(
        "Ein Wert von Values " + JSON.stringify(value) + " ist kein String"
      );
    }
    if (val.match(pattern) === null) {
      throw TypeError(
        "Der Wert " +
          JSON.stringify(val) +
          " passt nicht auf das Muster " +
          pattern +
          " des Typs " +
          type
      );
    }
    if (val.length > length) {
      throw TypeError(
        "Der Wert " + JSON.stringify(val) + " ist zu lang für den Typ " + type
      );
    }
  }
}

/**
 * Ruft checkRDN für alle RDNs im gegebenen Array auf.
 *
 * @param {type} rdns das zu prüfende Array
 * @throws {TypeError} wenn die Prüfung fehlschlägt
 */
export function checkRDNs(rdns) {
  if (rdns === null) {
    throw TypeError("RDNs ist null");
  }
  if (rdns === undefined) {
    throw TypeError("RDNs ist undefined");
  }
  if (
    rdns.constructor !== Array &&
    rdns.constructor !== Object &&
    rdns.constructor !== Subject
  ) {
    throw TypeError(
      "RDNs " + JSON.stringify(rdns) + " ist kein Array oder Object"
    );
  }
  /*
  if (rdns.constructor !== Object) {
    throw TypeError("RDNs " + JSON.stringify(rdns) + " ist kein Object");
  }
   */
  for (let type in rdns) {
    checkRDN(type, rdns[type]);
  }
}

/**
 * Gibt für einen DN in Stringdarstellung ein Objekt mit Type=>[Values] aus.
 *
 * Für jeden RDN-Typ hat das Ergenisobjekt ein Feld dessen Wert eine Liste mit
 * allen Werten diesen Typs ist.
 * Beispiel: Die Eingabe "CN=cn1, CN=cn2, O=o" ergibt das Objekt
 * { CN: ["cn1", "cn2"], O: ["o"] }.
 *
 * @param {String} dn der DN als String nach RFC2253
 */
export function getDNInWenjaFormatFromString(dn) {
  if (typeof dn !== "string") {
    throw TypeError(
      "getDNInWenjaFormatFromString erwartet String: " + JSON.stringify(dn)
    );
  }
  // Grober Test, ob die Eingabe überhaupt aussieht wie ein DN
  if (!dn.match(/^([a-zA-Z]+ *=[^=+]+)(, *[a-zA-Z]+ *=[^=+]+)*$/)) {
    throw TypeError("Eingabe sieht nicht aus wie ein DN: " + dn);
  }

  const dict = {}; // Ergebnis

  // Split an Kommas, denen noch Buchstaben und ein Gleichheitszeichen folgt.
  const pairs = dn.split(/, *(?=[a-zA-Z]+ *=)/); // Bsp: pairs = ["CN=name", "O=o"]

  for (let pair of pairs) {
    // Jedes Type-Value-Paar wird am Gleichheitszeichen gesplittet.
    const parts = pair.split(/ *= */);
    const type = parts[0]; // Bsp: type = "CN", value = "name"
    const value = parts[1].replace(/\\([\\,])/g, "$1");

    if (Object.prototype.hasOwnProperty.call(dict, type)) {
      dict[type].push(value);
    } else {
      dict[type] = [value];
    }
  }
  return dict;
}
