/**
 * Hier wird der Router eingerichtet. Insbesondere wird hier das Mapping von
 * URL-Pfaden auf anzuzeigende Vue-Komponenten festgelegt.
 *
 * Die hier angelegten Pfade sind nicht zwangsläufig irgendwo verlinkt.
 * Beispielsweise soll die Logkomponente vom normalen Benutzer nicht zu sehen
 * sein.
 *
 * Um einen Link anzulegen, importiert man einen der in names.js definierten
 * Namen und verlinkt dann mit einem <router-link>-Tag.
 *
 * Da hier alle Komponenten importiert werden, dürfen die Komponenten selbst
 * nicht den Router importieren, sonst gäbe es zirkuläre Abhängigkeiten. Das ist
 * auch der Grund dafür, dass die Pfade ausgelagert sind.
 */
import VueRouter from "vue-router";

import ExamplePage from "@/components/pages/developer/ExamplePage";
import LogView from "@/components/pages/developer/LogView";
import NewRequest from "@/components/pages/new-certificate/NewRequest";
import StartPage from "@/components/pages/StartPage";
import RootPage from "@/components/pages/RootPage";
import ErrorPage404 from "@/components/pages/ErrorPage404";
import RetrieveCertificate from "@/components/pages/retrieve-certificate/RetrieveCertificate";
import RevokeCertificate from "@/components/pages/revoke-certificate/RevokeCertificate";
import ConfirmRequest from "@/components/pages/new-certificate/ConfirmRequest";
import CAInformation from "@/components/pages/ca-information/CAInformation.vue";

import { Logger } from "@/logger/logger";
import { i18n } from "@/i18n/i18n";

const log = new Logger("router.js");

import { brand } from "@/brand/brand";
import { custom } from "@/customization/customization";
import * as names from "./names";
import { CERT_TYPES } from "../pki/certTypes";

// Seitentitel werden von den Routen bestimmt, der DEFAULT_TITLE wird
// angezeigt, wenn kein Titel definiert wurde.
const DEFAULT_TITLE = brand.pkiName;

// Mapping von Pfaden auf Komponenten
const routes = [
  {
    path: brand.basePath + ":ca/:raid",
    component: RootPage,
    props: true,
    children: [
      {
        path: "example",
        component: ExamplePage,
        name: names.EXAMPLE,
        meta: { title: "examplePage" },
      },
      {
        path: "",
        component: StartPage,
        name: names.HOME,
        meta: { title: "start" },
      },
      {
        path: "certificates/new/:step?",
        component: NewRequest,
        name: names.NEW_REQUEST,
        meta: { title: "newCert" },
        props: (route) => ({
          certType: CERT_TYPES.user,
          step: parseInt(route.params.step),
        }),
      },
      {
        path: "certificates/new/pn/:step?",
        component: NewRequest,
        name: names.NEW_REQUEST_PN,
        meta: { title: "newCertPseudoym" },
        props: (route) => ({
          certType: CERT_TYPES.pseudonym,
          step: parseInt(route.params.step),
        }),
      },
      {
        path: "certificates/new/group/:step?",
        component: NewRequest,
        name: names.NEW_REQUEST_GROUP,
        meta: { title: "newCertGroup" },
        props: (route) => ({
          certType: CERT_TYPES.group,
          step: parseInt(route.params.step),
        }),
      },
      {
        path: "certificates/new/server/:step?",
        component: NewRequest,
        name: names.NEW_REQUEST_SERVER,
        meta: { title: "newCertServer" },
        props: (route) => ({
          certType: CERT_TYPES.server,
          step: parseInt(route.params.step),
        }),
      },
      {
        path: "certificates/new/pkcs10/:step?",
        component: NewRequest,
        name: names.NEW_REQUEST_PKCS10,
        meta: { title: "uploadPKCS10" },
        props: (route) => ({
          certType: CERT_TYPES.pkcs10,
          step: parseInt(route.params.step),
        }),
      },
      {
        path: "log",
        component: LogView,
        name: names.LOG,
        meta: { title: "log" },
      },
      {
        path: "certificates/:retrieveRequestSerial?",
        component: RetrieveCertificate,
        name: names.RETRIEVE_CERTIFICATE,
        meta: { title: "retrieveCert" },
        props: true,
      },
      {
        path: "certificates/retrieve/save",
        component: RetrieveCertificate,
        name: names.RETRIEVE_CERTIFICATE_SAVE,
        meta: { title: "saveCert" },
        props: { step: 2 },
      },
      {
        path: "certificates/retrieve/done",
        component: RetrieveCertificate,
        name: names.RETRIEVE_CERTIFICATE_DONE,
        meta: { title: "doneCert" },
        props: { step: 3 },
      },
      {
        path: "revoke/:certSerial?",
        component: RevokeCertificate,
        name: names.REVOKE_CERTIFICATE,
        meta: { title: "revokeCert" },
        props: true,
      },
      {
        path: "unknown",
        component: ErrorPage404,
        name: names.UNKNOWN,
        meta: { title: "error" },
      },
      {
        path: "confirmrequest",
        component: ConfirmRequest,
        name: names.CONFIRM_REQUEST,
        meta: { title: "confirmRequest" },
      },
      {
        path: "cainformation",
        component: CAInformation,
        name: names.CA_INFOS,
        meta: { title: "cainformation" },
      },
    ],
  },
];

// Create the router instance and pass the `routes` option
export const router = new VueRouter({
  // scroll to top for each route navigations
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes,
  mode: "history",
});

// Setzt den Seitentitel bei jedem Seitenwechsel.
// Prüft, ob Unlock nötig ist.
router.beforeEach((to, from, next) => {
  // Da der windowTitle asynchron aus der Customization geholt wird, ist er
  // gegebenenfalls noch nicht beim ersten Seitenaufruf vorhanden.
  var title;
  if (custom != null) {
    title = custom.windowTitle;
  } else {
    title = DEFAULT_TITLE;
    log.info("Kein windowTitle in customization benutze " + DEFAULT_TITLE);
  }

  // Setze Seitentitel in aktueller Sprache
  // Prüfe ob ein Seitentitel gesetzt wurde
  if (to.meta.title) {
    // Prüfe ob eine Übersetzung für den Seitentitel existiert
    if (i18n.te(`message.${to.meta.title}`)) {
      title += " - " + i18n.t(`message.${to.meta.title}`);
    } else {
      // Fallback falls keine Übersetzung vorhanden ist
      title += " - " + to.meta.title;
    }
  }

  log.info(
    "Neue Seite - name: " + to.name + ", path: " + to.path + ", title: " + title
  );

  document.title = title;

  next();
});
