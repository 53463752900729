<!--
Diese Komponente zeigt eine Tabelle mit Informationen über ein Zertifikat.

Welche Informationen gezeigt werden, ist in Ticket #1786 festgehalten.

Aktuell werden die Daten über ein großes Objekt certData als Prop mitgeliefert.
Das sollte sinnvollerweise eher nur das Zertifikat und der zugehörige Antrag
sein, so dass hier die Daten aus beidem extrahiert werden.
-->
<template>
  <div>
    <b-table
      :items="certDataItems"
      small
      fixed
      borderless
      striped
      thead-class="hidden_header"
    ></b-table>
  </div>
</template>

<i18n>
    en:
      subject: "Name of certificate owner"
      window_title: "Subscriber-Service"
      issuer: "Name of certificate issuer"
      notbefore: "Valid from"
      notafter: "Valid until"
      serial: "Certificate serial number"
      request_date: "Certificate application date"
      comment: "Personal note"
      request_serial: "Applicationnumber"
      altnames: "Alternative Names"
    de:
      subject: "Name des Zertifikatinhabers"
      window_title: "Teilnehmerservice"
      issuer: "Name des Zertifikatsausstellers"
      notbefore: "Gültig ab"
      notafter: "Gültig bis"
      serial: "Zertifikatsseriennummer"
      request_date: "Antrag vom"
      comment: "Persönliche Notiz"
      request_serial: "Antragsnummer"
      altnames: "Alternative Namen"
</i18n>

<script>
import { custom } from "@/customization/customization";
import { parseCert } from "@/pki/dfnpki";

export default {
  computed: {
    certDataItems() {
      const certData = parseCert(this.certPEM);
      const request = this.request;
      let certItems = [
        { key: this.$t("subject"), value: certData.subject },
        {
          key: this.$t("window_title"),
          value: custom.windowTitle,
        },
        {
          key: this.$t("altnames"),
          value: certData.altnames
            .map((san) => san.type.shortName + ": " + san.value)
            .join(", "),
        },
        { key: this.$t("issuer"), value: certData.issuer },
        {
          key: this.$t("notbefore"),
          value: certData.notBefore.toLocaleDateString(this.$i18n.locale),
        },
        {
          key: this.$t("notafter"),
          value: certData.notAfter.toLocaleDateString(this.$i18n.locale),
        },
        { key: this.$t("serial"), value: certData.certSerial },
        {
          key: this.$t("request_date"),
          value: request.date.toLocaleDateString(this.$i18n.locale),
        },
        { key: this.$t("comment"), value: request.comment },
        { key: this.$t("request_serial"), value: request.serial },
      ];
      //const subject = this.certData.subject;

      return certItems;
    },
  },
  props: {
    certPEM: { type: String, required: true },
    request: { type: Object, required: true },
  },
  methods: {},
};
</script>

<style scoped>
.hidden_header {
  display: none;
}
</style>
