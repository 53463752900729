<!--
RootPage direkt unterhalb von App.vue. Hier stehen das erste Mal die vom Router
übergebenen Properties ca und raid zur Verfügung. Darum wird hier die
Customization geholt. Erst wenn diese da ist, werden Pageheader und router-view
eingebunden, weil sowohl der Header als auch die Seiten in der router-view von
ihr abhängen.
-->
<template>
  <div>
    <page-header v-if="customizationAvailable"></page-header>
    <app-nav id="main-nav" v-if="customizationAvailable"></app-nav>
    <page-footer v-if="customizationAvailable"></page-footer>

    <main id="page-wrap">
      <main class="container" id="content-layer">
        <error-modal ref="messages"></error-modal>
        <!--
          Als Key für <router-view> dient der Name der Route. Bei jeder Änderung
          der Route, bei der ein anderer Name verwendet wird, gibt es dadurch
          einen Reset der aufgerufenen Komponente.

          Fieser Hack: Damit zwischen retrieve-certificate und
          retrieve-certificate-save/done kein Reset passiert, wird das save/done
          abgeschnitten.
        -->
        <router-view
          v-if="customizationAvailable"
          @error-message="errorMessage"
          :key="$route.name.replace(/-(save|done)$/, '')"
        ></router-view>
        <div v-else>
          <error-page404 v-if="error"></error-page404>
          <b-spinner v-else></b-spinner>
        </div>
      </main>
    </main>
  </div>
</template>

<script>
import { Logger } from "@/logger/logger";
import { fetch } from "@/customization/customization";
import ErrorModal from "@/components/ErrorModal.vue";
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import ErrorPage404 from "@/components/pages/ErrorPage404";
import AppNav from "@/components/AppNav";
//import PageFooter from '../PageFooter.vue';

const log = new Logger("RootPage");
log.debug("Entering RootPage");

export default {
  props: ["ca", "raid"],
  components: { PageHeader, PageFooter, AppNav, ErrorModal, ErrorPage404 },
  data() {
    return {
      customizationAvailable: false,
      error: false,
    };
  },
  /**
   * Holt die Customization und setzt im Erfolgsfall customizationAvailable auf
   * true. Im Fehlerfall wird error auf true gesetzt.
   */
  async beforeMount() {
    const caInfo = "CA: '" + this.ca + "', RA-ID: '" + this.raid + "'";
    try {
      await fetch(this.ca, this.raid);
    } catch (e) {
      // TODO: Anhand e.response.status Fehler genauer unterscheiden. (404/503)
      log.error("Fehler beim Holen der Customization für " + caInfo);
      log.error(JSON.stringify(e, null, 2));
      this.error = true;
      return;
    }
    // custom.windowTitle = "blubb"; // Fehler
    this.customizationAvailable = true;
    log.debug("RootPage mounted mit " + caInfo);
  },
  methods: {
    errorMessage(msg) {
      // Sendet Fehlermeldungen weiter an App.vue.
      this.$refs.messages.error(msg);
    },
  },
  errorCaptured(err, vm, info) {
    this.errorMessage(
      this.$t("capturedErrorMsg", this.$i18n.locale) +
        "\n\n" +
        this.$t("errorDetails", this.$i18n.locale) +
        "\n" +
        err.toString() +
        "\nKomponente: " +
        info
    );
    return false;
  },
};
</script>

<i18n>
en:
  capturedErrorMsg: "An unexpected error occured within the webbrowser during processing or rendering of this webpage."
  error: "Error"
  errorDetails: "Error Details: "
de:
  capturedErrorMsg: "Es ist leider ein unerwarteter Fehler bei der Verarbeitung und Anzeige dieser Webseite im Browser aufgetreten."
  error: "Fehler"
  errorDetails: "Fehlerbeschreibung: "
</i18n>

<style scoped>
#content-layer {
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 190px;
  margin-bottom: 50px;
}
</style>
