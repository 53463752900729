<template>
  <div>
    <!-- Freigeschaltete Domains -->
    <DomainDropdown :server="showDomains" :email="showDomains"></DomainDropdown>
    <br />

    <!-- Dateiupload -->
    <b-form-group
      :label="$t('file_label')"
      :description="$t('file_description')"
      :invalid-feedback="
        displayAllFeedback && fileState === null
          ? $t('no_file_selected')
          : fileFeedback
      "
      :valid-feedback="fileFeedback"
      :state="displayAllFeedback && fileState === null ? false : fileState"
    >
      <b-form-file
        v-model="file"
        accept=".pem, .req, .csr, .p10"
        :state="displayAllFeedback && fileState === null ? false : fileState"
        :placeholder="$t('file_placeholder')"
        :drop_placeholder="$t('file_placeholder')"
        @input="uploadPKCS10"
      ></b-form-file>
    </b-form-group>

    <!-- DN-Präfixe -->
    <b-dropdown
      variant="primary"
      id="prefixes"
      :text="
        $t(
          showOnlySTLPrefixesForServer
            ? 'available_dn_prefixes_stl'
            : 'available_dn_prefixes'
        )
      "
    >
      <b-dropdown-item-button v-for="prefix in dnPrefixes" :key="prefix">
        {{ prefix }}
      </b-dropdown-item-button>
    </b-dropdown>
    <br />
  </div>
</template>

<i18n>
    // Die i18n-Strings sind in der Reihenfolge aufgeführt, in der sie der Benutzer zu sehen bekommt:
    // Strings, die noch nicht abgenommen wurden, sind mit einem Warndreieck markiert:
    en:
      available_dn_prefixes: "The subject DN in your CSR must end with one of the following strings:"
      available_dn_prefixes_stl: "The subject DN in your CSR must end with one of the following strings. For server certificates, only the variants with L and ST attributes may be used from this list:"
      file_label: "To upload your own CSR (PKCS#10) file you must have generated it locally, e.g. with openssl."
      file_description: "Your existing CSR (PKCS#10) file in PEM format. Commonly used file exentions are .pem and .csr."
      file_placeholder: "CSR (PKCS#10) file (e.g. .pem)"
      no_file_selected: "Please select your CSR file (PKCS#10) for uploading."
      parse_error: "The file you provided could not be parsed."
      syntax_error: "The value '{value}' is not allowed for item {type}."
      maxlength_exceeded: "The value '{value}' is too long for item {type}. Maximum length allowed for {type} is {maxLength} characters."
      no_cn: "The Subject Distinguished Name (subjectDN, sDN) in the CSR contains no Common Name (CN) attribute."
      parsed_pem: "CSR for {CN}"
      upload: "upload CSR"
    de:
      available_dn_prefixes: "Der Subject-DN in Ihrem Zertifikatantrag muss auf einen der folgenden Namen enden:"
      available_dn_prefixes_stl: "Der Subject-DN in Ihrem Zertifikatantrag muss auf einen der folgenden Namen enden. Für Serverzertifikate dürfen aus dieser Liste nur die Varianten mit L- und ST-Attributen verwendet werden:"
      file_label: "Um einen CSR (PKCS10) einreichen zu können, müssen Sie diesen vorher erstellt haben, z.B. mit openssl."
      file_description: "Ihr vorhandener CSR (PKCS#10) im PEM-Format. Gebräuchliche Dateiendungen sind .pem und .csr."
      file_placeholder: "CSR-Datei (PKCS#10, z.B. .pem)"
      no_file_selected: "Bitte wählen Sie Ihre CSR-Datei (PKCS#10) zum Hochladen aus."
      parse_error: "Der CSR konnte nicht eingelesen werden."
      syntax_error: "Der Wert '{value}' ist im Eintrag {type} nicht erlaubt."
      maxlength_exceeded: "Der Wert '{value}' im Eintrag {type} ist zu lang. Es sind maximal {maxLength} Zeichen erlaubt."
      no_cn: "Der Zertifikatname (Subject Distinguished Name, subjectDN, sDN) im CSR enthält kein Common Name (CN) Attribut."
      parsed_pem: "CSR für {CN}"
      upload: "CSR hochladen"
</i18n>

<script>
import { custom } from "@/customization/customization";
import { Logger } from "@/logger/logger";
import { extractPEM, parsePKCS10 } from "@/pki/dfnpki";
import { Subject } from "@/pki/subject";
import DomainDropdown from "@/components/pages/new-certificate/DomainDropdown";

const logger = new Logger("Pkcs10Upload");

export default {
  name: "Pkcs10Upload",
  components: { DomainDropdown },
  data() {
    return {
      dnPrefixes: custom.dnPrefixes,
      file: null,
      fileAsText: null,
      fileState: null,
      fileFeedback: "",
      showDomains: custom.policy.newRequest.showDomains,
      showOnlySTLPrefixesForServer:
        custom.policy.newRequest.showOnlySTLPrefixesForServer,
    };
  },
  props: {
    value: {},
    displayAllFeedback: { type: Boolean, required: true },
  },
  methods: {
    uploadPKCS10() {
      const file = this.file;
      if (!file) {
        throw new Error("keine PKCS#10-Datei ausgewählt.");
      }
      logger.info("Lese Datei " + file.name);

      const reader = new FileReader();

      reader.onload = (e) => this.parseFile(e.target.result);
      reader.readAsText(file);
    },
    /**
     * Zeigt eine Fehlermeldung.
     *
     * Setzt den fileState auf false und zeigt den in msg (optional mit params)
     * angegebenen i18n-String als Fehlermeldung in fileFeedback.
     * Außerdem wird null an die Elternkomponente emittet.
     */
    setError(msg, params) {
      this.fileState = false;
      this.fileFeedback = this.$t(msg, params);
      this.$emit("input", null);
    },
    /**
     * Prüft Syntax und Länge des Übergebenen Type-Value-Paares.
     *
     * Bei einem Fehler wird eine Fehlermeldung gesetzt und false zurückgegeben.
     */
    checkName(type, value) {
      if (!type.pattern.test(value)) {
        this.setError("syntax_error", { type: type.shortName, value: value });
        return false;
      }
      if (value.length > type.maxLength) {
        this.setError("maxlength_exceeded", {
          type: type.shortName,
          value: value,
          maxLength: type.maxLength,
        });
        return false;
      }
      logger.trace(type.shortName + " mit Wert " + value + " ok.");
      return true;
    },
    parseFile(content) {
      var pem;
      var requestData;
      try {
        pem = extractPEM("Inhalt der PKCS10-Datei", content);
        requestData = parsePKCS10(pem);
      } catch (e) {
        logger.error("Konnte Datei nicht parsen: " + e);
        this.setError("parse_error");
        return;
      }
      // Antrag erfolgreich eingelesen --> Syntaxprüfung für DN und Altnames
      for (let name of requestData.rdns.concat(requestData.altnames)) {
        const nameOk = this.checkName(name.type, name.value);
        if (!nameOk) {
          return;
        }
      }
      // Syntaxprüfung bestanden
      const subject = Subject.fromTypeValueArray(requestData.rdns);
      const altnames = requestData.altnames;
      logger.debug("Antrag eingelesen. DN: " + subject);

      this.fileState = true;
      try {
        this.fileFeedback = this.$t("parsed_pem", { CN: subject.getCN() });
      } catch (e) {
        this.setError("no_cn");
        return;
      }

      // PEM, Subject und Altnames an NewRequest.vue übergeben.
      this.$emit("input", { pem: pem, subject: subject, altnames: altnames });
    },
  },
};
</script>
<style scoped>
#prefixes {
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
