import { Logger } from "@/logger/logger";
const logger = new Logger("locale.js");

// Return: 'de' if the browsers locale starts with 'de',
//         'en' else
export function de_or_en() {
  var result = "en";
  if (navigator.language.substr(0, 2) === "de") {
    result = "de";
  }
  logger.debug("de_or_en() -> " + result);
  return result;
}
