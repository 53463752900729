<!--
Formular für die Zertifikatsdaten eines neuen Zertifikatantrags.
-->
<template>
  <form>
    <h2>{{ $t("title2") }}</h2>
    <p>{{ $t("hint") }}</p>
    <p>{{ $t("mandatory_inputs") }}</p>
    <DomainDropdown
      :server="showServerDomains"
      :email="showEmailDomains"
    ></DomainDropdown>
    <!-- Inputs für alle Inputobjects -->
    <div v-for="inputObject in allInputs" :key="inputObject.id">
      <b-container fluid="md" class="px-0">
        <p></p>
        <b-row no-gutters align-h="start">
          <label :for="inputObject.id"
            >{{ inputObject.label[$i18n.locale] }}
            {{ inputObject.optional ? "" : "*" }}
          </label>
        </b-row>
        <b-row no-gutters align-h="start">
          <div v-if="inputObject.type.shortName == 'GN'">
            <b-form-checkbox
              id="checkbox-GN"
              v-model="noGivenName"
              name="checkbox-1GN"
              aria-describedby="input-true input-false input-help"
              @change="
                clearGN();
                setDerivedCN();
                submitData();
              "
              @input="setDerivedCN()"
            >
              <p>{{ $t("noGNText") }}</p>
            </b-form-checkbox>
          </div>
        </b-row>
        <b-row no-gutters align-h="start">
          <b-input-group>
            <!-- Das Select wird nur bei certTypes.server angezeigt.
              Die Optionen sind für CN und SAN unterschiedlich. -->
            <b-col
              v-if="
                certType == certTypes.server &&
                (inputObject.type == SERVER_DOMAIN_TYPE ||
                  inputObject.type == sanTypes.IPAddress)
              "
              cols="4"
              class="px-0"
            >
              <b-form-select
                v-model="inputObject.type"
                :options="
                  serverCNoptions.some((o) => o.value == inputObject.type)
                    ? serverCNoptions
                    : serverSANoptions
                "
                @change="displayFeedback(inputObject)"
              ></b-form-select>
            </b-col>
            <b-col class="px-0">
              <b-form-input
                :id="inputObject.id"
                type="text"
                v-model="inputObject.value"
                :disabled="
                  (inputObject.type === rdnTypes.GN && noGivenName) ||
                  (inputObject.type === rdnTypes.CN &&
                    cnInputDisabled &&
                    !noGNSN)
                "
                :state="
                  displayAllFeedback
                    ? !inputHasError(inputObject)
                    : inputObject.state
                "
                :maxlength="inputObject.type.maxLength"
                aria-describedby="input-true input-false input-help"
                :placeholder="inputObject.placeholder[$i18n.locale]"
                trim
                @change="
                  setDerivedCN();
                  submitData();
                "
                @input="
                  setDerivedCN();
                  displayFeedback(inputObject);
                  submitData();
                "
              ></b-form-input>
              <b-form-invalid-feedback id="input-false">{{
                $t(
                  (inputObject.type === SERVER_DOMAIN_TYPE
                    ? "DNS"
                    : inputObject.type === SERVER_IP_TYPE
                    ? "IP"
                    : inputObject.type.shortName) + "_feedback"
                )
              }}</b-form-invalid-feedback>
              <b-form-valid-feedback id="input-true"></b-form-valid-feedback>
            </b-col>
          </b-input-group>
        </b-row>
      </b-container>
    </div>
    <b-button
      v-if="this.certType == certTypes.server"
      v-on:click="addSanInput()"
      class="mt-3"
      variant="primary"
    >
      {{ $t("ServerSAN_button") }}
    </b-button>
    <div id="div-namespace">
      <label>
        {{ $t("namespace") }}
      </label>
      <b-form-select
        v-model="selection"
        :options="dnPrefixes"
        @input="submitData()"
      >
      </b-form-select>
    </div>
  </form>
</template>

<i18n>
    en:
      title: "New certificate"
      intro: "Apply here for a new certificate."
      title2: "Create certificate request"
      hint: "The following data is used to create a new certificate request."
      mandatory_inputs: "(* = mandatory data)"
      text_feedback: "Please use only letters (no umlauts and diacritics), digits and the following special characters: ( ),'.:/-"
      text_with_at_feedback: "@:text_feedback@"
      subjectTitle: "Name prefix (Only as specified in your official government ID document)"
      subjectTitle_placeholder: "Optional: Your name prefix, only as specified in your official government ID document, e.g., \"Dr.\". Don't use umlauts and diacritics."
      subjectTitle_feedback: "@:text_feedback"

      // RDNs:
      GN: "Given Name"
      GN_placeholder: "At least one of your given names - fully spelled out. Further given names are optional or can be abbreviated. Do not use umlauts and diacritics."
      GN_feedback: "@:text_feedback"
      noGNText: "I don't have a Given Name."
      SN: "Surname"
      SN_placeholder: "Your full surname(s) - fully spelled out. Do not use umlauts and diacritics."
      SN_feedback: "@:text_feedback"
      pseudonym: "Pseudonym"
      pseudonym_placeholder: "Your chosen pseudonym. Do not use umlauts and diacritics. The \"PN - \" indicator will be added automatically where required."
      pseudonym_feedback: "@:text_feedback"
      GRP: "Group"
      GRP_placeholder: "Name of the group. Do not use umlauts and diacritics. The \"GRP - \" indicator will be added automatically."
      GRP_feedback: "@:text_feedback"
      CN: "Name (CN)"
      CN_placeholder: "Enter your first and last name(s) here. Do not use umlauts and diacritics."
      CN_feedback: "Maximum length of the CN is 64 characters. Please use only letters (no umlauts and diacritics), digits and the following special characters: ( ),'.:/-"
      ServerCN: "CommonName (CN)"
      ServerCN_placeholder: "A hostname (or wildcard-name), a domain name or an IP#."
      ServerCN_feedback: "@:text_feedback"
      ServerSAN: "Additional subject alternative name (SaN)"
      ServerSAN_placeholder: "A hostname (or wildcard name), a domain name or an IP#."
      ServerSAN_button: "Add another SAN"
      DNS_feedback: "No allowed domain name recognized."
      IP_feedback: "No allowed IP address recognized."
      E: "Email"
      E_placeholder: "Email address"
      E_feedback: "Wrong format of the email address."
      OU: "Organisational unit (OU)"
      OU_placeholder: "If you specify an organisational unit here, it will be included as OU-attribut in the certificate name."
      OU_feedback: "@:text_feedback"
      O_feedback: "@:text_feedback"
      serialNumber_feedback: "@:text_feedback"
      UID_feedback: "@:text_with_at_feedback"

      no_prefixes: "Please do not include a PN/GRP prefix in this input field. The necessary prefix will be automatically added to pseudonym and group certificate applications."
      derivedCN_to_long: "The automatically derived CN has reached its allowed maximal length, please fill in your name(s) manually.
                          \n\nPlease shorten the name accordingly e.g. by using initials for or leaving out all but one fully spelled out given name or leaving out the name prefix, or a combination of those."

      // SANs:
      email_feedback: "@:E_feedback"
      Microsoft_UPN_feedback: "@:E_feedback"
      URI_feedback: "@:text_feedback"

      namespace: "Namespace (The chosen namespace will be used to complete the final certificate name.)"
    de:
      title: "Neues Zertifikat"
      intro: "Hier können Sie ein neues Zertifikat beantragen"
      title2: "Antrag erstellen"
      hint: "Aus den folgenden Daten wird ein neuer Zertifikatantrag generiert."
      mandatory_inputs: "(* = Pflichtfeld)"
      text_feedback: "Bitte verwenden Sie nur Buchstaben (ohne Umlaute), Zahlen und folgende Sonderzeichen: ( ),'.:/-"
      text_with_at_feedback: "@:text_feedback@"
      subjectTitle: "Vorangestellter Namenszusatz (nur wie im amtlichen Ausweisdokument angegeben)"
      subjectTitle_placeholder: "Optional: Vorangestellter Namenszusatz nur wie im amtlichen Ausweisdokument angegeben, z.B. \"Dr.\". Verwenden Sie keine Umlaute."
      subjectTitle_feedback: "@:text_feedback"

      // RDNS:
      GN: "Vorname"
      GN_placeholder: "Mindestens einer Ihrer voll ausgeschriebenen Vornamen. Weitere Vornamen sind optional oder können abgekürzt werden. Verwenden Sie keine Umlaute."
      GN_feedback: "@:text_feedback"
      noGNText: "Ich habe keinen Vornamen."
      SN: "Nachname"
      SN_placeholder: "Ihr vollständiger ausgeschriebener Nachname. Verwenden Sie keine Umlaute."
      SN_feedback: "@:text_feedback"
      pseudonym: "Pseudonym"
      pseudonym_placeholder: "Ihr gewähltes Pseudonym. Verwenden Sie keine Umlaute. Die \"PN - \" Kennzeichnung wird wo erforderlich automatisch vorangestellt."
      pseudonym_feedback: "@:text_feedback"
      GRP: "Gruppe"
      GRP_placeholder: "Name der Gruppe. Verwenden Sie keine Umlaute. Die \"GRP - \" Kennzeichnung wird automatisch vorangestellt."
      GRP_feedback: "@:text_feedback"
      CN: "Name (CN)"
      CN_placeholder: "Geben Sie hier Ihre Vor- und Nachnamen ein. Verwenden Sie keine Umlaute."
      CN_feedback: "Maximale Länge des CNs sind 64 Zeichen. Bitte verwenden Sie nur Buchstaben (ohne Umlaute), Zahlen und folgende Sonderzeichen: ( ),'.:/-"
      ServerCN: "CommonName (CN)"
      ServerCN_placeholder: "Ein Domainname oder eine IP-Adresse."
      ServerCN_feedback: "@:text_feedback"
      ServerSAN: "Zusätzlicher SubjectAlternativeName (SaN)"
      ServerSAN_placeholder: "Ein Domainname oder eine IP-Adresse."
      ServerSAN_button: "Einen weiteren SAN hinzufügen"
      DNS_feedback: "Keine erlaubte Domain erkannt."
      IP_feedback: "Keine erlaubte IP-Adresse erkannt."
      E: "E-Mail"
      E_placeholder: "E-Mail-Adresse"
      E_feedback: "Falsches Format der E-Mail-Adresse."
      OU: "Abteilung (OU)"
      OU_placeholder: "Wenn Sie hier eine Abteilung angeben, wird diese in den Zertifikatnamen als OU-Attribut aufgenommen."
      OU_feedback: "@:text_feedback"
      O_feedback: "@:text_feedback"
      serialNumber_feedback: "@:text_feedback"
      UID_feedback: "@:text_with_at_feedback"

      no_prefixes: "Bitte verwenden Sie kein PN/GRP-Präfix im Eingabefeld! Das nötige Präfix wird automatisch im Antrag für Pseudonym-/Gruppenzertifikate ergänzt."
      derivedCN_to_long: "Der automatisch aus Namenszusatz, Vor- und Nachname abgeleitete CN hat die erlaubte Maximallänge von 64 Zeichen überschritten.
                          \n\nBitte passen Sie den Namen (CN) per Hand an.
                          \n\nKürzen Sie den Namen (CN), in dem Sie z.B. den Namenszusatz weglassen oder alle bis auf einen voll ausgeschriebenen Vornamen weglassen oder alle bis auf einen voll ausgeschriebenen Vornamen abkürzen, oder eine Kombination davon."

      // SANS:
      email_feedback: "Falsches Format der E-Mail-Adresse."
      Microsoft_UPN_feedback: "@:E_feedback"
      URI_feedback: "@:text_feedback"
      namespace: "Namensraum (Der endgültige Zertifikatsname wird mit dem gewählten Namensraum vervollständigt.)"

</i18n>

<script>
import { getDNInWenjaFormatFromString } from "@/pki/subject";
import { ALLOWED_RDN_TYPES, ALLOWED_SAN_TYPES } from "@/pki/nameTypes";
import { CERT_TYPES } from "@/pki/certTypes";
import { custom } from "@/customization/customization";
import { Logger } from "@/logger/logger";
import InputsForNewRequestCertificate from "./InputsForNewRequestCertificate.js";
import DomainDropdown from "@/components/pages/new-certificate/DomainDropdown";
import {
  SERVER_DOMAIN_TYPE,
  SERVER_IP_TYPE,
} from "./InputsForNewRequestCertificate.js";

const logger = new Logger("NewRequestCertificate");
// eslint-disable-next-line no-undef
const developerMode = process.env.NODE_ENV === "development";

// Abkürzung für Object.prototype.hasOwnProperty.call
function hasProperty(object, propertyName) {
  //logger.trace("hasProperty() aufgerufen mit propertyName = " + propertyName);
  return Object.prototype.hasOwnProperty.call(object, propertyName);
}

export default {
  name: "NewRequestCertificate",
  components: { DomainDropdown },
  mounted() {
    if (developerMode) {
      this.developerModeFunction();
    }
    if (this.certType == CERT_TYPES.server) {
      this.addSanInput();
    }
  },
  data() {
    const dnPrefixes = [];

    // Liste der DN-Präfixe erstellen
    for (let prefix of custom.dnPrefixes) {
      const wenjaPrefix = getDNInWenjaFormatFromString(prefix);
      if (
        this.certType == CERT_TYPES.server &&
        custom.policy.newRequest.showOnlySTLPrefixesForServer &&
        !hasProperty(wenjaPrefix, "ST")
      ) {
        // In Global werden für Server nur die ST-L-Präfixe angezeigt.
        continue;
      }
      dnPrefixes.push({
        value: wenjaPrefix,
        text: prefix,
      });
    }
    // In Global werden ab 30.8.2023 keine Zertifikate mehr ausgestellt.
    // Zur Sicherheit sind dann alle konfigurierten dnPrefixe leer.
    // Damit es beim Aufrufen der Antragsseiten nicht knallt, wird hier ein
    // leerer Eintrag gesetzt.
    if (dnPrefixes.length === 0) {
      dnPrefixes.push({
        value: "",
        text: "",
      });
    }

    return {
      custom,
      certTypes: CERT_TYPES,
      rdnTypes: ALLOWED_RDN_TYPES,
      sanTypes: ALLOWED_SAN_TYPES,
      publish: false,
      dnPrefixes,
      selection: dnPrefixes[0].value,
      validEmailDomains: null,
      loadingValidEmailDomains: false,
      validServerDomains: null,
      loadingValidServerDomains: false,
      noGivenName: false,
      cnInputDisabled: true,
      derivedCN: "",
      serverCNoptions: [
        { value: SERVER_DOMAIN_TYPE, text: "Domain" },
        { value: SERVER_IP_TYPE, text: "IP-Adresse" },
      ],
      serverSANoptions: [
        { value: ALLOWED_SAN_TYPES.IPAddress, text: "IP-Adresse" },
        { value: ALLOWED_SAN_TYPES.DNS, text: "DNS" },
      ],
      counterServerSAN: 1,
      showServerDomains:
        this.certType == CERT_TYPES.server &&
        custom.policy.newRequest.showDomains,
      showEmailDomains:
        this.certType != CERT_TYPES.server &&
        custom.policy.newRequest.showDomains,
      noGNSN:
        this.certType == CERT_TYPES.user && custom.policy.newRequest.noGNSN,
      dontSetPseudonym:
        this.certType == CERT_TYPES.pseudonym &&
        custom.policy.newRequest.dontSetPseudonym,
      SERVER_DOMAIN_TYPE,
      SERVER_IP_TYPE,
    };
  },
  /** InputsForNewRequestCertificate wird als Mixin importiert
   *
   * Importiert werden folgende Variablen:
   * titleInput -> Inputtyp für den subjectTitle
   * groupInput -> Inputtyp für den Gruppennamen
   * serverInput -> Inputtyp für den Server-CN
   * serverSANinput -> Inputtyp für die ServerSANs
   * sanInputObjects -> alle hinzugefügten SANs (nur für Server-Zertifikate)
   * inputObjects -> nur die angezeigten RDN-Inputs
   * allInputs -> alle angezeigten Inputs, ggf. Titel, RDNs, SANs
   *
   * SERVER_DOMAIN_TYPE und SERVER_IP_TYPE sind Pseudotypen für den Server-CN
   */
  mixins: [InputsForNewRequestCertificate],
  props: {
    certType: { type: Object, required: true },
    displayAllFeedback: { type: Boolean, required: true },
    value: {},
  },
  methods: {
    addSanInput() {
      const tmpSAN = { ...this.serverSANinput };
      tmpSAN.id = tmpSAN.id + this.counterServerSAN;
      this.sanInputObjects.push(tmpSAN);
      this.counterServerSAN++;
    },
    /**
     * Emittet RDNs und SANs an NewRequest.vue.
     * Leere Werte werden vorher entfernt.
     *
     * Wird bei jeder Änderung aufgerufen.
     */
    submitData() {
      if (this.formHasError()) {
        // Bei Fehlern wird null emittet. Das signalisiert, dass die Eingabe
        // noch nicht vollständig ist oder Fehler enthält.
        this.$emit("input", null);
        return;
      }
      this.$emit("input", {
        rdns: this.getRDNsInWenjaFormat(),
        sans: this.sanInputObjects.filter((san) => san.value.length > 0),
      });
    },
    /**
     * Prüft, ob ein Feld im Formular fehlerhaft ausgefüllt ist.
     *
     * Hat keine Nebenwirkungen. (State wird nicht gesetzt.)
     *
     * @return {boolean} true gdw. der Wert nicht für den Typen passt
     */
    inputHasError(inputObject) {
      const type = inputObject.type;
      const value = inputObject.value;

      if (value.length === 0) {
        // Leerer Wert => Check ob optional
        return !inputObject.optional;
      }
      // Bei ausgefüllten Werten teste 1. zu lang? 2. Pattern nicht erfüllt?
      return value.length > type.maxLength || !type.pattern.test(value);
    },
    /**
     * Prüft mit Hilfe von inputHasError, ob das Formular mindestens ein falsch
     * ausgefülltes Feld hat.
     *
     * Außer Logmeldungen gibt es keine Nebenwirkungen.
     *
     * @returns {boolean} true gdw. mindestens ein Input einen Fehler hat
     */
    formHasError() {
      for (let inputObject of this.allInputs) {
        if (this.inputHasError(inputObject)) {
          logger.debug(
            "Fehler in " + inputObject.label.de + "=" + inputObject.value
          );
          return true;
        }
      }
      return false;
    },
    /**
     * Setzt inputObject.state auf true oder false.
     */
    displayFeedback(inputObject) {
      // Prüft ob vom Benutzer ein GRP oder PN Präfix eingegenen wurde.
      if (/^ *(GRP|PN) *[: -]/.test(inputObject.value)) {
        // Löscht dieses automatisch und zeigt eine Fehlermeldung an.
        inputObject.value = "";
        this.$emit("error-message", this.$t("no_prefixes"));
      }
      inputObject.state = !this.inputHasError(inputObject);
      logger.trace(
        "State für " + inputObject.label["de"] + ": " + inputObject.state
      );
      return true; // Nötig, damit selects sich ändern?
    },
    /**
     * Leitet den CN aus Titel, GN und SN ab.
     */
    setDerivedCN() {
      // Fallunterscheidung Zertifikatstyp und Policy
      if (this.certType == CERT_TYPES.user && !this.noGNSN) {
        const title = this.titleInput.value;
        var derivedCN = title.length > 0 ? title + " " : "";
        var GN = this.inputObjects.find(
          (x) => x.type === this.rdnTypes.GN
        ).value;
        var SN = this.inputObjects.find(
          (x) => x.type === this.rdnTypes.SN
        ).value;
        var inputObject = this.inputObjects.find(
          (x) => x.type === this.rdnTypes.CN
        );

        derivedCN += GN;
        if (GN.length > 0) {
          derivedCN += " ";
        }
        derivedCN += SN;

        // Überpüft die Länge des Abgeleiteten CNs und gibt gegebenenfalls das CN-Eingabefeld frei.
        if (derivedCN.length > this.rdnTypes.CN.maxLength) {
          if (this.cnInputDisabled) {
            this.cnInputDisabled = false;
            inputObject.value = derivedCN;
            this.$emit("error-message", this.$t("derivedCN_to_long"));
          }
        } else {
          this.cnInputDisabled = true;
          inputObject.value = derivedCN;
        }
        this.displayFeedback(inputObject);
      }
    },
    /**
     * Löscht den aktuell eingegebenen GN.
     */
    clearGN() {
      var inputObject = this.inputObjects.find(
        (x) => x.type === this.rdnTypes.GN
      );
      if (this.noGivenName) {
        inputObject.value = "";
        inputObject.optional = true;
      } else {
        inputObject.optional = false;
      }
    },
    /**
     * Wandelt das Formular (eigens ausgefüllte Felder + Namensraum) ins Wenja-Format und gibt es zurück
     *
     * @returns {Object} RDNs im Wenja-Format
     */
    getRDNsInWenjaFormat() {
      let rdnsInWenjaFormat = {};

      for (let inputObject of this.inputObjects) {
        if (inputObject.value !== "") {
          if (!hasProperty(rdnsInWenjaFormat, inputObject.type.shortName)) {
            // TODO: das erste E wird durch das zweite E überschrieben?
            // set value of key to an empty array
            // eg. rdnsInWenjaFormat["OU"] = []
            rdnsInWenjaFormat[inputObject.type.shortName] = [];
          }
          // eg. rdnsInWenjaFormat["OU"].push("205")
          rdnsInWenjaFormat[inputObject.type.shortName].push(inputObject.value);
        }
      }
      for (let rdnTypeAsString in this.selection) {
        if (hasProperty(this.selection, rdnTypeAsString)) {
          if (!hasProperty(rdnsInWenjaFormat, rdnTypeAsString))
            rdnsInWenjaFormat[rdnTypeAsString] = [];
          for (let val of this.selection[rdnTypeAsString]) {
            rdnsInWenjaFormat[rdnTypeAsString].push(val);
          }
        }
      }

      // Ableitung des CNs
      if (this.certType == CERT_TYPES.pseudonym) {
        rdnsInWenjaFormat["CN"] = ["PN - " + rdnsInWenjaFormat["pseudonym"][0]];
      } else if (this.certType == CERT_TYPES.group) {
        rdnsInWenjaFormat["CN"] = ["GRP - " + this.groupInput.value];
      } else if (this.certType == CERT_TYPES.server) {
        rdnsInWenjaFormat["CN"] = [this.serverInput.value];
      }

      if (this.custom.setCNOnly) {
        rdnsInWenjaFormat["GN"] = [];
        rdnsInWenjaFormat["SN"] = [];
        rdnsInWenjaFormat["pseudonym"] = [];
      }

      // Falls in Policy Community wird der PN nicht gesetzt #2002
      if (this.dontSetPseudonym) {
        rdnsInWenjaFormat["pseudonym"] = [];
      }

      // Bei den mehrfach im DN vorkommenen Attributen Reihenfolge umkehren
      // (s. #1719, #2033)
      for (let rdn of Object.values(rdnsInWenjaFormat)) {
        if (rdn.length > 1) {
          rdn.reverse();
        }
      }

      //logger.debug("rdnsInWenjaFormat: " + JSON.stringify(rdnsInWenjaFormat));
      return rdnsInWenjaFormat;
    },
    /**
     *
     */

    developerModeFunction() {
      const devData = {
        GN: "Tim",
        SN: "Tester",
        pseudonym: "Pseudonym",
        E: "kickermensch@email.dfnpki.de",
        OU: "205",
        O: "TestOrg",
        CN: "test.dfn-cert.de",
        IP: "192.168.71.2",
      };

      for (let inputObject of this.allInputs) {
        if (hasProperty(devData, inputObject.type.shortName)) {
          inputObject.value = devData[inputObject.type.shortName];
        }
      }
      // Eingetragene Daten direkt an NewRequest übermitteln.
      this.setDerivedCN();
      this.submitData();
    },
  },
};
</script>

<style scoped>
form {
  margin-bottom: 24px;
}

.dropdown {
  width: 900px;
}

.collapsed .when-opened {
  display: none;
}

:not(.collapsed) > div > .when-closed {
  display: none;
}

#div-namespace {
  margin-top: 15px;
}
</style>
